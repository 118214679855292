import React, { useEffect } from 'react';
import type { FC } from 'react';
import NProgress from 'nprogress';
import { Box, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from './LoadingScreen.module.scss';

const LoadingScreen: FC = (props: any) => {
  const { classes } = props;
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={styles.loadingScreen}>
      <Box width={400}>
        <LinearProgress
          {...props}
          classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }}
        />
      </Box>
    </div>
  );
};

const customStyles = () => ({
  colorPrimary: {
    backgroundColor: '#fc404d',
  },
  barColorPrimary: {
    backgroundColor: '#F5F5F5',
  },
});

export default withStyles(customStyles)(LoadingScreen);
