import React from 'react';
import { and, ControlProps, rankWith } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Typography } from 'src/components/kit';
import { useIntl } from 'react-intl';
import colors from 'src/styles/variables.scss';
import { formUITypeIs } from '../util';

export const SummaryControlErrorTester = rankWith(1, and(formUITypeIs('SummaryError')));
export default withJsonFormsControlProps(({ data: _data }: ControlProps) => {
  const intl = useIntl();

  return (
    <div className='control-container'>
      <div className='row'>
        <Typography align='center' variant='body1' color={colors.redDark}>
          {intl.formatMessage({
            id: 'kyc.validation.nameAndIDnotMatch',
            defaultMessage:
              'Your name did not match your ID. Please update with your full legal name',
          })}
        </Typography>
      </div>
    </div>
  );
});
