/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from 'react';
import { uiTypeIs, rankWith } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Typography, Icon } from 'src/components/kit';
import { useSelector, useDispatch } from 'react-redux';
import { removeFile, addFile } from 'src/slices/onboarding';
import { useIntl } from 'react-intl';
import { ReactComponent as DragNDropImage } from 'src/assets/images/drag-n-drop.svg';
import './DocumentUpload.styles.scss';

const checkValidFile = (file: File): boolean => {
  if (!file) return false;

  const ext = file.name.split('.').pop().toLowerCase();

  if (ext === 'jpg' || ext === 'jpeg' || ext === 'pdf' || ext === 'png') {
    return true;
  }

  return false;
};

const checkValidFileSize = (file: File): File | boolean => {
  if (!file) return false;

  const fileSize = file.size / 1024 / 1024;

  if (fileSize < 2) {
    return true;
  }

  return false;
};

// eslint-disable-next-line no-multi-assign
const UploadDocumentRenderer = (): JSX.Element => {
  const dispatch = useDispatch();
  const { fileList, getFileListStatus } = useSelector((state: any) => state.onboarding);
  const [dragging, setDragging] = useState<boolean>(false);
  const drop = useRef(null);
  const intl = useIntl();

  const handleBrowseFileUpload = (e: any): void => {
    const file = e.target.files[0];
    if (!checkValidFileSize(file)) {
      file.error = intl.formatMessage({
        id: 'kyc.validation.fileSizeExceed',
        defaultMessage: 'Maximum file size exceeded',
      });
    }

    if (!checkValidFile(file)) {
      file.error = intl.formatMessage({
        id: 'kyc.validation.unsupportedFileType',
        defaultMessage: 'Unsupported file type',
      });
    }

    dispatch(addFile(file));
  };

  useEffect(() => {
    drop.current.addEventListener('dragover', handleDragOver);
    drop.current.addEventListener('drop', handleDrop);
    drop.current.addEventListener('dragenter', handleDragEnter);
    drop.current.addEventListener('dragleave', handleDragLeave);
  }, []);

  const handleDragEnter = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      const file = files[0];

      const isValidFile = checkValidFile(file);
      const isValidFileSize = checkValidFileSize(file);

      if (!isValidFile) {
        file.error = intl.formatMessage({
          id: 'kyc.validation.unsupportedFileType',
          defaultMessage: 'Unsupported file type',
        });
      }

      if (!isValidFileSize) {
        file.error = intl.formatMessage({
          id: 'kyc.validation.fileSizeExceed',
          defaultMessage: 'Maximum file size exceeded',
        });
      }

      dispatch(addFile(file));
    }

    setDragging(false);
  };

  const onRemoveFile = (fileId: string, error?: string): void => {
    dispatch(removeFile(fileId, error));
  };

  return (
    <div>
      <div className='mt-24 mb-16'>
        <Typography variant='body2'>
          {intl.formatMessage({
            id: 'kyc.documentUpload.documentUploadInstruction',
            defaultMessage: 'Please attach your Additional Supporting Documents',
          })}
        </Typography>
      </div>
      <div
        className={`document-upload-container mb-4 ${dragging ? 'upload-drag-state' : ''}`}
        ref={drop}>
        <DragNDropImage className='drag-drop-image' />
        <div className='mt-12 mb-4'>
          <Typography variant='title1'>
            {intl.formatMessage({
              id: 'kyc.documentUpload.dragAndDropFileText',
              defaultMessage: 'Drag & Drop files here',
            })}
          </Typography>
        </div>
        <div className='mb-8'>
          <Typography variant='body2'>
            {intl.formatMessage({
              id: 'kyc.documentUpload.selectFileToUploadText',
              defaultMessage: 'or select files to upload',
            })}
          </Typography>
        </div>

        <div className='mb-24'>
          <label htmlFor='file-upload'>
            <Typography variant='button-sm'>
              {intl.formatMessage({
                id: 'kyc.documentUpload.browseFileText',
                defaultMessage: 'BROWSE FILE',
              })}
            </Typography>
            <input
              type='file'
              name='file-upload'
              id='file-upload'
              className='file-upload'
              onChange={handleBrowseFileUpload}
              accept='image/png, image/jpeg, image/jpg, application/pdf'
              value=''
            />
          </label>
        </div>
        {getFileListStatus === 'ready' &&
          fileList.map((file: any) => (
            <UploadedFile
              key={file.id}
              file={file}
              status='Complete'
              progress={100}
              onRemove={onRemoveFile}
            />
          ))}

        {getFileListStatus === '404' &&
          [].map((file: any) => (
            <UploadedFile
              key={file.name}
              file={file}
              status='Complete'
              progress={100}
              onRemove={onRemoveFile}
            />
          ))}
      </div>
      <Typography variant='body3'>
        {intl.formatMessage({
          id: 'kyc.documentUpload.supportedFileFormatText',
          defaultMessage: 'Supported file formats: jpg, jpeg, png, pdf. Maximum file size 2 Mb.',
        })}
      </Typography>
    </div>
  );
};

interface UploadedFile {
  file: any;
  status: string;
  progress: number;
  onRemove: (fileId: string, error?: string) => void;
}

const UploadedFile: React.FC<UploadedFile> = ({ file, status, progress, onRemove }) => (
  <div className='file-item mb-19'>
    <div className='flex justify-content-between align-items-end'>
      <div className='flex align-items-center doc-file-name'>
        <div className='mr-8 align-items-center'>
          <Icon name='file' />
        </div>
        <Typography variant='body2'>{file.fileName}</Typography>
      </div>
      <div className='flex align-items-center'>
        <div className='mr-13'>
          <Typography variant='button-sm' color='#A6A6A6'>
            {status.toUpperCase()}
          </Typography>
        </div>
        <div
          className='align-items-center remove-button'
          onClick={() => {
            onRemove(file.id, file?.error);
          }}>
          <Icon name='close' />
        </div>
      </div>
    </div>
    <div className='mt-6 progress-container'>
      <progress value={progress} max='100' className='file-upload-progress' />
    </div>
    {file.error && <div className='error'>{file.error}</div>}
  </div>
);

export const UploadDocumentTester = rankWith(100, uiTypeIs('DocumentUpload'));

export default withJsonFormsControlProps(UploadDocumentRenderer);
