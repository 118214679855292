import React from 'react';
import { Icon } from 'src/components/kit';
import colors from 'src/styles/variables.scss';

export const StepNotStartedIcon: React.FC<{ color?: string }> = ({ color = colors.white }) => (
  <Icon name='stepNotStarted' title='Not Started' color={color} />
);
export const StepInProgressIcon: React.FC<{ color?: string }> = ({ color = colors.white }) => (
  <Icon name='stepStarted' title='In Progress' color={color} />
);
export const StepSkippedIcon: React.FC<{ color?: string }> = ({ color = colors.green }) => (
  <Icon name='stepNotStarted' title='Skipped' color={color} />
);
export const StepPendingIcon: React.FC<{ color?: string }> = ({ color = colors.black }) => (
  <Icon name='stepInProgress' title='Pending' color={color} />
);
export const StepFailedIcon: React.FC<{ color?: string }> = ({ color = colors.redDark }) => (
  <Icon name='stepFailed' title='Failed' color={color} />
);
export const StepCompletedIcon: React.FC<{ color?: string }> = ({ color = colors.white }) => (
  <Icon name='stepCompleted' title='Completed' color={color} />
);
