import React, { useState, useEffect } from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import {
  rankWith,
  ControlProps,
  uiTypeIs,
  ControlElement,
  UISchemaElement,
  JsonSchema,
} from '@jsonforms/core';
import { Input } from 'src/components/kit';
import { useSelector } from 'react-redux';

export type DropdownElement = ControlElementWithPlaceholder & {
  displayValues: Record<string, string>;
  readonly options?: {
    disableSearch?: boolean;
  };
};

export type TextAreaElement = ControlElementWithPlaceholder & {
  readonly options?: {
    multi: boolean;
  };
};

export type ControlElementWithPlaceholder = {
  placeholder?: string;
} & ControlElement;

export type UISchemaControlElement = UISchemaElement & { scope: string };

export function getPropertyNameFromControl(controlElement: UISchemaControlElement): string {
  return controlElement.scope.replace('#/properties/', '');
}

interface PhoneCountryCodeProps {
  uischema: UISchemaElement | any;
  handleChange: (path: string, value: any) => void;
  path: string;
  errors: any;
  data: string;
  schema: JsonSchema | any;
}

const PhoneCountryCode: React.FC<PhoneCountryCodeProps> = ({
  uischema,
  errors,
  path,
  data,
  handleChange,
  schema,
}): JSX.Element => {
  const label = uischema.label?.toString().replace('[bold]', '');
  const placeholder = uischema.placeholder as string;
  const [validationError, setValidationError] = useState<string>(errors);
  const formTouched = useSelector((state: any) => state.auth.formTouched);

  useEffect(() => {
    setValidationErrorMessage();
  }, [data]);

  useEffect(() => {
    setValidationErrorMessage();
  }, [formTouched]);

  const setValidationErrorMessage = () => {
    if (!data) {
      if (!errors) {
        setValidationError('');
      } else if (errors) {
        setValidationError('This field is required.');
      }
    } else if (data !== '' && errors) {
      if (schema.message) {
        setValidationError(`${schema.message}`);
      } else {
        setValidationError(errors);
      }
    } else if (!errors) {
      setValidationError('');
    }
  };

  return (
    <div className='phone-country-code-container'>
      <Input
        disabled={uischema?.options?.readonly}
        label={label}
        name={uischema.scope}
        value={data && data}
        handleChange={(e: any) => {
          handleChange(path, e.target.value);
        }}
        placeholder={placeholder}
        error={formTouched && validationError}
      />
    </div>
  );
};

const PhoneCountryCodeRenderer = (props: ControlProps): JSX.Element => {
  const { handleChange, uischema, data, path, errors, schema } = props;
  return (
    <PhoneCountryCode
      uischema={uischema}
      data={data}
      path={path}
      errors={errors}
      schema={schema}
      handleChange={(uipath: string, value: string) => handleChange(uipath, value)}
    />
  );
};

export const PhoneCountryCodeTester = rankWith(100, uiTypeIs('PhoneCountryCode'));

export default withJsonFormsControlProps(PhoneCountryCodeRenderer);
