/* eslint react/no-array-index-key: 0 */

import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen/LoadingScreen';
import Layout from 'src/layouts/DefaultLayout';
import ForgotPasswordLayout from './layouts/ForgotPasswdLayout';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  guardOptions?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense
    fallback={
      <div className='fallback-loading-screen-container'>
        <LoadingScreen />
      </div>
    }>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props: any) => (
              <Guard {...route.guardOptions}>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/pages/errors/NotFoundView.page')),
  },

  {
    path: '*',
    // layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        layout: Layout,
        component: lazy(() => import('src/pages/auth/LoginPage')),
      },
      {
        exact: true,
        path: '/signup',
        layout: Layout,
        component: lazy(() => import('src/pages/auth/SignupPage')),
      },
      {
        exact: true,
        path: '/login',
        layout: Layout,
        component: lazy(() => import('src/pages/auth/LoginPage')),
      },
      {
        exact: true,
        path: '/kyc',
        layout: Layout,
        component: lazy(() => import('src/pages/kyc')),
      },
      {
        exact: true,
        path: '/jumio',
        layout: Layout,
        component: lazy(() => import('src/pages/kyc/JumioResultPage')),
      },
      {
        exact: true,
        path: '/forgot-password',
        layout: ForgotPasswordLayout,
        component: lazy(() => import('src/pages/auth/ForgotPasswordPage')),
      },
      {
        exact: true,
        path: '/forgot-password-email',
        layout: ForgotPasswordLayout,
        component: lazy(() => import('src/pages/auth/ForgotPasswordEmailPage')),
      },
      {
        component: () => <Redirect to='/404' />,
      },
    ],
  },
];

export default routes;
