import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/components/kit';
import JsonForm from 'src/components/JsonForm';
import { setFormTouched, setCurrentStep } from 'src/slices/auth';
import { getSchemaByName } from 'src/utils/getSchemaByName';
import Spinner from 'src/components/Spinner/Spinner.component';
import { userLogout } from 'src/auth';
import isDocumentUploadDone from 'src/utils/isDocumentUploadDone';
import useAnalytics from 'src/analytics/useAnalytics';
import { setLead } from 'src/slices/onboarding';
import { useIntl } from 'react-intl';
import getSectionIdOfRecentPage from 'src/utils/getSectionIdOfRecentPage';
import BackButton from 'src/components/BackButton';

const formKey = 'affiliate-documentUpload';

export const isNoDocumentRequired = (schema: any): boolean => {
  let noDocumentRequired = true;
  schema?.uiSchema?.elements?.forEach((element: any) => {
    element?.elements?.forEach((subFormElement: any) => {
      if (subFormElement?.type === 'DocumentUpload') {
        noDocumentRequired = false;
      }
    });
  });

  return noDocumentRequired;
};

export const getDocumentUploadSchemaByApplicationType = (
  schema: any,
  applicationType: string,
): any => {
  let isSchemaHasRule = false;
  const elements = schema?.uiSchema?.elements
    .map((element: any) => {
      const ruleEnum = element?.rule?.condition?.schema?.enum;
      if (ruleEnum) {
        isSchemaHasRule = true;
        if (ruleEnum.includes(applicationType)) {
          return element;
        }
      }
      return null;
    })
    .filter((element: any) => element);

  const updatedSchema = {
    uiSchema: {
      ...schema?.uiSchema,
      elements,
    },
    dataSchema: schema.dataSchema,
  };
  return isSchemaHasRule ? updatedSchema : schema;
};

const DocumentUploadPage: FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { schemas, getSchemaStatus, fileList, lead } = useSelector(
    (state: any) => state.onboarding,
  );
  const [documentUploadSchema, setDocumentUploadSchema] = useState<any>({
    uiSchema: {},
    dataSchema: {},
  });
  const [applicationType, setApplicationType] = useState<string>('');
  const trackEvent = useAnalytics();
  useEffect(() => {
    if (getSchemaStatus === 'ready' && applicationType) {
      const schema = getSchemaByName(schemas, formKey);
      setDocumentUploadSchema(getDocumentUploadSchemaByApplicationType(schema, applicationType));
    }
  }, [schemas, getSchemaStatus, applicationType]);

  return (
    <div data-testid='DocumentUploadPage' className='position-relative'>
      <div>
        {getSchemaStatus === 'ready' && (
          <JsonForm
            name={formKey}
            schema={getSchemaByName(schemas, formKey)}
            handleChange={(data: any) => {
              setApplicationType(data?.applicationType);
            }}
          />
        )}
        {getSchemaStatus === 'loading' && (
          <div className='loading-container'>
            <Spinner size='large' />
          </div>
        )}
        <div className='flex kyc-btn-container'>
          <BackButton
            onClick={() => {
              dispatch(setCurrentStep(getSectionIdOfRecentPage(formKey)));
              trackEvent('Affiliate - Back', {
                pageDetails: formKey,
              });
            }}
          />
          {!isNoDocumentRequired(documentUploadSchema) && (
            <div className='kyc-save-and-close-btn-container'>
              <Button
                label={intl.formatMessage({
                  id: 'kyc.closeBtn',
                  defaultMessage: 'CLOSE',
                })}
                buttonType='button'
                variant='tertiary'
                datatestid='btnSaveAndClose'
                onClick={() => {
                  trackEvent('Affiliate - Save and Close', {
                    pageDetails: formKey,
                  });
                  userLogout();
                }}
              />
            </div>
          )}

          {!isNoDocumentRequired(documentUploadSchema) && (
            <div className='skip-for-now-btn'>
              <Button
                disabled={fileList && isDocumentUploadDone(fileList)}
                label={intl.formatMessage({
                  id: 'kyc.skipForNowBtn',
                  defaultMessage: 'SKIP FOR NOW',
                })}
                buttonType='button'
                variant='secondary'
                datatestid='btnSkip'
                onClick={() => {
                  trackEvent('Affiliate - Save and Skip', {
                    pageDetails: formKey,
                  });
                  dispatch(setCurrentStep('affiliate-declaration'));
                  dispatch(setFormTouched(false));
                  localStorage.setItem('DocumentUpload', 'skipped');
                }}
              />
            </div>
          )}

          <div>
            <Button
              onClick={() => {
                // trackEvent('Next', {
                //   pageDetails: formKey,
                // });
                dispatch(setCurrentStep('affiliate-declaration'));
                dispatch(setFormTouched(false));
                localStorage.setItem('DocumentUpload', 'done');
                dispatch(setLead({ ...lead, fileList }));
              }}
              label={intl.formatMessage({
                id: 'kyc.nextBtn',
                defaultMessage: 'NEXT',
              })}
              disabled={
                !isDocumentUploadDone(fileList) && !isNoDocumentRequired(documentUploadSchema)
              }
              buttonType='button'
              variant='primary'
              datatestid='btnNext'
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DocumentUploadPage;
