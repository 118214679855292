export default function isDocumentUploadDone(fileList: any): boolean {
  if (!fileList) return false;
  if (!fileList || fileList.length < 1) return false;

  let isDone = false;

  fileList.forEach((file: any) => {
    if (!file.error) {
      isDone = true;
    }
  });

  return isDone;
}
