import React from 'react';
import Sidebar from 'src/layouts/Sidebar/Sidebar.component';
import { useSelector } from 'react-redux';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }: LayoutProps) => {
  const { isAuthenticated } = useSelector((state: any) => state.auth);

  return (
    <div className='main-layout'>
      <Sidebar />
      <div className={`layout-content ${!isAuthenticated && 'notLoggedIn'}`}>
        <div className='layout-body'>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
