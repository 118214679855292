import axios from 'axios';
import { loadSession, AuthResponse } from 'src/auth';
import { onboardingServiceConfig } from 'src/config';
import { isTokenExpired } from 'src/utils/token';
import {
  AffiliateLeadApi,
  LeadApi,
  SettingsApi,
  CountriesApi,
} from '../resources/api-client/onboarding';

export const onboardingSvcAxiosClient = axios.create({ baseURL: onboardingServiceConfig.apiUrl });

const AffiliateSvc = new AffiliateLeadApi(
  null,
  onboardingServiceConfig.apiUrl,
  onboardingSvcAxiosClient,
);
const LeadSvc = new LeadApi(null, onboardingServiceConfig.apiUrl, onboardingSvcAxiosClient);
const SettingsSvc = new SettingsApi(null, onboardingServiceConfig.apiUrl, onboardingSvcAxiosClient);
const CountriesSvc = new CountriesApi(
  null,
  onboardingServiceConfig.apiUrl,
  onboardingSvcAxiosClient,
);

export interface OnboardingSvc {
  affliateLead: AffiliateLeadApi;
  lead: LeadApi;
  settings: SettingsApi;
  countries: CountriesApi;
}

const OnboardingSvcClient = {
  affliateLead: AffiliateSvc,
  lead: LeadSvc,
  settings: SettingsSvc,
  countries: CountriesSvc,
} as OnboardingSvc;

let token: string;

const getOnboardingSvcClient = async (): Promise<OnboardingSvc> => {
  if (token === null || isTokenExpired(token)) {
    loadSession((authResponse: AuthResponse) => {
      const { success, result } = authResponse;
      if (success) {
        token = result.accessToken;
        onboardingSvcAxiosClient.interceptors.request.use((config: any) => {
          setAccessToken(token);
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        });
      }
    });
  } else {
    onboardingSvcAxiosClient.interceptors.request.use((config: any) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  }
  return OnboardingSvcClient;
};

export const setAccessToken = (_token: string) => {
  token = _token;
};

export const getAccessToken = async () => {
  if (!token) {
    await getOnboardingSvcClient();
    return token;
  }
  return token;
};

export default getOnboardingSvcClient;
