import { useState, useEffect } from 'react';

const useWindowSize = (): number => {
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth);

  useEffect(
    () => () => {
      setWindowSize(window.innerWidth);

      window.addEventListener('resize', () => {
        setWindowSize(window.innerWidth);
      });
    },
    [],
  );

  return windowSize;
};

export default useWindowSize;
