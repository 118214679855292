import React, { useCallback, useMemo } from 'react';
import { and, GroupLayout, rankWith, RendererProps, resolveData } from '@jsonforms/core';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { Button } from 'src/components/kit';
import { setCurrentStep } from 'src/slices/auth';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { formUITypeIs, RenderChildren } from '../util';

type Layout = GroupLayout & {
  readonly id: string;
  readonly section: string;
  readonly editable?: {
    readonly parent: string;
    readonly id?: string;
  };
};

const SummarySubSectionRenderer = (props: RendererProps): JSX.Element => {
  const { uischema, schema, path, data } = props;
  const layout = uischema as Layout;
  const { id, section, editable } = layout;
  const dispatch = useDispatch();
  const intl = useIntl();
  const disableEditing = useMemo(
    () => resolveData(data, `${section}.disableEditing`),
    [data, section],
  );
  const onPress = useCallback(() => {
    if (typeof editable === 'undefined') {
      console.log('editable node is missing');
    }
    const screen = `affiliate-${section}`;

    dispatch(setCurrentStep(screen));
  }, [editable, section, id]);
  if (layout.elements.length > 0) {
    return (
      <div className='summary-subsection-container'>
        {editable && !disableEditing && (
          <div className='summary-button-container'>
            <Button
              onClick={onPress}
              variant='tertiary'
              size='small'
              label={intl.formatMessage({
                id: 'summary.editText',
                defaultMessage: 'Edit',
              })}
            />
          </div>
        )}

        <div className='children-container'>
          <RenderChildren schema={schema} layout={layout} path={path} />
        </div>
      </div>
    );
  }
  return <></>;
};

export const SummarySubSectionTester = rankWith(100, and(formUITypeIs('SummarySubSection')));
export default withJsonFormsLayoutProps(SummarySubSectionRenderer);
