/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { JsonSchema, Layout, uiTypeIs } from '@jsonforms/core';
import { JsonFormsDispatch, useJsonForms } from '@jsonforms/react';

export const handledUITypes = [
  'Form',
  'SubForm',
  'Control',
  'Phone',
  'VerticalLayout',
  'HorizontalLayout',
  'Text',
  'Paragraph',
  'SubParagraph',
  'Header',
  'Link',
  'BulletedList',
  'FileList',
  'SummaryBlock',
  'SummaryControlField',
  'SummaryControlCheckbox',
  'SummaryError',
  'SummarySubSection',
  'SummaryPhoneLayout',
  'SummaryFileList',
  'SummaryTextList',
  'FileArrayList',
  'JumioIdVerification',
  'BoxedParagraph',
] as const;

export function formUITypeIs(type: typeof handledUITypes[number]) {
  return uiTypeIs(type);
}

export interface RenderChildrenProps {
  layout: Layout;
  schema: JsonSchema;
  path: string;
  className?: string;
}

export const RenderChildren = ({
  layout,
  schema,
  path,
  className,
}: RenderChildrenProps): JSX.Element => {
  const { renderers, cells } = useJsonForms();

  return (
    <>
      {layout.elements.map((child) => (
        <div className={className}>
          <JsonFormsDispatch
            renderers={renderers}
            cells={cells}
            uischema={child}
            schema={schema}
            path={path}
          />
        </div>
      ))}
    </>
  );
};
