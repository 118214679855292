import React from 'react';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { rankWith, uiTypeIs, RendererProps, or, UISchemaElement } from '@jsonforms/core';
import { Typography, Link } from 'src/components/kit';

export type TextElement = {
  type?: 'Text' | 'Paragraph' | 'Header' | 'Link' | 'BoxedParagraph';
  value: string | TextElement[] | TextElement;
  href?: string;
  label?: string;
};

export type TextElementOptions = {
  options?: {
    detail: TextElement | TextElement[];
  };
};

export type FormTextProps = UISchemaElement & TextElement & TextElementOptions;

const TextRenderer = (props: RendererProps): JSX.Element => {
  const { uischema } = props;
  const { value, href } = uischema as FormTextProps;
  if (uischema.type === 'Header') {
    return (
      <div className='mb-8'>
        <Typography variant='title1'>{value}</Typography>
      </div>
    );
  }
  if (uischema.type === 'Paragraph') {
    return (
      <div className='mb-8'>
        <Typography variant='body1'>{value}</Typography>
      </div>
    );
  }
  if (uischema.type === 'SubParagraph') {
    return <Typography variant='body1'>{value}</Typography>;
  }
  if (uischema.type === 'Link') {
    return (
      <Link href={href} target='_blank'>
        {value}
      </Link>
    );
  }
  return <Typography variant='body2'>{value}</Typography>;
};

export const TextTester = rankWith(
  5,
  or(
    uiTypeIs('Text'),
    uiTypeIs('Paragraph'),
    uiTypeIs('SubParagraph'),
    uiTypeIs('Header'),
    uiTypeIs('Link'),
    uiTypeIs('BoxedParagraph'),
  ),
);
export default withJsonFormsLayoutProps(TextRenderer);
