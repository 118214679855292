import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import axios, { AxiosError } from 'axios';
import { debuggingConfig, onboardingConfig } from 'src/config';
import getOnboardingSvcClient from 'src/lib/onboardingService';
import { validate, Validate } from 'src/utils/formValidators';
import { createAjv } from '@jsonforms/core';
import { createSummarySection } from 'src/pages/kyc/Summary/createSummarySection';
import { v4 as uuidv4 } from 'uuid';
import en from 'src/localization/en.json';
import useAnalytics from 'src/analytics/useAnalytics';
import {
  AffiliateLead,
  AffiliateLeadApplication,
  AffiliateLeadIdVerification,
  CountryCode,
  CreateAffiliateIdVerification,
  CreateAffiliateLead,
  CreateIdVerification,
  CreateLead,
  Lead,
  LeadApplication,
  OnboardingSettings,
  Schemas,
  SchemaStructure,
} from '../resources/api-client/onboarding';

export type UISchema = ArrayElement<Schemas['uiSchemas']>;
export type DataSchema = ArrayElement<Schemas['dataSchemas']>;

export interface AffiliateLeadWithFileList extends AffiliateLead {
  fileList?: any;
}

export interface SummarySchema {
  schemas: DataSchema[] | any;
  uischemas: UISchema[] | any;
  validateLead: Validate | any;
}

export interface CountryLimited {
  countryCode: string;
  countryName: string;
  areaCode: string;
}

export interface Document {
  id: string;
  fileName: string;
  created: string;
}

const defaultLanguage = {
  file: en,
  label: 'English',
  key: 'en',
};

export interface OnboardingState {
  lead?: AffiliateLeadWithFileList | Lead | null;
  idVerification?: AffiliateLeadIdVerification | null;
  getLeadStatus: 'loading' | 'ready' | 'error' | '404';
  postLeadStatus: 'posting' | 'ready' | 'error';
  putLeadStatus: 'posting' | 'ready' | 'error';
  leadApplication?: AffiliateLeadApplication | LeadApplication | null;
  postLeadApplicationStatus: 'posting' | 'ready' | 'error';
  getLeadApplicationStatus: 'loading' | 'ready' | 'error' | '404';
  postIdVerificationStatus: 'posting' | 'ready' | 'error';
  settings?: OnboardingSettings;
  getSettingsStatus: 'loading' | 'ready' | 'error' | '404';
  countries?: CountryLimited[];
  getCountriesStatus: 'loading' | 'ready' | 'error' | '404';
  getSchemaStatus: 'loading' | 'ready' | 'error' | '404';
  schemas?: any | null;
  getSummarySchemaStatus: 'loading' | 'ready' | 'error' | '404';
  summarySchema?: SummarySchema | null;
  getAffiliateStructureStatus: 'loading' | 'ready' | 'error' | '404';
  affiliateStructure: SchemaStructure | null;
  fileList: Document[];
  getFileListStatus: 'loading' | 'ready' | 'error' | '404';
  defaultCountry: CountryLimited | null;
  getDefaultCountryStatus: 'loading' | 'ready' | 'error';
  language: any;
  acceptLanguage: string;
}

export const initialState: OnboardingState = {
  lead: null,
  idVerification: null,
  getLeadStatus: 'loading',
  postLeadStatus: 'ready',
  putLeadStatus: 'ready',
  leadApplication: null,
  getLeadApplicationStatus: 'loading',
  postLeadApplicationStatus: 'ready',
  postIdVerificationStatus: 'posting',
  settings: null,
  getSettingsStatus: 'loading',
  countries: [],
  getCountriesStatus: 'loading',
  getSchemaStatus: 'loading',
  schemas: null,
  getSummarySchemaStatus: 'loading',
  summarySchema: null,
  affiliateStructure: null,
  getAffiliateStructureStatus: 'loading',
  fileList: [],
  getFileListStatus: 'loading',
  defaultCountry: null,
  getDefaultCountryStatus: 'loading',
  language: defaultLanguage,
  acceptLanguage: 'en-US',
};

const slice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setState(state: OnboardingState, action: PayloadAction<Partial<OnboardingState>>) {
      if (debuggingConfig.mode !== 'storybook') {
        console.error(
          'This reducer should not be used inside the application, this is only to facilitate storybook',
        );
        return;
      }
      Object.assign(state, action.payload);
    },
    resetState(state: OnboardingState) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
    setLeadStatus(
      state: OnboardingState,
      action: PayloadAction<{ status: 'loading' | 'ready' | 'error' | '404' }>,
    ) {
      const { status } = action.payload;
      state.getLeadStatus = status;
    },
    setLeadApplicationStatus(
      state: OnboardingState,
      action: PayloadAction<{ status: 'loading' | 'ready' | 'error' | '404' }>,
    ) {
      const { status } = action.payload;
      state.getLeadApplicationStatus = status;
    },
    setLead(
      state: OnboardingState,
      action: PayloadAction<{ lead: AffiliateLeadWithFileList | Lead }>,
    ) {
      const { lead } = action.payload;
      state.lead = lead;
      state.getLeadStatus = 'ready';
    },
    setIdVerification(
      state: OnboardingState,
      action: PayloadAction<{ idVerification: AffiliateLeadIdVerification }>,
    ) {
      const { idVerification } = action.payload;
      state.idVerification = idVerification;
    },
    setPostLeadStatus(
      state: OnboardingState,
      action: PayloadAction<{ status: 'posting' | 'ready' | 'error' }>,
    ) {
      const { status } = action.payload;
      state.postLeadStatus = status;
    },
    setPutLeadStatus(
      state: OnboardingState,
      action: PayloadAction<{
        status: 'posting' | 'ready' | 'error';
        kycSectionData?: any;
        kycSectionId?: string;
      }>,
    ) {
      const { status } = action.payload;
      state.putLeadStatus = status;
      if (action.payload.kycSectionData && action.payload.kycSectionId) {
        const updatedLead = { ...state.lead };
        updatedLead.kycSections[action.payload.kycSectionId] = action.payload?.kycSectionData;
        if (action.payload.kycSectionId === 'affiliate-experience') {
          updatedLead.kycSections['affiliate-documentUpload'] = {
            applicationType: action.payload?.kycSectionData?.applicationType,
          };
        }
        state.lead = updatedLead;
      }
    },
    setPostLeadApplicationStatus(
      state: OnboardingState,
      action: PayloadAction<{ status: 'posting' | 'ready' | 'error' }>,
    ) {
      const { status } = action.payload;
      state.postLeadApplicationStatus = status;
    },
    setPostIdVerificationStatus(
      state: OnboardingState,
      action: PayloadAction<{ status: 'posting' | 'ready' | 'error' }>,
    ) {
      const { status } = action.payload;
      state.postIdVerificationStatus = status;
    },
    setCountriesStatus(
      state: OnboardingState,
      action: PayloadAction<{ status: 'loading' | 'ready' | 'error' | '404' }>,
    ) {
      const { status } = action.payload;
      state.getCountriesStatus = status;
    },
    setCountries(state: OnboardingState, action: PayloadAction<{ countries: CountryLimited[] }>) {
      const { countries } = action.payload;
      state.countries = countries;
      state.getCountriesStatus = 'ready';
    },
    setSchemas(state: OnboardingState, action: PayloadAction<{ schemas: any | null }>) {
      const { schemas } = action.payload;
      state.schemas = schemas;
      state.getSchemaStatus = 'ready';
    },
    setSchemaStatus(
      state: OnboardingState,
      action: PayloadAction<{ status: 'loading' | 'ready' | 'error' | '404' }>,
    ) {
      const { status } = action.payload;
      state.getSchemaStatus = status;
    },

    setSettingsStatus(
      state: OnboardingState,
      action: PayloadAction<{ status: 'loading' | 'ready' | 'error' | '404' }>,
    ) {
      const { status } = action.payload;
      state.getSettingsStatus = status;
    },
    setSettings(state: OnboardingState, action: PayloadAction<{ settings: OnboardingSettings }>) {
      const { settings } = action.payload;
      state.settings = settings;
      state.getSettingsStatus = 'ready';
    },
    setSummarySchema(
      state: OnboardingState,
      action: PayloadAction<{ summarySchema: SummarySchema | null }>,
    ) {
      const { summarySchema } = action.payload;
      state.summarySchema = summarySchema;
      state.getSummarySchemaStatus = 'ready';
    },
    setSummarySchemaStatus(
      state: OnboardingState,
      action: PayloadAction<{ status: 'loading' | 'ready' | 'error' | '404' }>,
    ) {
      const { status } = action.payload;
      state.getSummarySchemaStatus = status;
    },
    setAffiliateStructure(
      state: OnboardingState,
      action: PayloadAction<{ affiliateStructure: any | null }>,
    ) {
      const { affiliateStructure } = action.payload;
      state.affiliateStructure = affiliateStructure;
      state.getAffiliateStructureStatus = 'ready';
    },
    setAffiliateStructureStatus(
      state: OnboardingState,
      action: PayloadAction<{ status: 'loading' | 'ready' | 'error' | '404' }>,
    ) {
      const { status } = action.payload;
      state.getAffiliateStructureStatus = status;
    },

    setFileList(state: OnboardingState, action: PayloadAction<{ fileList: any }>) {
      const { fileList } = action.payload;
      state.fileList = fileList;
      state.getFileListStatus = 'ready';
    },
    setFileListStatus(
      state: OnboardingState,
      action: PayloadAction<{ status: 'loading' | 'ready' | 'error' | '404' }>,
    ) {
      const { status } = action.payload;
      state.getFileListStatus = status;
    },
    addFile(state: OnboardingState, action: PayloadAction<{ file: any }>) {
      const { file } = action.payload;
      state.fileList = [...state.fileList, file];
    },
    removeFile(state: OnboardingState, action: PayloadAction<{ fileId: string }>) {
      const { fileId } = action.payload;
      state.fileList = state.fileList.filter((currentFile: any) => currentFile.id !== fileId);
    },
    setDefaultCountryOption(
      state: OnboardingState,
      action: PayloadAction<{ country: CountryLimited }>,
    ) {
      const { country } = action.payload;
      state.defaultCountry = country;
      state.getDefaultCountryStatus = 'ready';
    },
    setDefaultCountryOptionStatus(
      state: OnboardingState,
      action: PayloadAction<{ status: 'loading' | 'ready' | 'error' }>,
    ) {
      const { status } = action.payload;
      state.getCountriesStatus = status;
    },
    setLanguage(state: OnboardingState, action: PayloadAction<{ language: any }>) {
      const { language } = action.payload;
      state.language = language;
      localStorage.setItem('language', JSON.stringify(language));
    },
    setAcceptLanguage(state: OnboardingState, action: PayloadAction<{ acceptLanguage: any }>) {
      const { acceptLanguage } = action.payload;
      state.acceptLanguage = acceptLanguage;
      localStorage.setItem('acceptLanguage', acceptLanguage);
    },
  },
});

// For getting updated data without affecting the app state.
export const getUpdatedLeadData = async (): Promise<any> => {
  try {
    const onboardingClient = await getOnboardingSvcClient();
    const res = await onboardingClient.affliateLead.affiliateLeadGet('');
    const leadResponse = res.data as AffiliateLead;
    return leadResponse;
  } catch (error) {
    return 'error';
  }
};

export const setLanguage =
  (language: any): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setLanguage({ language }));
  };
export const setAcceptLanguage =
  (acceptLanguage: any): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setAcceptLanguage({ acceptLanguage }));
  };
export const setFileList =
  (fileList: any): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setFileList({ fileList }));
  };

export const addFile =
  (file: any): AppThunk =>
  async (dispatch) => {
    const trackEvent = useAnalytics();
    const onboardingClient = await getOnboardingSvcClient();
    if (!file.error) {
      onboardingClient.affliateLead
        .affiliateLeadDocumentPost('', '', file)
        .then((res) => {
          const fileToAdd = {
            id: res.data,
            fileName: file.name,
            created: Date.now().toString(),
          };

          dispatch(slice.actions.addFile({ file: fileToAdd }));
          trackEvent('Affiliate KYC - Document Uploaded', { file: fileToAdd });
        })
        .catch(async (error: AxiosError) => {
          const errorFile = {
            id: uuidv4(),
            fileName: file.name,
            error: error.response.data,
            created: Date.now(),
          };
          dispatch(slice.actions.addFile({ file: errorFile }));
        });
    } else {
      const errorFile = {
        id: uuidv4(),
        fileName: file.name,
        error: file.error,
        created: Date.now(),
      };
      dispatch(slice.actions.addFile({ file: errorFile }));
    }
  };
export const removeFile =
  (fileId: string, error?: string): AppThunk =>
  async (dispatch) => {
    if (!error) {
      const trackEvent = useAnalytics();
      const onboardingClient = await getOnboardingSvcClient();
      onboardingClient.affliateLead
        .affiliateLeadDocumentDelete('', fileId)
        .then(() => {
          dispatch(slice.actions.removeFile({ fileId }));
          trackEvent('Affiliate KYC - Document Removed', { fileId });
        })
        .catch(async (axiosErr: AxiosError) => {
          console.error(axiosErr);
        });
    } else {
      dispatch(slice.actions.removeFile({ fileId }));
    }
  };

export const getCountrySetting =
  (country: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSettingsStatus({ status: 'loading' }));
    const onboardingClient = await getOnboardingSvcClient();
    onboardingClient.settings
      .settingsCountryCodeGet(country as CountryCode)
      .then((res) => {
        const settings = res.data as OnboardingSettings;
        dispatch(slice.actions.setSettings({ settings }));
      })
      .catch(async (error: AxiosError) => {
        console.log(error);
        if (error.response && error.response.status === 404) {
          dispatch(slice.actions.setSettingsStatus({ status: '404' }));
        } else {
          dispatch(slice.actions.setSettingsStatus({ status: 'error' }));
        }
      });
  };

export const getDefaultCountryOption = (): AppThunk => async (dispatch) => {
  axios
    .get('https://ipapi.co/json/')
    .then((response) => {
      const { data } = response;
      const country = {
        countryCode: data.country,
        countryName: data.country_name,
        areaCode: data.country_calling_code,
      };
      dispatch(slice.actions.setDefaultCountryOption({ country }));
    })
    .catch((error) => {
      console.log(error);
      dispatch(
        slice.actions.setDefaultCountryOption({
          country: {
            areaCode: '',
            countryName: '',
            countryCode: '',
          },
        }),
      );
    });
};

export const getCountries =
  (acceptLanguage: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setCountriesStatus({ status: 'loading' }));
    const onboardingClient = await getOnboardingSvcClient();
    onboardingClient.countries
      .countriesGet(acceptLanguage)
      .then((res) => {
        const countries = res.data as unknown as CountryLimited[];
        dispatch(slice.actions.setCountries({ countries }));
      })
      .catch(async (error: AxiosError) => {
        console.log(error);
        if (error.response && error.response.status === 404) {
          dispatch(slice.actions.setCountriesStatus({ status: '404' }));
        } else {
          dispatch(slice.actions.setCountriesStatus({ status: 'error' }));
        }
      });
  };

export const getData =
  (acceptLanguage: string): AppThunk =>
  async (dispatch) => {
    const trackEvent = useAnalytics();
    dispatch(slice.actions.setLeadStatus({ status: 'loading' }));
    const onboardingClient = await getOnboardingSvcClient();
    if (onboardingConfig.for === 'affiliate-lead') {
      onboardingClient.affliateLead
        .affiliateLeadGet('', acceptLanguage)
        .then((res) => {
          const lead = res.data as AffiliateLeadWithFileList | Lead;
          const idVerification = lead?.idVerification;
          dispatch(slice.actions.setLead({ lead }));
          dispatch(slice.actions.setIdVerification({ idVerification }));
          dispatch(getFileList(lead));

          // dispatch(getCountrySetting(lead.countryCode));
          // dispatch(getAffiliateStructureByCountryCode(lead.countryCode));
          const jumioVerificationId = localStorage.getItem('JumioVerificationId');
          if (jumioVerificationId && jumioVerificationId?.trim() !== '') {
            console.log('passed here');
            const request: CreateAffiliateIdVerification = {
              reference: jumioVerificationId,
            };
            dispatch(updateJumioVerification(request, acceptLanguage));
            trackEvent('Affiliate ID Verification Completed', undefined);
            localStorage.setItem('JumioVerificationId', '');
          }
        })
        .catch(async (error: AxiosError) => {
          if (error.response && error.response.status === 404) {
            dispatch(slice.actions.setLeadStatus({ status: '404' }));
          } else {
            dispatch(slice.actions.setLeadStatus({ status: 'error' }));
          }
        });
    } else {
      onboardingClient.lead
        .leadGet('', acceptLanguage)
        .then((res) => {
          const lead = res.data as AffiliateLeadWithFileList | Lead;
          dispatch(slice.actions.setLead({ lead }));
        })
        .catch(async (error: AxiosError) => {
          if (error.response && error.response.status === 404) {
            dispatch(slice.actions.setLeadStatus({ status: '404' }));
          } else {
            dispatch(slice.actions.setLeadStatus({ status: 'error' }));
          }
        });
    }
  };

export const saveData =
  (request: CreateAffiliateLead | CreateLead, acceptLanguage: string): AppThunk =>
  async (dispatch) => {
    const trackEvent = useAnalytics();
    dispatch(slice.actions.setPostLeadStatus({ status: 'posting' }));
    const onboardingClient = await getOnboardingSvcClient();
    if (onboardingConfig.for === 'affiliate-lead') {
      const userDetails = request as CreateAffiliateLead;
      onboardingClient.affliateLead
        .affiliateLeadPost('', acceptLanguage, request as CreateAffiliateLead)
        .then(() => {
          dispatch(slice.actions.setPostLeadStatus({ status: 'ready' }));
          window.location.href = '/kyc';
          trackEvent('Affiliate Application Started', {
            signupDetails: {
              firstName: userDetails.firstName,
              lastName: userDetails.lastName,
              countryCode: userDetails.countryCode,
              phoneCountryCode: userDetails.phoneCountryCode,
              phoneNumber: userDetails.phoneNumber,
              emailAddress: userDetails.emailAddress,
              promoCode: userDetails.promoCode,
            },
          });
        })
        .catch(async () => {
          dispatch(slice.actions.setPostLeadStatus({ status: 'error' }));
        });
    } else {
      onboardingClient.lead
        .leadPost('', acceptLanguage, request as CreateLead)
        .then((res) => {
          // change to leadGet
          console.log(res);
          dispatch(slice.actions.setPostLeadStatus({ status: 'ready' }));
        })
        .catch(async (error: AxiosError) => {
          console.log(error);
          dispatch(slice.actions.setPostLeadStatus({ status: 'error' }));
        });
    }
  };

export const saveKycSection =
  (
    request: { [key: string]: any },
    kycSectionId: string,
    acceptLanguage: string,
    onDone: () => void,
    isSaveAndLogout?: boolean,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setPutLeadStatus({ status: 'posting' }));
    const onboardingClient = await getOnboardingSvcClient();
    if (onboardingConfig.for === 'affiliate-lead') {
      onboardingClient.affliateLead
        .affiliateLeadKycSectionsKycSectionIdPut(
          kycSectionId,
          acceptLanguage,
          request as { [key: string]: any },
        )
        .then(() => {
          if (!isSaveAndLogout) {
            dispatch(
              slice.actions.setPutLeadStatus({
                status: 'ready',
                kycSectionData: request,
                kycSectionId,
              }),
            );
          }
          onDone();
        })
        .catch(async (error: AxiosError) => {
          console.log(error);
          dispatch(slice.actions.setPutLeadStatus({ status: 'error' }));
        });
    } else {
      onboardingClient.lead
        .leadPut('', acceptLanguage, request as CreateLead)
        .then((res) => {
          console.log(res);
          dispatch(slice.actions.setPutLeadStatus({ status: 'ready' }));
          onDone();
        })
        .catch(async (error: AxiosError) => {
          console.log(error);
          dispatch(slice.actions.setPutLeadStatus({ status: 'error' }));
        });
    }
  };

export const submitObApplication =
  (acceptLanguage: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setPostLeadApplicationStatus({ status: 'posting' }));
    const onboardingClient = await getOnboardingSvcClient();
    if (onboardingConfig.for === 'affiliate-lead') {
      onboardingClient.affliateLead
        .affiliateLeadApplicationPost('')
        .then((res) => {
          console.log(res);
          dispatch(getData(acceptLanguage));
          dispatch(slice.actions.setPostLeadApplicationStatus({ status: 'ready' }));
        })
        .catch(async (error: AxiosError) => {
          console.log(error);
          dispatch(slice.actions.setPostLeadApplicationStatus({ status: 'error' }));
        });
    } else {
      onboardingClient.lead
        .leadApplicationPost('')
        .then((res) => {
          console.log(res);
          dispatch(getData(acceptLanguage));
          dispatch(slice.actions.setPostLeadApplicationStatus({ status: 'ready' }));
        })
        .catch(async (error: AxiosError) => {
          console.log(error);
          dispatch(slice.actions.setPostLeadApplicationStatus({ status: 'error' }));
        });
    }
  };

export const updateJumioVerification =
  (
    request: CreateAffiliateIdVerification | CreateIdVerification,
    acceptLanguage: string,
  ): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setPostIdVerificationStatus({ status: 'posting' }));
    const onboardingClient = await getOnboardingSvcClient();
    if (onboardingConfig.for === 'affiliate-lead') {
      onboardingClient.affliateLead
        .affiliateLeadIdVerificationPost(
          '',
          acceptLanguage,
          request as CreateAffiliateIdVerification,
        )
        .then((res) => {
          console.log(res);
          dispatch(slice.actions.setPostIdVerificationStatus({ status: 'ready' }));
        })
        .catch(async (error: AxiosError) => {
          console.log(error);
          dispatch(slice.actions.setPostIdVerificationStatus({ status: 'error' }));
        });
    } else {
      onboardingClient.lead
        .leadIdVerificationPost('', acceptLanguage, request as CreateIdVerification)
        .then((res) => {
          console.log(res);
          dispatch(slice.actions.setPostLeadApplicationStatus({ status: 'ready' }));
        })
        .catch(async (error: AxiosError) => {
          console.log(error);
          dispatch(slice.actions.setPostLeadApplicationStatus({ status: 'error' }));
        });
    }
  };

export const getSchema =
  (acceptLanguage: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSchemaStatus({ status: 'loading' }));
    const onboardingClient = await getOnboardingSvcClient();
    if (onboardingConfig.for === 'affiliate-lead') {
      onboardingClient.affliateLead
        .affiliateLeadSchemasGet('', acceptLanguage)
        .then((res) => {
          const schemas = res.data as Schemas;
          dispatch(slice.actions.setSchemas({ schemas }));
        })
        .catch(async (error: AxiosError) => {
          if (error.response && error.response.status === 404) {
            dispatch(slice.actions.setSchemaStatus({ status: '404' }));
          } else {
            dispatch(slice.actions.setSchemaStatus({ status: 'error' }));
          }
        });
    } else {
      onboardingClient.lead
        .leadSchemasGet('', acceptLanguage)
        .then((res) => {
          const schemas = res.data as Schemas;
          dispatch(slice.actions.setSchemas({ schemas }));
        })
        .catch(async (error: AxiosError) => {
          if (error.response && error.response.status === 404) {
            dispatch(slice.actions.setSchemaStatus({ status: '404' }));
          } else {
            dispatch(slice.actions.setLeadStatus({ status: 'error' }));
          }
        });
    }
  };

export const getSummarySchema =
  (acceptLanguage: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setSummarySchemaStatus({ status: 'loading' }));
    const onboardingClient = await getOnboardingSvcClient();
    if (onboardingConfig.for === 'affiliate-lead') {
      onboardingClient.affliateLead
        .affiliateLeadSchemasGet('', acceptLanguage)
        .then((res) => {
          const schemas = res.data as Schemas;
          const { uiSchemas, dataSchemas } = schemas;
          if (!uiSchemas || !dataSchemas) {
            dispatch(slice.actions.setSummarySchemaStatus({ status: '404' }));
          }
          const personalDetailsDataSchema = dataSchemas.find(
            (x) => x.schemaName === 'affiliate-personalDetails',
          )?.schema;
          const financesDataSchema = dataSchemas.find(
            (x) => x.schemaName === 'affiliate-finances',
          )?.schema;
          const experienceDataSchema = dataSchemas.find(
            (x) => x.schemaName === 'affiliate-experience',
          )?.schema;
          const declarationDataSchema = dataSchemas.find(
            (x) => x.schemaName === 'affiliate-declaration',
          )?.schema;

          if (!personalDetailsDataSchema || !financesDataSchema) {
            dispatch(slice.actions.setSummarySchemaStatus({ status: 'error' }));
          }
          const summarySchema: SummarySchema = {
            schemas: dataSchemas,
            uischemas: uischemaWithSummarySectionCreated(uiSchemas),
            validateLead: validatorAffiliateLead(
              personalDetailsDataSchema,
              financesDataSchema,
              experienceDataSchema,
              declarationDataSchema,
            ),
          };
          dispatch(slice.actions.setSummarySchema({ summarySchema }));
        })
        .catch(async (error: AxiosError) => {
          if (error.response && error.response.status === 404) {
            dispatch(slice.actions.setSummarySchemaStatus({ status: '404' }));
          } else {
            dispatch(slice.actions.setSummarySchemaStatus({ status: 'error' }));
          }
        });
    } else {
      onboardingClient.lead
        .leadSchemasGet('', acceptLanguage)
        .then((res) => {
          const schemas = res.data as Schemas;
          const { uiSchemas, dataSchemas } = schemas;
          if (!uiSchemas || !dataSchemas) {
            dispatch(slice.actions.setSummarySchemaStatus({ status: '404' }));
          }
          const personalDetailsDataSchema = dataSchemas.find(
            (x) => x.schemaName === 'personalDetails',
          )?.schema;
          const financesDataSchema = dataSchemas.find(
            (x) => x.schemaName === 'financeDetails',
          )?.schema;

          if (!personalDetailsDataSchema || !financesDataSchema) {
            dispatch(slice.actions.setSummarySchemaStatus({ status: 'error' }));
          }
          const summarySchema: SummarySchema = {
            schemas: dataSchemas,
            uischemas: uischemaWithSummarySectionCreated(uiSchemas),
            validateLead: validatorLead(),
          };
          dispatch(slice.actions.setSummarySchema({ summarySchema }));
        })
        .catch(async (error: AxiosError) => {
          if (error.response && error.response.status === 404) {
            dispatch(slice.actions.setSummarySchemaStatus({ status: '404' }));
          } else {
            dispatch(slice.actions.setSummarySchemaStatus({ status: 'error' }));
          }
        });
    }
  };

const uischemaWithSummarySectionCreated = (uiSchemas: any): UISchema[] => {
  let summarySchema;
  uiSchemas.map((originalUiSchema) => {
    // The links to the other kyc sections in the summary form are created clientside by passing the initial Summary form ui text from the API, and merging the other uischemas into it.
    if (originalUiSchema.schemaName === 'affiliate-summary') {
      const uiSchemasToTransform = uiSchemas?.filter((uiSchema) => !uiSchema.schema.hideInSummary);
      summarySchema = createSummarySection(originalUiSchema, uiSchemasToTransform);
      return summarySchema;
    }
    return originalUiSchema;
  });
  return summarySchema;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const validatorAffiliateLead = (
  personalDetailsDataSchema,
  financesDataSchema,
  experienceDataSchema,
  declarationDataSchema,
) => {
  validate({
    personalDetailsAjv: createAjv({
      useDefaults: true,
    }).compile(personalDetailsDataSchema),
    financesAjv: createAjv({
      useDefaults: true,
    }).compile(financesDataSchema),
    experienceAjv: createAjv({
      useDefaults: true,
    }).compile(experienceDataSchema),
    declarationAjv: createAjv({
      useDefaults: true,
    }).compile(declarationDataSchema),
  });
};

const validatorLead = () => {
  validate({
    personalDetailsAjv: undefined,
    financesAjv: undefined,
    experienceAjv: undefined,
    declarationAjv: undefined,
  });
};

export const getAffiliateStructureByCountryCode =
  (countryCode: CountryCode): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setAffiliateStructureStatus({ status: 'loading' }));
    const onboardingClient = await getOnboardingSvcClient();
    if (onboardingConfig.for === 'affiliate-lead') {
      onboardingClient.countries
        .countriesCountryCodeAffiliateStructureGet(countryCode, '')
        .then((res) => {
          const affiliateStructure = res.data as SchemaStructure;
          dispatch(slice.actions.setAffiliateStructure({ affiliateStructure }));
        })
        .catch(async (error: AxiosError) => {
          if (error.response && error.response.status === 404) {
            dispatch(slice.actions.setAffiliateStructureStatus({ status: '404' }));
          } else {
            dispatch(slice.actions.setAffiliateStructureStatus({ status: 'error' }));
          }
        });
    }
  };

export const getIdVerificationData =
  (acceptLanguage: string): AppThunk =>
  async (dispatch) => {
    const onboardingClient = await getOnboardingSvcClient();
    if (onboardingConfig.for === 'affiliate-lead') {
      onboardingClient.affliateLead
        .affiliateLeadGet('', acceptLanguage)
        .then((res) => {
          const lead = res.data as AffiliateLead | Lead;
          const idVerification = lead?.idVerification;
          dispatch(slice.actions.setIdVerification({ idVerification }));
        })
        .catch(async (error: AxiosError) => {
          console.log(error);
        });
    } else {
      onboardingClient.lead
        .leadGet('', acceptLanguage)
        .then((res) => {
          const lead = res.data as AffiliateLead | Lead;
          const idVerification = lead?.idVerification;
          dispatch(slice.actions.setIdVerification({ idVerification }));
        })
        .catch(async (error: AxiosError) => {
          console.log(error);
        });
    }
  };

export const getFileList =
  (lead: AffiliateLead | Lead): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setFileListStatus({ status: 'loading' }));
    const onboardingClient = await getOnboardingSvcClient();
    if (onboardingConfig.for === 'affiliate-lead') {
      onboardingClient.affliateLead
        .affiliateLeadDocumentsGet('')
        .then((res) => {
          const fileList = res.data as unknown as Document[];
          dispatch(slice.actions.setFileList({ fileList }));
          dispatch(slice.actions.setLead({ lead: { ...lead, fileList } }));
        })
        .catch(async (error: AxiosError) => {
          if (error.response && error.response.status === 404) {
            dispatch(slice.actions.setFileListStatus({ status: '404' }));
            dispatch(slice.actions.setFileList({ fileList: [] }));
            dispatch(slice.actions.setLead({ lead: { ...lead, fileList: [] } }));
          } else {
            dispatch(slice.actions.setFileListStatus({ status: 'error' }));
            dispatch(slice.actions.setFileList({ fileList: [] }));
            dispatch(slice.actions.setLead({ lead: { ...lead, fileList: [] } }));
          }
        });
    } else {
      // TO DO
    }
  };

export const setLead =
  (lead: AffiliateLeadWithFileList | Lead): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setLead({ lead }));
  };

export const { reducer } = slice;
export default slice;
