import React, { useMemo, useState, useEffect } from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import {
  rankWith,
  ControlProps,
  uiTypeIs,
  ControlElement,
  UISchemaElement,
  JsonSchema,
} from '@jsonforms/core';
import { Input, Select, TextArea } from 'src/components/kit';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

export type DropdownElement = ControlElementWithPlaceholder & {
  displayValues: Record<string, string>;
  readonly options?: {
    disableSearch?: boolean;
  };
};

export type TextAreaElement = ControlElementWithPlaceholder & {
  readonly options?: {
    multi: boolean;
  };
};

export type ControlElementWithPlaceholder = {
  placeholder?: string;
} & ControlElement;

export type UISchemaControlElement = UISchemaElement & { scope: string };

export function getPropertyNameFromControl(controlElement: UISchemaControlElement): string {
  return controlElement.scope.replace('#/properties/', '');
}

function isDropdown(uischema: ControlElementWithPlaceholder): uischema is DropdownElement {
  return Boolean((uischema as DropdownElement)?.displayValues);
}

function isTextArea(uischema: ControlElementWithPlaceholder): uischema is TextAreaElement {
  return Boolean((uischema as TextAreaElement)?.options?.multi);
}

function mapValuesToDropdown(displayValues: Record<string, string>): any {
  return Object.entries(displayValues).map((values: any) => ({
    value: values[0],
    label: values[1],
  }));
}

function getSelectValue(mappedData: any, data: any) {
  const selectValue = mappedData.find((current: any) => current.value === data);
  return selectValue;
}

interface ControlWrapperProps {
  uischema: UISchemaElement | any;
  handleChange: (path: string, value: any) => void;
  path: string;
  errors: any;
  data: string;
  schema: JsonSchema | any;
}

const ControlWrapper: React.FC<ControlWrapperProps> = ({
  uischema,
  errors,
  path,
  data,
  handleChange,
  schema,
}): JSX.Element => {
  const label = uischema.label?.toString()?.replace('[bold]', '');
  const boldLabel = !!uischema.label?.toString()?.includes('[bold]');
  const placeholder = uischema.placeholder as string;
  const [validationError, setValidationError] = useState<string>(errors);
  const formTouched = useSelector((state: any) => state.auth.formTouched);
  const intl = useIntl();

  useEffect(() => {
    setValidationErrorMessage();
  }, [data]);

  useEffect(() => {
    setValidationErrorMessage();
  }, [formTouched]);

  const setValidationErrorMessage = () => {
    if (!data) {
      if (!errors) {
        setValidationError('');
      } else if (errors) {
        setValidationError(
          intl.formatMessage({
            id: 'kyc.validation.fieldIsRequired',
            defaultMessage: 'This field is required.',
          }),
        );
      }
    } else if (data !== '' && errors) {
      if (schema.message) {
        setValidationError(`${schema.message}`);
      } else {
        setValidationError(errors);
      }
    } else if (!errors) {
      setValidationError('');
    }
  };

  const mappedData = useMemo(
    () => mapValuesToDropdown((uischema as DropdownElement)?.displayValues || {}),
    [uischema],
  );

  if (isDropdown(uischema)) {
    return (
      <div className='wide-control-container'>
        <Select
          searchable={!uischema?.options?.disableSearch}
          placeholder={placeholder}
          disabled={uischema?.options?.readonly}
          handleChange={(selectedItem: any) => {
            handleChange(path, selectedItem.value);
          }}
          value={data && getSelectValue(mappedData, data && data)}
          name={uischema.scope}
          label={`${label}`}
          options={mappedData}
          error={formTouched && `${validationError}`}
          variant={boldLabel ? 'boldLabel' : 'default'}
          width='350px'
        />
      </div>
    );
  }

  if (isTextArea(uischema)) {
    return (
      <div className='wide-control-container'>
        <TextArea
          label={label}
          maxLength={schema?.maxLength ?? 250}
          name={uischema.scope}
          value={data && data}
          handleChange={(e: any) => {
            handleChange(path, e.target.value);
          }}
          placeholder={placeholder}
          error={formTouched && validationError}
          variant={boldLabel ? 'boldLabel' : 'default'}
        />
      </div>
    );
  }

  return (
    <div className='control-container'>
      <Input
        disabled={uischema?.options?.readonly}
        label={label}
        name={uischema.scope}
        value={data && data}
        handleChange={(e: any) => {
          handleChange(path, e.target.value);
        }}
        placeholder={placeholder}
        error={formTouched && validationError}
        maxLength={schema?.maxLength ?? 100}
      />
    </div>
  );
};

const ControlRenderer = (props: ControlProps): JSX.Element => {
  const { handleChange, uischema, data, path, errors, schema } = props;
  return (
    <ControlWrapper
      uischema={uischema}
      data={data}
      path={path}
      errors={errors}
      schema={schema}
      handleChange={(uipath: string, value: string) => handleChange(uipath, value)}
    />
  );
};

export const ControlTester = rankWith(100, uiTypeIs('Control'));

export default withJsonFormsControlProps(ControlRenderer);
