import React from 'react';
import clsx from 'clsx';
import styles from './Input.module.scss';

interface InputProps {
  error?: string;
  disabled?: boolean;
  htmlFor?: string;
  label?: string;
  name: string;
  handleChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  datatestid?: string;
  maxLength?: number;
}

const Input: React.FC<InputProps> = (props: InputProps) => {
  const {
    error = '',
    disabled = false,
    htmlFor,
    label,
    name,
    handleChange = () => false,
    placeholder = '',
    value,
    datatestid,
    maxLength = 100,
  } = props;
  const errorTestId = `${datatestid}Error`;
  return (
    <>
      {label && (
        <label
          className={clsx(styles.label, {
            [styles.disabled]: disabled,
          })}
          htmlFor={htmlFor}>
          {label}
        </label>
      )}
      <input
        className={clsx(styles.input, {
          [styles.hasError]: error.length,
        })}
        disabled={disabled}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
        data-testid={datatestid}
        maxLength={maxLength}
      />
      {error && (
        <div className={styles.error} data-testid={errorTestId}>
          {error}
        </div>
      )}
    </>
  );
};

export default Input;
