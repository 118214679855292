export const affiliateStructure = {
  sections: [
    {
      id: 'affiliate-personalDetails',
      type: 'UiAndDataServerValidated',
    },
    {
      id: 'affiliate-idVerification',
      type: 'UiOnly',
    },
    {
      id: 'affiliate-finances',
      type: 'UiAndDataServerValidated',
    },
    {
      id: 'affiliate-experience',
      type: 'UiAndDataServerValidated',
    },
    {
      id: 'affiliate-documentUpload',
      type: 'UiOnly',
    },
    {
      id: 'affiliate-declaration',
      type: 'UiAndData',
    },
    {
      id: 'affiliate-summary',
      type: 'UiOnly',
    },
  ],
};
