import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import routes, { renderRoutes } from 'src/routes';
import { IntlProvider } from 'react-intl';
import en from 'src/localization/en.json';
import languages from 'src/utils/languages';
import { setAuthentication } from './slices/auth';
import { setLanguage, setAcceptLanguage } from './slices/onboarding';

const history = createBrowserHistory();
const userLocales =
  navigator.languages && navigator.languages.length ? navigator.languages : [navigator.language];
const userLanguages = userLocales.map((locale) =>
  locale.indexOf('-') > 0 ? locale.substring(0, locale.indexOf('-')) : locale,
);
const preferredLanguage =
  userLanguages.find((userLanguage) =>
    languages.find((language) => language.key === userLanguage),
  ) ?? 'en';
const userCulture = userLocales[0].substring(userLocales[0].indexOf('-') + 1); // Always use the user's preferred culture to format numbers, dates, etc
const locale = `${preferredLanguage}-${userCulture}`;
const languageObject = languages.filter((language: any) => language.key === preferredLanguage)[0];

const App: React.FC = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, status } = useSelector((state: any) => state.auth);
  const { language } = useSelector((state: any) => state.onboarding);

  useEffect(() => {
    const currentLanguage = JSON.parse(localStorage.getItem('language'));
    const currentAcceptLanguage = localStorage.getItem('acceptLanguage');
    if (!currentLanguage) {
      dispatch(setLanguage(languageObject));
      dispatch(setAcceptLanguage(locale));
      dispatch(setAuthentication(locale));
    } else {
      dispatch(setLanguage(languages.filter((item: any) => item.key === currentLanguage?.key)[0]));
      dispatch(setAcceptLanguage(currentAcceptLanguage));
      dispatch(setAuthentication(currentAcceptLanguage));
    }
  }, []);

  useEffect(() => {
    if (status === 'ready') {
      if (isAuthenticated === true) {
        history.push('/kyc');
      }
    }
  }, [isAuthenticated, status]);

  return (
    <div className='App' data-testid='App'>
      <IntlProvider messages={language?.file ?? en} locale={language?.key ?? 'en'}>
        <Router history={history}>{renderRoutes(routes)}</Router>
      </IntlProvider>
    </div>
  );
};

export default App;
