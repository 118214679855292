/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import clsx from 'clsx';
import styles from './TextArea.module.scss';

interface TextAreaProps {
  error?: string;
  disabled?: boolean;
  htmlFor?: string;
  label?: string;
  name: string;
  handleChange?: (event: React.FormEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  value?: string;
  datatestid?: string;
  variant?: 'default' | 'boldLabel';
  maxLength?: number;
}

const TextArea: React.FC<TextAreaProps> = (props: TextAreaProps) => {
  const {
    error = '',
    disabled = false,
    htmlFor,
    label,
    name,
    handleChange = () => false,
    placeholder = '',
    value,
    datatestid,
    variant = 'default',
    maxLength = 250,
  } = props;
  const errorTestId = `${datatestid}Error`;
  const [inputText, setInputText] = useState('');
  let labelStyle = styles.label;
  if (variant === 'boldLabel') {
    labelStyle = styles.boldLabel;
  }
  const onChange = (e: any): void => {
    setInputText(e.target.value);
    handleChange(e);
  };

  return (
    <>
      {label && (
        <label
          className={clsx(labelStyle, {
            [styles.disabled]: disabled,
          })}
          htmlFor={htmlFor}>
          {label}
        </label>
      )}
      <div className={clsx(styles.Wrapper)}>
        <textarea
          className={clsx(styles.input, {
            [styles.hasError]: error.length,
          })}
          disabled={disabled}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          data-testid={datatestid}
          rows={4}
          maxLength={maxLength}
        />
        <label className={clsx(styles.counter)}>
          {inputText.length}/{maxLength}
        </label>
      </div>
      {error && (
        <div className={styles.error} data-testid={errorTestId}>
          {error}
        </div>
      )}
    </>
  );
};

export default TextArea;
