/* eslint-disable react-hooks/rules-of-hooks */
import { and, optionIs, rankWith, UISchemaElement, uiTypeIs } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox } from 'src/components/kit';

interface CheckboxDatetimeProps {
  uischema: UISchemaElement | any;
  handleChange: (path: string, value?: string) => void;
  path: string;
  data: string;
  errors: any;
}

// eslint-disable-next-line no-multi-assign
const CheckboxDatetimeRenderer = (props: CheckboxDatetimeProps): JSX.Element => {
  const { uischema, path, data, handleChange, errors } = props;
  const [isChecked, setIsChecked] = useState(
    data !== null && data !== undefined && data?.trim() !== '',
  );

  const label = uischema.label as string;
  const formTouched = useSelector((state: any) => state.auth.formTouched);

  useEffect(() => {
    if (data) {
      setIsChecked(data !== null && data !== undefined && data?.trim() !== '');
    }
  }, [data]);
  return (
    <div className='wide-control-container mt-8'>
      <Checkbox
        label={label}
        onClick={() => {
          setIsChecked(!isChecked);
          if (!isChecked) {
            handleChange(path, new Date().toISOString());
          } else {
            handleChange(path, '');
          }
        }}
        error={formTouched && errors}
        checked={isChecked}
      />
    </div>
  );
};

export const CheckboxDatetimeTester = rankWith(
  105,
  and(uiTypeIs('CheckboxDateTime'), optionIs('format', 'checkbox_datetime')),
);
export default withJsonFormsControlProps(CheckboxDatetimeRenderer);
