import React from 'react';
import clsx from 'clsx';

import styles from './Link.module.scss';

interface LinkProps {
  /**
   * Link content
   */
  children?: any;
  /**
   * Scss class that apply to button
   */
  className?: string;
  /**
   * Link URL
   */
  href?: string;
  /**
   * Specifies the relationship between the current document and the linked document
   */
  rel?: string;
  /**
   * Specifies where to open the linked document
   */
  target?: string;
  variant?: 'default' | 'noUnderline';
}

const Link: React.FC<LinkProps> = ({
  children,
  className,
  href,
  rel,
  target,
  variant = 'default',
  ...otherProps
}: LinkProps) => (
  <a
    className={clsx(styles.link, styles[variant], className)}
    href={href}
    rel={rel}
    target={target}
    {...otherProps}>
    {children}
  </a>
);

export default Link;
