/* eslint-disable @typescript-eslint/explicit-function-return-type */
import jwtDecode from 'jwt-decode';

export const CUSTOMER_ID_CLAIM = 'https://axi.com/customerId';
export const CUSTOMER_SIGNATURE_CLAIM = 'https://axi.com/customerSignature';

export interface AccessToken {
  iss: string;
  sub: string;
  aud: string[];
  iat: Date;
  exp: Date;
  azp: string;
  scope: string;
  [CUSTOMER_ID_CLAIM]: string;
  [CUSTOMER_SIGNATURE_CLAIM]: string;
}

export function tryGetCustomerIdFromToken(accessToken: string): string | false {
  const parsed = parseAccessToken(accessToken);
  const customerId = parsed[CUSTOMER_ID_CLAIM];
  return typeof customerId !== 'undefined' ? customerId : false;
}

export function accessTokenContainsCustomerIdClaims(accessToken: string): boolean {
  const parsedAccessToken = parseAccessToken(accessToken);
  return CUSTOMER_ID_CLAIM in parsedAccessToken && CUSTOMER_SIGNATURE_CLAIM in parsedAccessToken;
}

export function parseAccessToken(token: string): AccessToken {
  const parsed = jwtDecode<AccessToken & { iat: number; exp: number }>(token);
  const result: AccessToken = {
    ...parsed,
    exp: new Date(parsed.exp * 1000), // OAuth uses time in seconds since 1970 rather than milliseconds
    iat: new Date(parsed.iat * 1000),
  };
  return result;
}

// https://auth0.com/docs/scopes/openid-connect-scopes#standard-claims
export interface IdToken {
  email: string;
  sub: string;
  given_name: string | undefined;
}

export function parseIdToken(token: string): IdToken {
  const parsed = jwtDecode<IdToken>(token);
  return parsed;
}
