import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import getJumioSvcClient from 'src/lib/jumioService';
import { AxiosError } from 'axios';
import { debuggingConfig } from 'src/config';
import { InitiateRequest, InitiateResponse } from '../resources/api-client/jumio';

export interface JumioVerificationResult {
  status: 'SUCCESS' | 'ERROR';
  id: string;
  txnReference: string;
  errorCode?: string;
}

export interface JumioState {
  status: 'loading' | 'ready' | 'error';
  jumioObject?: InitiateResponse | null;
  verificationResult?: JumioVerificationResult | null;
  jumioStep: 'selection' | 'verify' | 'processed';
}

export const initialState: JumioState = {
  status: 'loading',
  jumioObject: null,
  verificationResult: null,
  jumioStep: 'selection',
};

const slice = createSlice({
  name: 'jumio',
  initialState,
  reducers: {
    setState(state: JumioState, action: PayloadAction<Partial<JumioState>>) {
      if (debuggingConfig.mode !== 'storybook') {
        console.error(
          'This reducer should not be used inside the application, this is only to facilitate storybook',
        );
        return;
      }
      Object.assign(state, action.payload);
    },
    resetState(state: JumioState) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
    setStatus(state: JumioState, action: PayloadAction<{ status: 'loading' | 'ready' | 'error' }>) {
      const { status } = action.payload;
      state.status = status;
    },
    initiateJumio(
      state: JumioState,
      action: PayloadAction<{
        status: 'loading' | 'ready' | 'error';
        jumioObject: InitiateResponse;
      }>,
    ) {
      const { status, jumioObject } = action.payload;
      state.status = status;
      state.jumioObject = jumioObject;
      state.jumioStep = 'verify';
    },
    setJumioStep(
      state: JumioState,
      action: PayloadAction<{ step: 'selection' | 'verify' | 'processed' }>,
    ) {
      const { step } = action.payload;
      state.jumioStep = step;
    },
    setVerificationResult(
      state: JumioState,
      action: PayloadAction<{ verificationResult: JumioVerificationResult }>,
    ) {
      const { verificationResult } = action.payload;
      state.verificationResult = verificationResult;
      state.jumioStep = 'processed';
    },
  },
});

export const initiateJumio =
  (initiateRequest: InitiateRequest): AppThunk =>
  async (dispatch) => {
    console.log('initiating jumio verification...');
    const jumioClient = await getJumioSvcClient();
    jumioClient.client
      .postInitiate('', initiateRequest)
      .then((res) => {
        const jumioObject = res.data as InitiateResponse;
        console.log(jumioObject);
        dispatch(slice.actions.initiateJumio({ status: 'ready', jumioObject }));
      })
      .catch(async (error: AxiosError) => {
        console.log(error);
        dispatch(slice.actions.setStatus({ status: 'error' }));
      });
  };

export const setVerificationResult =
  (verificationResult: JumioVerificationResult): AppThunk =>
  async (dispatch) => {
    console.log('setting jumio verification result...');
    dispatch(slice.actions.setVerificationResult({ verificationResult }));
  };

export const { reducer } = slice;
export default slice;
