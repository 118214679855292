import React, { useState } from 'react';
import clsx from 'clsx';
import IconButton from '../IconButton/IconButton';

import styles from './Password.module.scss';

interface PasswordProps {
  error?: string;
  className?: string;
  disabled?: boolean;
  htmlFor?: string;
  label?: string;
  name: string;
  handleChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  handleFocus?: () => void;
  handleBlur?: () => void;
  datatestid?: string;
}

const Password: React.FC<PasswordProps> = (props: PasswordProps) => {
  const {
    error = '',
    className,
    disabled = false,
    htmlFor,
    label,
    name,
    handleChange = () => false,
    handleFocus = () => false,
    handleBlur = () => false,
    placeholder = '',
    value,
    datatestid,
  } = props;
  const errorTestId = `${datatestid}Error`;
  const [showInput, setShowInput] = useState(false);

  const onPasswordIconClick = (): void => {
    setShowInput(!showInput);
  };

  return (
    <>
      {label && (
        <label
          className={clsx(styles.label, {
            [styles.disabled]: disabled,
          })}
          htmlFor={htmlFor}>
          {label}
        </label>
      )}
      <div className={clsx(styles.Wrapper, className)}>
        <input
          className={clsx(styles.input, {
            [styles.hasError]: error.length,
          })}
          type={showInput ? 'text' : 'password'}
          disabled={disabled}
          name={name}
          onChange={handleChange}
          placeholder={placeholder}
          value={value}
          onBlur={handleBlur}
          onFocus={handleFocus}
          data-testid={datatestid}
        />
        <IconButton
          icon={showInput ? 'eyeOn' : 'eyeOff'}
          className={clsx(styles.iconBtn, className)}
          onClick={onPasswordIconClick}
          variant='primary'
        />
      </div>
      {error && (
        <div className={styles.error} data-testid={errorTestId}>
          {error}
        </div>
      )}
    </>
  );
};

export default Password;
