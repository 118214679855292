import React from 'react';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { rankWith, uiTypeIs, RendererProps, GroupLayout } from '@jsonforms/core';

const VerticalLayoutRenderer = (props: RendererProps): JSX.Element => {
  const { uischema, schema, path, renderers, cells, enabled } = props;
  const uiSchemaForm = uischema as GroupLayout;
  return (
    <div className='flex flex-direction-column'>
      {uiSchemaForm.elements.map(
        (child: any): JSX.Element => (
          <JsonFormsDispatch
            schema={schema}
            uischema={child}
            path={path}
            enabled={enabled}
            renderers={renderers}
            cells={cells}
            key={child.scope || child.value}
          />
        ),
      )}
    </div>
  );
};

export const VerticalLayoutTester = rankWith(100, uiTypeIs('VerticalLayout'));
export default withJsonFormsLayoutProps(VerticalLayoutRenderer);
