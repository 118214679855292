import { combineReducers } from '@reduxjs/toolkit';
import { reducer as jumioReducer } from 'src/slices/jumio';
import { reducer as onboardingReducer } from 'src/slices/onboarding';
import { reducer as authReducer } from 'src/slices/auth';
// These reducers might need to be deconstructed in the future
// when the components that are using these will be packaged
// and published individually for another project
const rootReducer = combineReducers({
  jumio: jumioReducer,
  onboarding: onboardingReducer,
  auth: authReducer,
});

export default rootReducer;
