import React from 'react';
import Icon from '../Icon/Icon';
import inputStyles from '../Input/Input.module.scss';
import styles from './ErrorMessage.module.scss';

interface ErrorMessageProps {
  message: string;
  icon?: IconName;
}
const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, icon }) => (
  <div className={styles.errorMessage}>
    {icon && (
      <div className='mt-6'>
        <Icon name={icon} title={icon} />
      </div>
    )}
    <div className={inputStyles.error}>{message}</div>
  </div>
);

export default ErrorMessage;
