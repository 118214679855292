import { UISchema } from 'src/slices/onboarding';

export default function filterSummarySchema(summarySchema: UISchema['schema']): UISchema['schema'] {
  return filter(summarySchema);
}

function filter(schema: any): UISchema['schema'] {
  if (schema.type !== 'SummaryControlField') {
    if (!(schema.elements instanceof Array)) {
      return schema;
    }

    const elements = schema.elements
      .map((element: UISchema['schema']) => filter(element))
      .filter((element: UISchema['schema']) => element !== null);

    return {
      ...schema,
      elements,
    };
  }

  return scopesToFilter.includes(schema.scope) ? null : schema;
}

const scopesToFilter = ['#/properties/termsAndConditions/properties/customerTermsAndConditions'];
