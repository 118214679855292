import auth0 from 'auth0-js';
import useAnalytics from 'src/analytics/useAnalytics';
import { auth0Config, auth0LogoutURL, auth0RedirectUri } from 'src/config';
import { jumioSvcSetAccessToken } from 'src/lib/jumioService';
import { setAccessToken } from 'src/lib/onboardingService';

interface Credential {
  email: string;
  password: string;
}

export interface AuthResponse {
  success: boolean;
  error?: Error | null | any;
  result?: any;
}
// eslint-disable-next-line react-hooks/rules-of-hooks
const trackEvent = useAnalytics();

// INITIALIZE AUTH0
export const webAuth = new auth0.WebAuth(auth0Config);

export const signUp = async (
  credential: Credential,
  response: (AuthResponse: AuthResponse) => void,
): Promise<void> => {
  const { email, password } = credential;
  webAuth.signup(
    {
      email,
      password,
      connection: auth0Config.connection,
    },
    async (err: any, result: any) => {
      if (err && !result) {
        response({
          success: false,
          error: err as Error,
        });
        trackEvent('Affiliate Auth0 Signup Failed', {
          details: err,
        });
      } else {
        trackEvent('Affiliate Auth0 Signup Started', undefined);
        userLogin({ email, password }, (AuthResponse: AuthResponse) => {
          if (AuthResponse.success) {
            response({
              success: true,
              error: null,
            });
          }
        });
      }
    },
  );
};

// LOGIN
export const userLogin = (
  credentials: Credential,
  response: (AuthResponse: AuthResponse) => void,
): void => {
  const { email, password } = credentials;
  trackEvent('Affiliate Auth0 Login Initiated', undefined);
  webAuth.login(
    {
      realm: auth0Config.connection,
      email,
      password,
      redirectUri: auth0RedirectUri,
    },
    async (err: any, result: any) => {
      if (err && !result) {
        response({
          success: false,
          error: err as Error,
        });
        trackEvent('Affiliate Auth0 Login Failed', {
          details: err,
        });
      } else {
        response({
          success: true,
          error: null,
          result,
        });
      }
    },
  );
  localStorage.setItem('JumioVerification', null);
  localStorage.setItem('JumioVerificationId', '');
  localStorage.setItem('DocumentUpload', null);
  localStorage.setItem('TrackLogin', 'true');
};

// Check session
export const loadSession = (response: (AuthResponse: AuthResponse) => void): void => {
  webAuth.checkSession(
    {
      responseType: 'id_token token code',
      responseMode: 'web_message',
      redirectUri: auth0RedirectUri,
    },
    async (err: any, result: any) => {
      if (err && !result) {
        setAccessToken(null);
        jumioSvcSetAccessToken(null);
        response({
          success: false,
          error: err as Error,
          result,
        });
      } else {
        setAccessToken(result.accessToken);
        jumioSvcSetAccessToken(result.accessToken);
        // const customerId = tryGetCustomerIdFromToken(result.accessToken);
        // window.analytics.identify(customerId, {});
        response({
          success: true,
          error: null,
          result,
        });
      }
    },
  );
};

// Logout
export const userLogout = () => {
  trackEvent('Affiliate Auth0 Logout Initiated', undefined);
  window.analytics.reset();
  webAuth.logout({
    returnTo: auth0LogoutURL,
  });
};

// reset password
export const resetPassword = async ({
  email,
  response,
}: {
  email: string;
  response: (AuthResponse: AuthResponse) => void;
}): Promise<void> => {
  trackEvent('Affiliate Auth0 Forgot Password Initiated', undefined);
  webAuth.changePassword(
    {
      connection: auth0Config.connection,
      email,
    },
    async (err: any, result: any) => {
      if (err && !result) {
        response({
          success: false,
          error: err as Error,
        });
        trackEvent('Affiliate Auth0 Forgot Password Failed', {
          details: err,
        });
      } else {
        response({
          success: true,
          error: null,
        });
        trackEvent('Affiliate Auth0 Forgot Password Completed', undefined);
      }
    },
  );
};
