import {
  and,
  hasType,
  JsonSchema,
  rankWith,
  schemaMatches,
  UISchemaElement,
  uiTypeIs,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Select } from 'src/components/kit';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { ControlElementWithPlaceholder } from '../Control';

export type DropdownElement = ControlElementWithPlaceholder & {
  displayValues: Record<string, string>;
  readonly options?: {
    disableSearch?: boolean;
  };
};

function mapValuesToDropdown(displayValues: Record<string, string>): any {
  return Object.entries(displayValues).map((values: any) => ({
    value: values[0],
    label: values[1],
  }));
}

interface MultiSelectProps {
  uischema: UISchemaElement | any;
  handleChange: (path: string, value: string[]) => void;
  path: string;
  errors: any;
  data: string[];
  schema: JsonSchema | any;
}

// prettier-ignore
function getMultiSelectValues(mappedData: any, selectedValues: string[]) {
  const values = selectedValues.map((selectedVal: string) => mappedData.find((current: any) => current.value === selectedVal));
  return values;
}

// eslint-disable-next-line no-multi-assign
const MultiSelectRenderer = (props: MultiSelectProps): JSX.Element => {
  const { handleChange, uischema, data, path, errors, schema } = props;
  const label = uischema.label?.toString().replace('[bold]', '');
  const boldLabel = !!uischema.label.toString().includes('[bold]');
  const placeholder = uischema.placeholder as string;
  const [validationError, setValidationError] = useState<string>(errors);
  const formTouched = useSelector((state: any) => state.auth.formTouched);
  const [, setSelectedValue] = useState(data || []);
  const intl = useIntl();

  const mappedData = useMemo(
    () => mapValuesToDropdown((uischema as DropdownElement)?.displayValues || {}),
    [uischema],
  );

  useEffect(() => {
    setValidationErrorMessage();
  }, [data]);

  useEffect(() => {
    setValidationErrorMessage();
  }, [formTouched]);

  const setValidationErrorMessage = () => {
    if (!data) {
      if (!errors) {
        setValidationError('');
      } else if (errors) {
        setValidationError(
          intl.formatMessage({
            id: 'kyc.validation.fieldIsRequired',
            defaultMessage: 'This field is required.',
          }),
        );
      }
    } else if (data && errors) {
      if (schema.message) {
        setValidationError(`${schema.message}`);
      } else {
        setValidationError(errors);
      }
    } else if (!errors) {
      setValidationError('');
    }
  };

  return (
    <div className='wide-control-container'>
      <Select
        searchable
        placeholder={placeholder}
        value={data && getMultiSelectValues(mappedData, data)}
        handleChange={(e: any) => {
          setSelectedValue(e.map((x: any) => x.value));
          handleChange(
            path,
            e.map((x: any) => x.value),
          );
        }}
        name={uischema.scope}
        label={`${label}`}
        options={mappedData}
        error={data && `${validationError}`}
        isMulti
        variant={boldLabel ? 'boldLabel' : 'default'}
        width='350px'
      />
    </div>
  );
};

// prettier-ignore
export const MultiSelectTester = rankWith(
  106,
  and(
    uiTypeIs('Control'),
    and(
      schemaMatches(
        (schema) => hasType(schema, 'array') && !Array.isArray(schema.items) && schema.uniqueItems === true
      )
    )
  )
);
export default withJsonFormsControlProps(MultiSelectRenderer);
