import React from 'react';
import { and, Layout, rankWith, RendererProps } from '@jsonforms/core';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { formUITypeIs, RenderChildren } from '../util';

const SummaryPhoneLayoutRenderer = (props: RendererProps): JSX.Element => {
  const { uischema, schema, path } = props;
  const layout = uischema as Layout;

  return (
    <div className='summary-phone-container'>
      <RenderChildren schema={schema} layout={layout} path={path} />
    </div>
  );
};

export const SummaryPhoneLayoutTester = rankWith(100, and(formUITypeIs('SummaryPhoneLayout')));
export default withJsonFormsLayoutProps(SummaryPhoneLayoutRenderer);
