import React from 'react';
import Sidebar from 'src/layouts/Sidebar/Sidebar.component';

interface ForgotPasswordLayoutProps {
  children: React.ReactNode;
}

const ForgotPasswordLayout: React.FC<ForgotPasswordLayoutProps> = ({
  children,
}: ForgotPasswordLayoutProps) => (
  <div className='forget-main-layout'>
    <Sidebar />
    <div className='forget-layout-content'>
      <div className='forget-layout-body'>{children}</div>
    </div>
  </div>
);

export default ForgotPasswordLayout;
