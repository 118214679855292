import React, { useState } from 'react';
import { and, GroupLayout, rankWith, RendererProps } from '@jsonforms/core';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { BlockStatus } from 'src/utils/formValidators';
import {
  StepCompletedIcon,
  StepFailedIcon,
  StepInProgressIcon,
  StepNotStartedIcon,
  StepPendingIcon,
} from 'src/components/ProgressIcons';
import colors from 'src/styles/variables.scss';
import { Typography, Icon } from 'src/components/kit';
import { formUITypeIs, RenderChildren } from '../util';

type BlockProps = GroupLayout & { blockProperty: string };

const SummaryBlockRenderer = (props: RendererProps): JSX.Element => {
  const { uischema, schema, path, data } = props;
  const subForm = uischema as BlockProps;
  const details = subForm.blockProperty;
  const blockStatus = data[details].blockStatus as BlockStatus;
  const [expanded, setExpanded] = useState(true);
  const StatusIcon = () => {
    if (blockStatus === 'Completed') {
      return <StepCompletedIcon />;
    }
    if (blockStatus === 'Ongoing') {
      if (details === 'idVerification') {
        return <StepPendingIcon />;
      }
      return <StepInProgressIcon color={colors.black} />;
    }
    if (blockStatus === 'RequiresRemediation') {
      return <StepFailedIcon />;
    }
    return <StepNotStartedIcon />;
  };
  return (
    <div className='summary-block-container'>
      <div className='summary-header-container'>
        <div className='row-container'>
          <StatusIcon />
          <Typography className='summary-header-label' variant='h3'>
            {subForm.label}
          </Typography>
          <div
            className='expand-button-container'
            onClick={() => {
              setExpanded(!expanded);
            }}>
            {expanded ? <Icon name='arrowUp' size={14} /> : <Icon name='arrowDown' size={14} />}
          </div>
        </div>
      </div>

      <div className={`${!expanded && 'mobile-display-none'}`}>
        <RenderChildren schema={schema} layout={subForm} path={path} />
      </div>
    </div>
  );
};

export const SummaryBlockTester = rankWith(100, and(formUITypeIs('SummaryBlock')));
export default withJsonFormsLayoutProps(SummaryBlockRenderer);
