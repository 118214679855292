import clsx from 'clsx';
import React from 'react';
import Spinner from 'src/components/Spinner/Spinner.component';
import styles from './Button.module.scss';

interface ButtonProps {
  /**
   * Used for the root node.
   * Either a string to use a DOM element or a component.
   * E.g. `button`, `a`, `Link`.
   */
  component?: any;
  /**
   * Is button disabled
   */
  disabled?: boolean;
  /**
   * Link URL. Used when Button component implement link logic
   */
  href?: string;
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  /**
   * Specifies the relationship between the current document and the linked document.
   * Used when Button component implement link logic
   */
  rel?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium';
  /**
   * Specifies where to open the linked document.
   * Used when Button component implement link logic
   */
  target?: string;
  /**
   * Button style variant
   */
  variant?: 'primary' | 'secondary' | 'tertiary';
  buttonType?: 'button' | 'submit';
  datatestid?: string;
  loading?: boolean;
}

/**
 * Primary UI component for user interaction
 */
const Button: React.FC<ButtonProps> = ({
  component: Component = 'button',
  disabled = false,
  label,
  onClick,
  size = 'medium',
  variant = 'primary',
  buttonType = 'button',
  datatestid,
  loading = false,
  ...otherProps
}: ButtonProps) => (
  <Component
    className={clsx(styles.button, {
      [styles.medium]: size === 'medium',
      [styles.small]: size === 'small',
      [styles.primary]: variant === 'primary',
      [styles.secondary]: variant === 'secondary',
      [styles.tertiary]: variant === 'tertiary',
    })}
    disabled={loading ? true : disabled}
    onClick={onClick}
    type={buttonType}
    data-testid={datatestid}
    {...otherProps}>
    {loading && (
      <div className={styles.loading}>
        <Spinner size='small' />
      </div>
    )}
    <span className={styles.label}>{label}</span>
  </Component>
);

export default Button;
