/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback } from 'react';
import { and, GroupLayout, rankWith, RendererProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Checkbox } from 'src/components/kit';
import { formUITypeIs } from '../util';

const SummaryControlCheckbox = (props: RendererProps): JSX.Element => {
  const { uischema, data, visible } = props;
  const layout = uischema as GroupLayout;
  const { label } = layout;
  const getIsChecked = useCallback(() => {
    if (!data || (Array.isArray(data) && data.length === 0) || data?.trim === '') {
      return false;
    }
    return true;
  }, [data]);

  if (!visible) {
    return null;
  }

  return (
    <div className='summary-control-container'>
      <div className='row'>
        <Checkbox label={label} checked={getIsChecked()} disabled />
      </div>
    </div>
  );
};

export const SummaryControlCheckboxTester = rankWith(
  1,
  and(formUITypeIs('SummaryControlCheckbox')),
);
export default withJsonFormsControlProps(SummaryControlCheckbox);
