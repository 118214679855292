import React, { useState, useEffect } from 'react';
import { JsonForms } from '@jsonforms/react';
import CustomRenderers from 'src/components/FormEngine/index';
import { materialRenderers } from '@jsonforms/material-renderers';
import { useSelector } from 'react-redux';
import sampleSchema from './sampleSchema.json';

interface JsonFormProps {
  /**
   * Schema to be rendered (schema must have "dataSchema" and "uiSchema")
   */
  schema?: Schema;

  /**
   * -
   */

  handleChange?: (data: any, errors: any) => void;
  /**
   * Form name
   */
  name: string;
}

interface Schema {
  dataSchema: any;
  uiSchema: any;
}

const renderers = [...materialRenderers, ...CustomRenderers];
const JsonForm: React.FC<JsonFormProps> = ({
  schema = sampleSchema,
  name,
  handleChange = () => {},
}): JSX.Element => {
  const { getLeadStatus, lead, currentStep } = useSelector((state: any) => state.onboarding);
  const [initialData, setInitialData] = useState<any>({});

  useEffect(() => {
    if (lead && getLeadStatus === 'ready') {
      if (lead.kycSections[name]) {
        setInitialData(lead.kycSections[name]);
      } else {
        setInitialData({});
      }
    } else {
      setInitialData({});
    }
  }, [name, lead, getLeadStatus, currentStep]);

  return (
    <div data-testid='JsonForm'>
      <JsonForms
        schema={schema.dataSchema}
        uischema={schema.uiSchema}
        data={initialData}
        renderers={renderers}
        onChange={({ errors, data }) => {
          handleChange(data, errors);
        }}
        validationMode='ValidateAndShow'
      />
    </div>
  );
};

export default JsonForm;
