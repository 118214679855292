import {
  getDocumentUploadSchemaByApplicationType,
  isNoDocumentRequired,
} from 'src/pages/kyc/DocumentUploadPage';
import {
  AffiliateLead,
  AffiliateLeadIdVerification,
  IdVerificationStatus,
} from 'src/resources/api-client/onboarding';
import isDocumentUploadDone from './isDocumentUploadDone';
import { getSchemaByName } from './getSchemaByName';

export const getSideMenuItemStatus = (
  lead: AffiliateLead,
  idVerification: AffiliateLeadIdVerification,
  sideMenuItemKey: string,
  currentStep: string,
  fileList?: any,
  schemas?: any,
): string => {
  if (!lead.kycSections) return 'NotStarted';

  if (
    (lead.application?.status === 'Complete' ||
      lead.application?.status === 'Pending' ||
      lead.application?.status === 'Rejected' ||
      lead.application?.status === 'RequiresRemediation') &&
    sideMenuItemKey === 'affiliate-summary'
  ) {
    return 'Completed';
  }

  if (
    sideMenuItemKey === 'affiliate-personalDetails' &&
    !lead.kycSections[sideMenuItemKey].salutation
  ) {
    return 'Ongoing';
  }

  if (currentStep === sideMenuItemKey && sideMenuItemKey !== 'affiliate-idVerification') {
    return 'Ongoing';
  }

  if (sideMenuItemKey === 'affiliate-documentUpload') {
    return documentUploadStatus(fileList, lead, schemas);
  }

  const isExisting = Object.keys(lead.kycSections).find((key: string) => key === sideMenuItemKey);
  if (isExisting) {
    return 'Completed';
  }

  if (sideMenuItemKey === 'affiliate-idVerification') {
    if (currentStep === sideMenuItemKey) {
      return 'Ongoing';
    }
    const idStatus = idVerificationStatus(idVerification, currentStep);
    return idStatus;
  }

  return 'NotStarted';
};

const idVerificationStatus = (
  idVerification: AffiliateLeadIdVerification,
  currentStep: string,
): string => {
  if (!idVerification) {
    if (currentStep === 'affiliate-personalDetails') {
      return 'NotStarted';
    }
    const jumioStepDone = localStorage.getItem('JumioVerification') === 'done';
    if (jumioStepDone) return 'Pending';
    return 'Skipped';
  }

  const { status } = idVerification;
  if (status === IdVerificationStatus.Complete) {
    return 'Completed';
  }
  return status;
};

const documentUploadStatus = (fileList: any, lead: any, schemas: any): string => {
  const applicationType = lead?.kycSections?.['affiliate-documentUpload']?.applicationType;
  if (applicationType) {
    const documentUploadSchema = getDocumentUploadSchemaByApplicationType(
      getSchemaByName(schemas, 'affiliate-documentUpload'),
      applicationType,
    );
    if (isNoDocumentRequired(documentUploadSchema)) {
      return 'Completed';
    }
  }

  if (fileList && fileList?.length >= 1 && isDocumentUploadDone(fileList)) {
    return 'Completed';
  }

  const documentUploadDone = localStorage.getItem('DocumentUpload') === 'done';
  if (fileList && fileList?.length < 1 && documentUploadDone) {
    return 'Completed';
  }

  const documentUploadSkipped = localStorage.getItem('DocumentUpload') === 'skipped';
  if (documentUploadSkipped && fileList?.length < 1 && !isDocumentUploadDone(fileList)) {
    return 'Skipped';
  }

  return 'NotStarted';
};
