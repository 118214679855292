import React from 'react';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { rankWith, uiTypeIs, RendererProps, GroupLayout } from '@jsonforms/core';
import { Typography } from 'src/components/kit';

export type SubFormLayout = GroupLayout & { id: string };

const FormRenderer = (props: RendererProps): JSX.Element => {
  const { uischema, schema, path, renderers, cells, enabled } = props;

  const uiSchemaForm = uischema as GroupLayout;

  if (uiSchemaForm.elements.length === 0) {
    return null;
  }

  return (
    <div>
      <div className='mb-24 json-form-header-text'>
        <Typography variant='h2'>{uiSchemaForm?.label}</Typography>
      </div>
      {uiSchemaForm.elements.map((child: any) => {
        const subForm = child as SubFormLayout;
        return (
          <div key={subForm.id}>
            <JsonFormsDispatch
              schema={schema}
              uischema={child}
              path={path}
              enabled={enabled}
              renderers={renderers}
              cells={cells}
            />
          </div>
        );
      })}
    </div>
  );
};

export const FormTester = rankWith(5, uiTypeIs('Form'));
export default withJsonFormsLayoutProps(FormRenderer);
