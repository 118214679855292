import React from 'react';
import clsx from 'clsx';

import Icon from '../Icon/Icon';

import styles from './Checkbox.module.scss';

interface CheckboxProps {
  /**
   * Scss class that apply to span that represent checkbox input
   */
  checkboxClass?: string;
  /**
   * Scss class that apply to wrapper
   */
  className?: string;
  /**
   * Apply inactive visual appearance to the checkbox
   */
  disabled?: boolean;
  /**
   * Indicates whether the checkbox error state
   */
  error?: string;
  /**
   * Unique identifier that apply to input directly
   */
  id?: string;
  /**
   * Checkbox main text
   */
  label?: React.ReactNode;
  /**
   * Scss class that apply to label
   */
  labelClass?: string;
  /**
   * Change handler that apply to input directly
   */
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  /**
   * Click handler that apply to span that represent checkbox input
   */
  onClick?: (event: React.MouseEvent<HTMLElement>, checked: boolean) => void;
  /**
   * Keyboard handler that apply to span that represent checkbox input
   */
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>, checked: boolean) => void;
  /**
   * Indicating whether or not this checkbox is checked
   */
  checked?: boolean;
  /**
   * A unique value. Used during form submission and to identify which checkbox inputs are selected
   */
  value?: string;

  name?: string;

  datatestid?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checkboxClass,
  className,
  disabled = false,
  error,
  id,
  label,
  labelClass,
  onChange = () => false,
  onClick = () => false,
  onKeyDown = () => false,
  checked = false,
  value = '',
  name,
  datatestid,
  ...otherProps
}: CheckboxProps) => {
  const handleClick = (event: React.MouseEvent<HTMLElement>): void =>
    !disabled && onClick(event, checked);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>): void =>
    !disabled && onKeyDown(event, checked);

  return (
    <div className={clsx(styles.Wrapper, className)}>
      <input
        checked={checked}
        className={styles.Input}
        disabled={disabled}
        id={id}
        onChange={onChange}
        type='checkbox'
        value={value}
        name={name}
        {...otherProps}
      />
      <span
        aria-checked={checked}
        className={clsx(
          styles.Checkbox,
          {
            [styles.CheckedCheckbox]: checked,
            [styles.DisabledCheckbox]: disabled,
            [styles.WithError]: error,
          },
          checkboxClass,
        )}
        data-testid={datatestid}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        role='checkbox'
        tabIndex={disabled ? -1 : 0}>
        <span className={styles.IconCheckedWrapper}>
          <Icon color={error ? '#ffffff' : '#282424'} name='check' />
        </span>
      </span>
      {label && (
        <label className={clsx(styles.Label, labelClass)} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
