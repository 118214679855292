import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as AxiLogo } from 'src/assets/logo/axi_logo_white.svg';
import clsx from 'clsx';
import { userLogout } from 'src/auth';
import { getSideMenuItemStatus } from 'src/utils/getSideMenuItemStatus';
import { AffiliateLeadIdVerification } from 'src/resources/api-client/onboarding';
import { Typography, Icon } from 'src/components/kit';
import Dropdown from 'src/components/Dropdown';
import colors from 'src/styles/variables.scss';
import {
  AffiliateLeadWithFileList,
  getCountries,
  setAcceptLanguage,
  setLanguage,
} from 'src/slices/onboarding';
import languages from 'src/utils/languages';
import { useIntl, createIntl, createIntlCache } from 'react-intl';
import en from 'src/localization/en.json';
import SideMenu from '../SideMenu/SideMenu.component';
import { getData, getSchema, getSummarySchema } from '../../slices/onboarding';
import styles from './Sidebar.module.scss';

export interface SideMenuItem {
  key: string;
  label: string;
  status: string;
  step: number;
  visible?: boolean;
  locale?: any;
  meesages?: any;
}

const getSideMenuItems = (
  lead: AffiliateLeadWithFileList,
  idVerification: AffiliateLeadIdVerification,
  currentStep: string,
  schemas: any,
  locale: any,
  messages: any,
): SideMenuItem[] => {
  const cache = createIntlCache();

  const intl = createIntl(
    {
      locale: locale ?? 'en',
      messages: messages ?? en,
    },
    cache,
  );

  const SideMenuItems: SideMenuItem[] = [
    {
      key: 'personalDetails',
      label: intl.formatMessage({
        id: 'kyc.sidebar.personalDetailsLabel',
        defaultMessage: 'Personal',
      }),
      status: getSideMenuItemStatus(lead, idVerification, 'affiliate-personalDetails', currentStep),
      step: 1,
      visible: true,
    },
    {
      key: 'idVerification',
      label: intl.formatMessage({
        id: 'kyc.sidebar.idVerificationLabel',
        defaultMessage: 'ID Verification',
      }),
      status: getSideMenuItemStatus(lead, idVerification, 'affiliate-idVerification', currentStep),
      step: 2,
      visible: true,
    },
    {
      key: 'finances',
      label: intl.formatMessage({
        id: 'kyc.sidebar.financeDetailsLabel',
        defaultMessage: 'Billing Information',
      }),
      status: getSideMenuItemStatus(lead, idVerification, 'affiliate-finances', currentStep),
      step: 3,
      visible: true,
    },
    {
      key: 'experience',
      label: intl.formatMessage({
        id: 'kyc.sidebar.experienceLabel',
        defaultMessage: 'Experience',
      }),
      status: getSideMenuItemStatus(lead, idVerification, 'affiliate-experience', currentStep),
      step: 4,
      visible: true,
    },
    {
      key: 'documentUpload',
      label: intl.formatMessage({
        id: 'kyc.sidebar.documentUploadsLabel',
        defaultMessage: 'Upload Documents',
      }),
      status: getSideMenuItemStatus(
        lead,
        idVerification,
        'affiliate-documentUpload',
        currentStep,
        lead?.fileList,
        schemas,
      ),
      step: 5,
      visible: true,
    },
    {
      key: 'declaration',
      label: intl.formatMessage({
        id: 'kyc.sidebar.declarationLabel',
        defaultMessage: 'Declaration',
      }),
      status: getSideMenuItemStatus(lead, idVerification, 'affiliate-declaration', currentStep),
      step: 6,
      visible: true,
    },
    {
      key: 'summary',
      label: intl.formatMessage({
        id: 'kyc.sidebar.summaryLabel',
        defaultMessage: 'Summary',
      }),
      status: getSideMenuItemStatus(lead, idVerification, 'affiliate-summary', currentStep),
      step: 7,
      visible: true,
    },
  ];

  return SideMenuItems;
};

const getPageTitle = (schemas: any, currentStep: string): string => {
  if (!schemas) return 'Page Title';
  const pageTitle = schemas?.uiSchemas?.filter(
    (uiSchema: any) => uiSchema?.schemaName === currentStep,
  )[0]?.schema?.label;

  if (pageTitle === 'Personal Details') {
    return 'Sign Up';
  }

  if (currentStep === 'done' || currentStep === 'onReview') {
    return 'Completion';
  }
  return pageTitle;
};

const PublicSidebar: React.FC = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, currentStep, status } = useSelector((state: any) => state.auth);
  const { lead, getLeadStatus, idVerification, language, getSchemaStatus, schemas } = useSelector(
    (state: any) => state.onboarding,
  );
  const intl = useIntl();

  const [isTopBarOpen, setTopBarOpen] = useState<boolean>(false);

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.topBarOpen]: isTopBarOpen === true,
        [styles.isLoggedIn]: isAuthenticated === true,
        [styles.isNotLoggedIn]: isAuthenticated === false,
      })}
      data-testid='Sidebar'>
      <div className='flex justify-content-between align-items-center logo-container'>
        <AxiLogo className={styles.logo} height={40} />
        {isAuthenticated && (
          <>
            {isTopBarOpen ? (
              <div className={styles.menuBtn} onClick={() => setTopBarOpen(!isTopBarOpen)}>
                <Icon name='close' color={colors.white} size={32} />
              </div>
            ) : (
              <div className={styles.menuBtn} onClick={() => setTopBarOpen(!isTopBarOpen)}>
                <Icon name='menu' color={colors.white} size={32} />
              </div>
            )}
          </>
        )}
      </div>
      {/* prettier-ignore */}
      {!isTopBarOpen && status === 'ready' && isAuthenticated && getSchemaStatus === 'ready' && (
        <div className={styles.mobilePageTitle}>
          <Typography variant='h2' color={colors.white}>
            {schemas && getPageTitle(schemas, currentStep)}
          </Typography>
        </div>
      )}
      <div className={styles.menu}>
        {status === 'ready' && isAuthenticated && getSchemaStatus === 'ready' && (
          <>
            {getLeadStatus === 'ready' && (
              <SideMenu
                items={getSideMenuItems(
                  lead,
                  idVerification,
                  currentStep,
                  schemas,
                  language?.key,
                  language?.file,
                )}
              />
            )}
          </>
        )}
      </div>
      <div className={styles.optionsContainer}>
        <div
          className={clsx(styles.languageDropDownContainer, {
            [styles.logoutContainer]: isAuthenticated === true,
            [styles.languageDropDownContainerNotLoggedIn]: isAuthenticated === false,
          })}>
          <Dropdown
            items={languages.map((item: any) => ({
              label: item.label,
              onClick: () => {
                dispatch(setLanguage(item));
                dispatch(setAcceptLanguage(item.locale));
                if (window.location.href.includes('/kyc')) {
                  dispatch(getSchema(item.locale));
                  dispatch(getData(item.locale));
                  dispatch(getSummarySchema(item.locale));
                } else if (window.location.href.includes('/signup')) {
                  dispatch(getCountries(item.locale));
                }
              },
            }))}
            // prettier-ignore
            content={(
              <div className="flex align-items-center">
                <Icon size={24} name="globe" color={colors.white} />
                <div className="ml-10 mr-10">
                  <Typography variant="h3" color={colors.white}>
                    {language && language.label}
                  </Typography>
                </div>
                <div className="mt-8">
                  <Icon size={20} color={colors.white} name="movementDown" />
                </div>
              </div>
            )}
          />
        </div>

        {isAuthenticated && (
          <div className={styles.logoutContainer}>
            <div
              className={styles.logout}
              onClick={() => {
                userLogout();
              }}>
              <Icon name='logout' color={colors.white} size={24} />
              <Typography variant='h3'>
                {intl.formatMessage({
                  id: 'kyc.sidebar.logoutText',
                  defaultMessage: 'Logout',
                })}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PublicSidebar;
