import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import './dropdown.styles.scss';

interface DropdownProps {
  items: DropdownItem[];
  content: any;
}
interface DropdownItem {
  label: string;
  onClick: () => void;
}

const Dropdown: React.FC<DropdownProps> = ({ items, content }) => {
  const [visible, setVisible] = useState(false);
  return (
    <div
      className='dropdown'
      onClick={() => {
        setVisible(!visible);
      }}>
      {content}
      {visible && (
        <div className='dropdown-content'>
          {items.map((item: DropdownItem) => (
            <div key={item.label} onClick={item.onClick} className='dropdown-item'>
              <Typography variant='body2'>{item?.label}</Typography>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
