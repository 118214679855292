import { JsonFormsRendererRegistryEntry } from '@jsonforms/core';
import SubFormRenderer, { SubFormTester } from './SubForm';
import FormRenderer, { FormTester } from './Form';
import TextRenderer, { TextTester } from './Text';
import PhoneControlRenderer, { PhoneControlTester } from './Phone';
import ControlRenderer, { ControlTester } from './Control';
import SummaryBlockRenderer, { SummaryBlockTester } from './SummaryBlock';
import SummaryControlFieldRenderer, { SummaryControlFieldTester } from './SummaryControlField';
import SummaryError, { SummaryControlErrorTester } from './SummaryError';
import SummarySubSectionRenderer, { SummarySubSectionTester } from './SummarySubSection';
import SummaryControlCheckboxRenderer, {
  SummaryControlCheckboxTester,
} from './SummaryControlCheckbox';
import JumioRenderer, { JumioTester } from './Jumio';
import RadioControlRenderer, { RadioControlTester } from './RadioControl';
import MultiSelectRenderer, { MultiSelectTester } from './MultiSelect';
import CheckboxDatetimeRenderer, { CheckboxDatetimeTester } from './CheckboxDateTime';
import UploadDocumentRenderer, { UploadDocumentTester } from './DocumentUpload';
import DocumentUploadTextRenderer, { DocumentUploadTextTester } from './DocumentUploadText';
import PhoneHorizontalLayoutRenderer, {
  PhoneHorizontalLayoutTester,
} from './PhoneHorizontalLayout';
import PhoneCountryCodeRenderer, {
  PhoneCountryCodeTester,
} from './PhoneHorizontalLayout/CountryCodeRenderer';
import HorizontalLayoutRenderer, { HorizontalLayoutTester } from './HorizontalLayout';
import SummaryPhoneLayoutRenderer, { SummaryPhoneLayoutTester } from './SummaryPhoneLayout';
import SummaryFileListRenderer, { SummaryFileListTester } from './SummaryFileList';
import SummaryTextListRenderer, { SummaryTextListTester } from './SummaryTextList';
import SummaryHorizontalLayoutRenderer, {
  SummaryHorizontalLayoutTester,
} from './SummaryHorizontalLayout';
import VerticalLayoutRenderer, { VerticalLayoutTester } from './VerticalLayout';

const CustomRenderers: JsonFormsRendererRegistryEntry[] = [
  { tester: FormTester, renderer: FormRenderer },
  { tester: SubFormTester, renderer: SubFormRenderer },
  { tester: TextTester, renderer: TextRenderer },
  { tester: PhoneControlTester, renderer: PhoneControlRenderer },
  { tester: ControlTester, renderer: ControlRenderer },
  { tester: SummaryBlockTester, renderer: SummaryBlockRenderer },
  { tester: SummaryControlFieldTester, renderer: SummaryControlFieldRenderer },
  { tester: SummaryControlCheckboxTester, renderer: SummaryControlCheckboxRenderer },
  { tester: SummaryControlErrorTester, renderer: SummaryError },
  { tester: SummarySubSectionTester, renderer: SummarySubSectionRenderer },
  { tester: RadioControlTester, renderer: RadioControlRenderer },
  { tester: CheckboxDatetimeTester, renderer: CheckboxDatetimeRenderer },
  { tester: MultiSelectTester, renderer: MultiSelectRenderer },
  { tester: ControlTester, renderer: ControlRenderer },
  { tester: JumioTester, renderer: JumioRenderer },
  { tester: UploadDocumentTester, renderer: UploadDocumentRenderer },
  { tester: DocumentUploadTextTester, renderer: DocumentUploadTextRenderer },
  { tester: PhoneHorizontalLayoutTester, renderer: PhoneHorizontalLayoutRenderer },
  { tester: PhoneCountryCodeTester, renderer: PhoneCountryCodeRenderer },
  { tester: HorizontalLayoutTester, renderer: HorizontalLayoutRenderer },
  { tester: SummaryPhoneLayoutTester, renderer: SummaryPhoneLayoutRenderer },
  { tester: SummaryFileListTester, renderer: SummaryFileListRenderer },
  { tester: SummaryTextListTester, renderer: SummaryTextListRenderer },
  { tester: SummaryHorizontalLayoutTester, renderer: SummaryHorizontalLayoutRenderer },
  { tester: VerticalLayoutTester, renderer: VerticalLayoutRenderer },
];

export default CustomRenderers;
