import React from 'react';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { rankWith, uiTypeIs, UISchemaElement, RendererProps } from '@jsonforms/core';
import { Typography } from 'src/components/kit';
import './DocumentUploadText.scss';

interface DocumentUploadProps extends RendererProps {
  uischema: UISchemaElement | any;
}

const DocumentUploadTextRenderer = (props: DocumentUploadProps): JSX.Element => {
  const { uischema, visible } = props;
  const value = uischema.value as string;

  return (
    visible && (
      <div className='document-upload-text-container'>
        <Typography variant='body1'>{`${value}`}</Typography>
      </div>
    )
  );
};

export const DocumentUploadTextTester = rankWith(105, uiTypeIs('Text'));
export default withJsonFormsLayoutProps(DocumentUploadTextRenderer);
