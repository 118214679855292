export const getSchemaByName = (schemas: any, schemaName: string) => {
  if (!schemas) {
    return {
      uiSchema: {},
      dataSchema: {},
    };
  }
  const uiSchema = schemas.uiSchemas.filter((current: any) => current.schemaName === schemaName);
  const dataSchema = schemas.dataSchemas.filter(
    (current: any) => current.schemaName === schemaName,
  );

  if (uiSchema.length < 1 && dataSchema.length < 1) {
    return {
      uiSchema: {},
      dataSchema: {},
    };
  }
  return {
    uiSchema: uiSchema[0]?.schema ?? {},
    dataSchema: dataSchema[0]?.schema ?? {},
  };
};
