import React from 'react';

import { ReactComponent as Candlestick } from 'src/assets/icons/candlestick.svg';
import { ReactComponent as Notification } from 'src/assets/icons/notification.svg';
import { ReactComponent as Fund } from 'src/assets/icons/fund.svg';
import { ReactComponent as File } from 'src/assets/icons/file.svg';
import { ReactComponent as Info } from 'src/assets/icons/info.svg';
import { ReactComponent as Search } from 'src/assets/icons/search.svg';
import { ReactComponent as Filter } from 'src/assets/icons/filter.svg';
import { ReactComponent as Favourite } from 'src/assets/icons/favourite.svg';
import { ReactComponent as Clock } from 'src/assets/icons/clock.svg';
import { ReactComponent as Link } from 'src/assets/icons/link.svg';
import { ReactComponent as Unlink } from 'src/assets/icons/unlink.svg';
import { ReactComponent as Calendar } from 'src/assets/icons/calendar.svg';
import { ReactComponent as Download } from 'src/assets/icons/download.svg';
import { ReactComponent as PieChart } from 'src/assets/icons/pie-chart.svg';
import { ReactComponent as More } from 'src/assets/icons/more.svg';
import { ReactComponent as Personal } from 'src/assets/icons/personal.svg';
import { ReactComponent as Experience } from 'src/assets/icons/experience.svg';
import { ReactComponent as Password } from 'src/assets/icons/password.svg';
import { ReactComponent as Settings } from 'src/assets/icons/settings.svg';
import { ReactComponent as Support } from 'src/assets/icons/support.svg';
import { ReactComponent as Logout } from 'src/assets/icons/logout.svg';
import { ReactComponent as Information } from 'src/assets/icons/information.svg';
import { ReactComponent as StepCompleted } from 'src/assets/icons/step-completed.svg';
import { ReactComponent as StepInProgress } from 'src/assets/icons/step-in-progress.svg';
import { ReactComponent as StepNotStarted } from 'src/assets/icons/step-not-started.svg';
import { ReactComponent as StepStarted } from 'src/assets/icons/step-started.svg';
import { ReactComponent as Camera } from 'src/assets/icons/camera.svg';
import { ReactComponent as Warning } from 'src/assets/icons/warning.svg';
import { ReactComponent as Avatar } from 'src/assets/icons/avatar.svg';
import { ReactComponent as Education } from 'src/assets/icons/education.svg';
import { ReactComponent as Check } from 'src/assets/icons/check.svg';
import { ReactComponent as ArrowBack } from 'src/assets/icons/arrow-back.svg';
import { ReactComponent as ArrowNext } from 'src/assets/icons/arrow-next.svg';
import { ReactComponent as ArrowDown } from 'src/assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'src/assets/icons/arrow-up.svg';
import { ReactComponent as Close } from 'src/assets/icons/close.svg';
import { ReactComponent as Plus } from 'src/assets/icons/plus.svg';
import { ReactComponent as MovementUp } from 'src/assets/icons/movement-up.svg';
import { ReactComponent as MovementDown } from 'src/assets/icons/movement-down.svg';
import { ReactComponent as MovementNone } from 'src/assets/icons/movement-none.svg';
import { ReactComponent as Bullet } from 'src/assets/icons/bullet.svg';
import { ReactComponent as CloseRound } from 'src/assets/icons/close-round.svg';
import { ReactComponent as EyeOn } from 'src/assets/icons/eye-on.svg';
import { ReactComponent as EyeOff } from 'src/assets/icons/eye-off.svg';
import { ReactComponent as Portfolio } from 'src/assets/icons/portfolio.svg';
import { ReactComponent as Help } from 'src/assets/icons/help.svg';
import { ReactComponent as Menu } from 'src/assets/icons/menu.svg';
import { ReactComponent as MenuItem } from 'src/assets/icons/menu-item.svg';
import { ReactComponent as BulletAlternative } from 'src/assets/icons/bullet-alternative.svg';
import { ReactComponent as Withdraw } from 'src/assets/icons/withdraw.svg';
import { ReactComponent as Chart } from 'src/assets/icons/chart.svg';
import { ReactComponent as OpenExternal } from 'src/assets/icons/open-external.svg';
import { ReactComponent as StepFailed } from 'src/assets/icons/step-failed.svg';
import { ReactComponent as PasswordStepNotStarted } from 'src/assets/icons/password-step-not-started.svg';
import { ReactComponent as PasswordStepCompleted } from 'src/assets/icons/password-step-completed.svg';
import { ReactComponent as Globe } from 'src/assets/icons/globe.svg';

const ICONS = {
  candlestick: Candlestick,
  notification: Notification,
  fund: Fund,
  file: File,
  info: Info,
  search: Search,
  filter: Filter,
  favourite: Favourite,
  clock: Clock,
  link: Link,
  unlink: Unlink,
  calendar: Calendar,
  download: Download,
  pieChart: PieChart,
  more: More,
  personal: Personal,
  experience: Experience,
  password: Password,
  settings: Settings,
  support: Support,
  logout: Logout,
  information: Information,
  stepCompleted: StepCompleted,
  stepInProgress: StepInProgress,
  stepNotStarted: StepNotStarted,
  stepFailed: StepFailed,
  stepStarted: StepStarted,
  passwordStepNotStarted: PasswordStepNotStarted,
  passwordStepCompleted: PasswordStepCompleted,
  camera: Camera,
  warning: Warning,
  avatar: Avatar,
  education: Education,
  check: Check,
  arrowBack: ArrowBack,
  arrowNext: ArrowNext,
  arrowDown: ArrowDown,
  arrowUp: ArrowUp,
  close: Close,
  plus: Plus,
  movementUp: MovementUp,
  movementDown: MovementDown,
  movementNone: MovementNone,
  bullet: Bullet,
  closeRound: CloseRound,
  eyeOn: EyeOn,
  eyeOff: EyeOff,
  portfolio: Portfolio,
  help: Help,
  menu: Menu,
  menuItem: MenuItem,
  bulletAlternative: BulletAlternative,
  withdraw: Withdraw,
  chart: Chart,
  openExternal: OpenExternal,
  globe: Globe,
};

interface IconProps {
  /**
   * Apply color to icon
   */
  color?: string;
  /**
   * An identifier that allows to select the desired icon. The names correspond to the names in the design
   */
  name: IconName;
  /**
   * Value of icon width and height
   */
  size?: number;
  /**
   * Description for icon
   */
  title?: string;
}

const Icon: React.FC<IconProps> = ({
  color = '#282424',
  name,
  size = 24,
  ...otherProps
}: IconProps) => {
  const SvgComponent = ICONS[name];
  if (!SvgComponent) {
    return null;
  }
  if (name === 'calendar') {
    return <SvgComponent height={size} width={size} {...otherProps} />;
  }
  return <SvgComponent height={size} width={size} fill={color} {...otherProps} />;
};

export default Icon;
