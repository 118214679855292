import React, { useState } from 'react';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { rankWith, uiTypeIs } from '@jsonforms/core';
import './Jumio.styles.scss';
import { Button, RadioButton, Typography } from 'src/components/kit';
import { useDispatch, useSelector } from 'src/store';
import { InitiateRequest, DocumentType } from 'src/resources/api-client/jumio';
import { jumioRedirectUri, onboardingServiceConfig } from 'src/config';
import { initiateJumio } from 'src/slices/jumio';
import { IdDocumentType } from 'src/resources/api-client/onboarding';
import { setCurrentStep } from 'src/slices/auth';
import useAnalytics from 'src/analytics/useAnalytics';
import { useIntl } from 'react-intl';
import BackButton from 'src/components/BackButton';
import getSectionIdOfRecentPage from 'src/utils/getSectionIdOfRecentPage';

const getDocumentTypeLabel = (idDocumentType: string): string => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();
  let label = 'Passport';
  switch (idDocumentType) {
    case IdDocumentType.Passport: {
      label = intl.formatMessage({ id: 'kyc.idVerification.passport', defaultMessage: 'Passport' });
      break;
    }
    case IdDocumentType.DrivingLicense: {
      label = intl.formatMessage({
        id: 'kyc.idVerification.drivingLicense',
        defaultMessage: 'Driver License',
      });

      break;
    }
    case IdDocumentType.NationalId: {
      label = intl.formatMessage({
        id: 'kyc.idVerification.nationalId',
        defaultMessage: 'Identity Card',
      });
      break;
    }
    default: {
      break;
    }
  }
  return label;
};

const getDocumentTypeValue = (idDocumentType: string): string => {
  let value = 'PASSPORT';
  switch (idDocumentType) {
    case IdDocumentType.Passport: {
      value = 'PASSPORT';
      break;
    }
    case IdDocumentType.DrivingLicense: {
      value = 'DRIVING_LICENSE';
      break;
    }
    case IdDocumentType.NationalId: {
      value = 'ID_CARD';
      break;
    }
    default: {
      break;
    }
  }
  return value;
};

const allowedDocumentTypes = ['Passport', 'DrivingLicense', 'NationalId'];
const formKey = 'affiliate-idVerification';
const JumioRenderer = (): JSX.Element => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { lead, acceptLanguage } = useSelector((state: any) => state.onboarding);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const intl = useIntl();
  // const settings = useSelector((s) => s.onboarding.settings);
  // const settingsStatus = useSelector((s) => s.onboarding.getSettingsStatus);
  // const [activeSelection, setActiveSelection] = useState(
  //   getDocumentTypeValue(settings?.allowedIdDocumentTypes[0]) ?? IdDocumentType.Passport
  // );
  const [activeSelection, setActiveSelection] = useState(
    getDocumentTypeValue(allowedDocumentTypes[0]),
  );
  const dispatch = useDispatch();
  const trackEvent = useAnalytics();
  const radioButtonClick = (event, value): void => {
    setActiveSelection(value);
  };

  const onVerifyId = (): void => {
    trackEvent('Affiliate ID Verification Started', undefined);
    const request: InitiateRequest = {
      id: lead.customerId,
      userReference: `affiliate-${lead.customerId}`,
      workflowId: 200,
      isoCountry: lead.countryCode,
      documentType: activeSelection as DocumentType,
      redirectSuccessUrl: jumioRedirectUri,
      redirectFailureUrl: jumioRedirectUri,
      callbackUrl: `${onboardingServiceConfig.apiUrl}/jumio-callback`,
      locale: acceptLanguage,
    };
    dispatch(initiateJumio(request));
  };

  const onSaveAndSkip = (): void => {
    localStorage.setItem('JumioVerification', 'saveAndSkip');
    trackEvent('Affiliate ID Verification Skipped', undefined);
    dispatch(setCurrentStep('affiliate-finances'));
  };
  // if (settingsStatus === 'ready') {
  return (
    <>
      <div className='selectCaption'>
        <Typography variant='h3'>
          {intl.formatMessage({
            id: 'kyc.idVerification.typeOfDocumentToScanText',
            defaultMessage: 'Select the type of document you wish to scan',
          })}
        </Typography>
      </div>

      <div className='radioButtonGroup'>
        {allowedDocumentTypes.map((documentType: any) => (
          <RadioButton
            checked={activeSelection === getDocumentTypeValue(documentType)}
            id={`radiobtn${documentType}`}
            label={getDocumentTypeLabel(documentType)}
            name={`radio-btn-${documentType}`}
            onClick={radioButtonClick}
            value={getDocumentTypeValue(documentType)}
          />
        ))}
      </div>
      <div className='flex kyc-btn-container id-verification-btn-container'>
        <BackButton
          onClick={() => {
            dispatch(setCurrentStep(getSectionIdOfRecentPage(formKey)));
            trackEvent('Affiliate - Back', {
              pageDetails: formKey,
            });
          }}
        />
        <div className='kyc-btn-inner-container'>
          <div>
            <Button
              label={intl.formatMessage({
                id: 'kyc.idVerification.verifyNowBtn',
                defaultMessage: 'VERIFY MY ID NOW',
              })}
              data-testid='btnVerify'
              variant='primary'
              onClick={onVerifyId}
            />
          </div>
          <div className='save-and-skip-button-container'>
            <Button
              label={intl.formatMessage({
                id: 'kyc.idVerification.saveAndSkipBtn',
                defaultMessage: 'SAVE AND SKIP',
              })}
              data-testid='btnSaveAndSkip'
              variant='secondary'
              onClick={onSaveAndSkip}
            />
          </div>
        </div>
      </div>
    </>
  );
  // }

  // return <></>;
};

export const JumioTester = rankWith(1, uiTypeIs('JumioIdVerification'));
export default withJsonFormsLayoutProps(JumioRenderer);
