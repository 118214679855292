/* tslint:disable */
/* eslint-disable */
/**
 * OnboardingAPI
 * API for onboarding users
 *
 * The version of the OpenAPI document: 1.0.2
 * Contact:
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AffiliateLead
 */
export interface AffiliateLead {
  /**
   *
   * @type {CountryCode}
   * @memberof AffiliateLead
   */
  countryCode: CountryCode;
  /**
   *
   * @type {string}
   * @memberof AffiliateLead
   */
  customerId: string;
  /**
   *
   * @type {string}
   * @memberof AffiliateLead
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof AffiliateLead
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof AffiliateLead
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof AffiliateLead
   */
  phoneCountryCode: string;
  /**
   *
   * @type {string}
   * @memberof AffiliateLead
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof AffiliateLead
   */
  privacyPolicyAcceptedTime: string;
  /**
   *
   * @type {AffiliateLeadApplication}
   * @memberof AffiliateLead
   */
  application?: AffiliateLeadApplication | null;
  /**
   *
   * @type {string}
   * @memberof AffiliateLead
   */
  externalCustomerRef?: string | null;
  /**
   *
   * @type {AffiliateLeadIdVerification}
   * @memberof AffiliateLead
   */
  idVerification?: AffiliateLeadIdVerification | null;
  /**
   *
   * @type {{ [key: string]: { [key: string]: any; }; }}
   * @memberof AffiliateLead
   */
  kycSections?: { [key: string]: { [key: string]: any } } | null;
}
/**
 *
 * @export
 * @interface AffiliateLeadApplication
 */
export interface AffiliateLeadApplication {
  /**
   *
   * @type {ApplicationStatus}
   * @memberof AffiliateLeadApplication
   */
  status: ApplicationStatus;
  /**
   *
   * @type {AffiliateLeadApplicationRemediation}
   * @memberof AffiliateLeadApplication
   */
  remediation?: AffiliateLeadApplicationRemediation | null;
}
/**
 *
 * @export
 * @interface AffiliateLeadApplicationRemediation
 */
export interface AffiliateLeadApplicationRemediation {
  /**
   *
   * @type {ApplicationRemediationStatus}
   * @memberof AffiliateLeadApplicationRemediation
   */
  status?: ApplicationRemediationStatus;
  /**
   *
   * @type {string}
   * @memberof AffiliateLeadApplicationRemediation
   */
  info?: string | null;
}
/**
 *
 * @export
 * @interface AffiliateLeadIdVerification
 */
export interface AffiliateLeadIdVerification {
  /**
   *
   * @type {IdVerificationStatus}
   * @memberof AffiliateLeadIdVerification
   */
  status: IdVerificationStatus;
  /**
   *
   * @type {string}
   * @memberof AffiliateLeadIdVerification
   */
  transactionTime: string;
  /**
   *
   * @type {string}
   * @memberof AffiliateLeadIdVerification
   */
  remediationInfo?: string | null;
  /**
   *
   * @type {ConfidentialIdVerificationRedirectionInfo}
   * @memberof AffiliateLeadIdVerification
   */
  redirectionInfo?: ConfidentialIdVerificationRedirectionInfo | null;
}
/**
 *
 * @export
 * @interface ApplicationRemediation
 */
export interface ApplicationRemediation {
  /**
   *
   * @type {ApplicationRemediationStatus}
   * @memberof ApplicationRemediation
   */
  status?: ApplicationRemediationStatus;
  /**
   *
   * @type {string}
   * @memberof ApplicationRemediation
   */
  info?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ApplicationRemediationStatus = {
  DuplicateTin: 'DuplicateTin'
} as const;

export type ApplicationRemediationStatus = typeof ApplicationRemediationStatus[keyof typeof ApplicationRemediationStatus];

/**
 *
 * @export
 * @enum {string}
 */

export const ApplicationStatus = {
  Complete: 'Complete',
  Pending: 'Pending',
  Rejected: 'Rejected',
  RequiresRemediation: 'RequiresRemediation',
  Started: 'Started'
} as const;

export type ApplicationStatus = typeof ApplicationStatus[keyof typeof ApplicationStatus];

/**
 *
 * @export
 * @interface AssociatedCustomer
 */
export interface AssociatedCustomer {
  /**
   *
   * @type {string}
   * @memberof AssociatedCustomer
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof AssociatedCustomer
   */
  source: string;
  /**
   *
   * @type {string}
   * @memberof AssociatedCustomer
   */
  status: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const AssociatedCustomerCheckStatus = {
  Complete: 'Complete',
  Pending: 'Pending'
} as const;

export type AssociatedCustomerCheckStatus = typeof AssociatedCustomerCheckStatus[keyof typeof AssociatedCustomerCheckStatus];

/**
 *
 * @export
 * @interface AssociatedCustomers
 */
export interface AssociatedCustomers {
  /**
   *
   * @type {AssociatedCustomerCheckStatus}
   * @memberof AssociatedCustomers
   */
  status: AssociatedCustomerCheckStatus;
  /**
   *
   * @type {Array<AssociatedCustomer>}
   * @memberof AssociatedCustomers
   */
  customers: Array<AssociatedCustomer>;
}
/**
 *
 * @export
 * @interface BusinessRule
 */
export interface BusinessRule {
  /**
   *
   * @type {string}
   * @memberof BusinessRule
   */
  sectionKey: string;
  /**
   *
   * @type {string}
   * @memberof BusinessRule
   */
  propertyKey: string;
  /**
   *
   * @type {RuleType}
   * @memberof BusinessRule
   */
  ruleType: RuleType;
  /**
   *
   * @type {string}
   * @memberof BusinessRule
   */
  requiredValue: string;
  /**
   *
   * @type {string}
   * @memberof BusinessRule
   */
  failureMessage: string;
  /**
   *
   * @type {string}
   * @memberof BusinessRule
   */
  subPropertyKey?: string | null;
  /**
   *
   * @type {MatchType}
   * @memberof BusinessRule
   */
  matchType?: MatchType | null;
}
/**
 *
 * @export
 * @interface BusinessRules
 */
export interface BusinessRules {
  /**
   *
   * @type {Array<BusinessRule>}
   * @memberof BusinessRules
   */
  rules?: Array<BusinessRule> | null;
}
/**
 *
 * @export
 * @interface ConfidentialAffiliateLead
 */
export interface ConfidentialAffiliateLead {
  /**
   *
   * @type {string}
   * @memberof ConfidentialAffiliateLead
   */
  customerId: string;
  /**
   *
   * @type {string}
   * @memberof ConfidentialAffiliateLead
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof ConfidentialAffiliateLead
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ConfidentialAffiliateLead
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof ConfidentialAffiliateLead
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof ConfidentialAffiliateLead
   */
  phoneCountryCode: string;
  /**
   *
   * @type {CountryCode}
   * @memberof ConfidentialAffiliateLead
   */
  countryCode: CountryCode;
  /**
   *
   * @type {string}
   * @memberof ConfidentialAffiliateLead
   */
  userTermsAndConditionsAcceptedTime: string;
  /**
   *
   * @type {ConfidentialAffiliateLeadApplication}
   * @memberof ConfidentialAffiliateLead
   */
  application?: ConfidentialAffiliateLeadApplication | null;
  /**
   *
   * @type {string}
   * @memberof ConfidentialAffiliateLead
   */
  externalCustomerRef?: string | null;
  /**
   *
   * @type {ConfidentialAffiliateLeadIdVerification}
   * @memberof ConfidentialAffiliateLead
   */
  idVerification?: ConfidentialAffiliateLeadIdVerification | null;
  /**
   *
   * @type {string}
   * @memberof ConfidentialAffiliateLead
   */
  initialLoginConnection?: string | null;
  /**
   *
   * @type {{ [key: string]: { [key: string]: any; }; }}
   * @memberof ConfidentialAffiliateLead
   */
  kycSections?: { [key: string]: { [key: string]: any } } | null;
}
/**
 *
 * @export
 * @interface ConfidentialAffiliateLeadApplication
 */
export interface ConfidentialAffiliateLeadApplication {
  /**
   *
   * @type {ConfidentialApplicationStatus}
   * @memberof ConfidentialAffiliateLeadApplication
   */
  confidentialStatus: ConfidentialApplicationStatus;
  /**
   *
   * @type {Array<string>}
   * @memberof ConfidentialAffiliateLeadApplication
   */
  details?: Array<string> | null;
  /**
   *
   * @type {AffiliateLeadApplicationRemediation}
   * @memberof ConfidentialAffiliateLeadApplication
   */
  remediation?: AffiliateLeadApplicationRemediation | null;
  /**
   *
   * @type {string}
   * @memberof ConfidentialAffiliateLeadApplication
   */
  lastUpdate?: string;
  /**
   *
   * @type {ApplicationStatus}
   * @memberof ConfidentialAffiliateLeadApplication
   */
  status: ApplicationStatus;
}
/**
 *
 * @export
 * @interface ConfidentialAffiliateLeadIdVerification
 */
export interface ConfidentialAffiliateLeadIdVerification {
  /**
   *
   * @type {string}
   * @memberof ConfidentialAffiliateLeadIdVerification
   */
  transactionTime?: string;
  /**
   *
   * @type {ConfidentialIdVerificationStatus}
   * @memberof ConfidentialAffiliateLeadIdVerification
   */
  confidentialStatus?: ConfidentialIdVerificationStatus;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ConfidentialAffiliateLeadIdVerification
   */
  confidentialDetails?: { [key: string]: string } | null;
  /**
   *
   * @type {string}
   * @memberof ConfidentialAffiliateLeadIdVerification
   */
  remediationInfo?: string | null;
  /**
   *
   * @type {string}
   * @memberof ConfidentialAffiliateLeadIdVerification
   */
  rejectionDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof ConfidentialAffiliateLeadIdVerification
   */
  rejectionDescriptionExtra?: string | null;
  /**
   *
   * @type {string}
   * @memberof ConfidentialAffiliateLeadIdVerification
   */
  reference?: string | null;
  /**
   *
   * @type {IdDocumentType}
   * @memberof ConfidentialAffiliateLeadIdVerification
   */
  documentType?: IdDocumentType | null;
  /**
   *
   * @type {ConfidentialIdVerificationRedirectionInfo}
   * @memberof ConfidentialAffiliateLeadIdVerification
   */
  redirectionInfo?: ConfidentialIdVerificationRedirectionInfo | null;
  /**
   *
   * @type {IdVerificationStatus}
   * @memberof ConfidentialAffiliateLeadIdVerification
   */
  status: IdVerificationStatus;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ConfidentialApplicationStatus = {
  Complete: 'Complete',
  Accepted: 'Accepted',
  Duplicate: 'Duplicate',
  FailedBusinessRules: 'FailedBusinessRules',
  Fraud: 'Fraud',
  PositivePepsCheck: 'PositivePepsCheck',
  Submitted: 'Submitted',
  RequiresRemediation: 'RequiresRemediation',
  Started: 'Started'
} as const;

export type ConfidentialApplicationStatus = typeof ConfidentialApplicationStatus[keyof typeof ConfidentialApplicationStatus];

/**
 *
 * @export
 * @interface ConfidentialIdVerification
 */
export interface ConfidentialIdVerification {
  /**
   *
   * @type {string}
   * @memberof ConfidentialIdVerification
   */
  transactionTime?: string;
  /**
   *
   * @type {ConfidentialIdVerificationStatus}
   * @memberof ConfidentialIdVerification
   */
  confidentialStatus?: ConfidentialIdVerificationStatus;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof ConfidentialIdVerification
   */
  confidentialDetails?: { [key: string]: string } | null;
  /**
   *
   * @type {string}
   * @memberof ConfidentialIdVerification
   */
  remediationInfo?: string | null;
  /**
   *
   * @type {string}
   * @memberof ConfidentialIdVerification
   */
  rejectionDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof ConfidentialIdVerification
   */
  rejectionDescriptionExtra?: string | null;
  /**
   *
   * @type {string}
   * @memberof ConfidentialIdVerification
   */
  reference?: string | null;
  /**
   *
   * @type {IdDocumentType}
   * @memberof ConfidentialIdVerification
   */
  documentType?: IdDocumentType | null;
  /**
   *
   * @type {ConfidentialIdVerificationRedirectionInfo}
   * @memberof ConfidentialIdVerification
   */
  redirectionInfo?: ConfidentialIdVerificationRedirectionInfo | null;
  /**
   *
   * @type {IdVerificationStatus}
   * @memberof ConfidentialIdVerification
   */
  status: IdVerificationStatus;
}
/**
 *
 * @export
 * @interface ConfidentialIdVerificationRedirectionInfo
 */
export interface ConfidentialIdVerificationRedirectionInfo {
  /**
   *
   * @type {string}
   * @memberof ConfidentialIdVerificationRedirectionInfo
   */
  sectionId: string;
  /**
   *
   * @type {string}
   * @memberof ConfidentialIdVerificationRedirectionInfo
   */
  subFormId?: string | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ConfidentialIdVerificationStatus = {
  Approved: 'Approved',
  Fraud: 'Fraud',
  IncorrectName: 'IncorrectName',
  IncorrectDob: 'IncorrectDob',
  UnsupportedIdType: 'UnsupportedIdType',
  UnsupportedIdCountry: 'UnsupportedIdCountry',
  UnreadableId: 'UnreadableId',
  NoId: 'NoId',
  ExpiredId: 'ExpiredId',
  NoExpiry: 'NoExpiry',
  Pending: 'Pending'
} as const;

export type ConfidentialIdVerificationStatus = typeof ConfidentialIdVerificationStatus[keyof typeof ConfidentialIdVerificationStatus];

/**
 *
 * @export
 * @interface ConfidentialLead
 */
export interface ConfidentialLead {
  /**
   *
   * @type {string}
   * @memberof ConfidentialLead
   */
  customerId: string;
  /**
   *
   * @type {string}
   * @memberof ConfidentialLead
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof ConfidentialLead
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ConfidentialLead
   */
  termsAndConditionsAcceptedTime: string;
  /**
   *
   * @type {Array<TradingAccount>}
   * @memberof ConfidentialLead
   */
  demoTradingAccounts: Array<TradingAccount>;
  /**
   *
   * @type {ConfidentialLeadAssociatedEmailCustomers}
   * @memberof ConfidentialLead
   */
  associatedEmailCustomers: ConfidentialLeadAssociatedEmailCustomers;
  /**
   *
   * @type {ConfidentialLeadApplication}
   * @memberof ConfidentialLead
   */
  application?: ConfidentialLeadApplication | null;
  /**
   *
   * @type {string}
   * @memberof ConfidentialLead
   */
  externalCustomerRef?: string | null;
  /**
   *
   * @type {CountryCode}
   * @memberof ConfidentialLead
   */
  countryCode?: CountryCode | null;
  /**
   *
   * @type {CurrencyCode}
   * @memberof ConfidentialLead
   */
  currencyCode?: CurrencyCode | null;
  /**
   *
   * @type {ConfidentialAffiliateLeadIdVerification}
   * @memberof ConfidentialLead
   */
  idVerification?: ConfidentialAffiliateLeadIdVerification | null;
  /**
   *
   * @type {string}
   * @memberof ConfidentialLead
   */
  customerTermsAndConditionsAcceptedTime?: string | null;
  /**
   *
   * @type {string}
   * @memberof ConfidentialLead
   */
  customerTermsAndConditionsAcceptedIpAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof ConfidentialLead
   */
  initialLoginConnection?: string | null;
  /**
   *
   * @type {{ [key: string]: { [key: string]: any; }; }}
   * @memberof ConfidentialLead
   */
  kycSections?: { [key: string]: { [key: string]: any } } | null;
}
/**
 *
 * @export
 * @interface ConfidentialLeadApplication
 */
export interface ConfidentialLeadApplication {
  /**
   *
   * @type {ConfidentialApplicationStatus}
   * @memberof ConfidentialLeadApplication
   */
  confidentialStatus: ConfidentialApplicationStatus;
  /**
   *
   * @type {Array<string>}
   * @memberof ConfidentialLeadApplication
   */
  details?: Array<string> | null;
  /**
   *
   * @type {AffiliateLeadApplicationRemediation}
   * @memberof ConfidentialLeadApplication
   */
  remediation?: AffiliateLeadApplicationRemediation | null;
  /**
   *
   * @type {ConfidentialLeadApplicationIncentives}
   * @memberof ConfidentialLeadApplication
   */
  incentives?: ConfidentialLeadApplicationIncentives | null;
  /**
   *
   * @type {Array<IncentiveType>}
   * @memberof ConfidentialLeadApplication
   */
  incentivesApplied?: Array<IncentiveType> | null;
  /**
   *
   * @type {string}
   * @memberof ConfidentialLeadApplication
   */
  lastUpdate?: string;
  /**
   *
   * @type {ApplicationStatus}
   * @memberof ConfidentialLeadApplication
   */
  status: ApplicationStatus;
}
/**
 *
 * @export
 * @interface ConfidentialLeadApplicationIncentives
 */
export interface ConfidentialLeadApplicationIncentives {
  /**
   *
   * @type {IncentivesFreeCredit}
   * @memberof ConfidentialLeadApplicationIncentives
   */
  freeCredit?: IncentivesFreeCredit | null;
}
/**
 *
 * @export
 * @interface ConfidentialLeadAssociatedEmailCustomers
 */
export interface ConfidentialLeadAssociatedEmailCustomers {
  /**
   *
   * @type {AssociatedCustomerCheckStatus}
   * @memberof ConfidentialLeadAssociatedEmailCustomers
   */
  status: AssociatedCustomerCheckStatus;
  /**
   *
   * @type {Array<AssociatedCustomer>}
   * @memberof ConfidentialLeadAssociatedEmailCustomers
   */
  customers: Array<AssociatedCustomer>;
}
/**
 *
 * @export
 * @interface Country
 */
export interface Country {
  /**
   *
   * @type {CountryCode}
   * @memberof Country
   */
  countryCode: CountryCode;
  /**
   *
   * @type {string}
   * @memberof Country
   */
  countryName: string;
  /**
   *
   * @type {Array<Currency>}
   * @memberof Country
   */
  currencies: Array<Currency>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const CountryCode = {
  Ca: 'CA',
  Ae: 'AE',
  Af: 'AF',
  Ag: 'AG',
  Ai: 'AI',
  Al: 'AL',
  Am: 'AM',
  Ao: 'AO',
  Ar: 'AR',
  At: 'AT',
  Au: 'AU',
  Az: 'AZ',
  Ba: 'BA',
  Bb: 'BB',
  Be: 'BE',
  Bf: 'BF',
  Bg: 'BG',
  Bh: 'BH',
  Bj: 'BJ',
  Bm: 'BM',
  Bn: 'BN',
  Bo: 'BO',
  Br: 'BR',
  Bs: 'BS',
  Bt: 'BT',
  Bw: 'BW',
  By: 'BY',
  Bz: 'BZ',
  Ch: 'CH',
  Ci: 'CI',
  Cl: 'CL',
  Cm: 'CM',
  Co: 'CO',
  Cr: 'CR',
  Cv: 'CV',
  Cy: 'CY',
  Cz: 'CZ',
  De: 'DE',
  Dk: 'DK',
  Dm: 'DM',
  Do: 'DO',
  Dz: 'DZ',
  Ec: 'EC',
  Ee: 'EE',
  Eg: 'EG',
  Es: 'ES',
  Fi: 'FI',
  Fj: 'FJ',
  Fm: 'FM',
  Fr: 'FR',
  Ga: 'GA',
  Gb: 'GB',
  Gd: 'GD',
  Ge: 'GE',
  Gh: 'GH',
  Gm: 'GM',
  Gr: 'GR',
  Gt: 'GT',
  Gw: 'GW',
  Gy: 'GY',
  Hk: 'HK',
  Hn: 'HN',
  Hr: 'HR',
  Hu: 'HU',
  Id: 'ID',
  Ie: 'IE',
  In: 'IN',
  Iq: 'IQ',
  Is: 'IS',
  It: 'IT',
  Jm: 'JM',
  Jo: 'JO',
  Ke: 'KE',
  Kg: 'KG',
  Kh: 'KH',
  Kn: 'KN',
  Kr: 'KR',
  Kw: 'KW',
  Ky: 'KY',
  Kz: 'KZ',
  La: 'LA',
  Lb: 'LB',
  Lc: 'LC',
  Lk: 'LK',
  Lr: 'LR',
  Lt: 'LT',
  Lu: 'LU',
  Lv: 'LV',
  Ly: 'LY',
  Ma: 'MA',
  Md: 'MD',
  Me: 'ME',
  Mg: 'MG',
  Mk: 'MK',
  Ml: 'ML',
  Mm: 'MM',
  Mn: 'MN',
  Mo: 'MO',
  Mr: 'MR',
  Ms: 'MS',
  Mt: 'MT',
  Mu: 'MU',
  Mw: 'MW',
  Mx: 'MX',
  My: 'MY',
  Na: 'NA',
  Ne: 'NE',
  Ng: 'NG',
  Ni: 'NI',
  Nl: 'NL',
  No: 'NO',
  Np: 'NP',
  Nr: 'NR',
  Nz: 'NZ',
  Om: 'OM',
  Pa: 'PA',
  Pe: 'PE',
  Pg: 'PG',
  Ph: 'PH',
  Pk: 'PK',
  Pl: 'PL',
  Pt: 'PT',
  Pw: 'PW',
  Py: 'PY',
  Qa: 'QA',
  Ro: 'RO',
  Rs: 'RS',
  Ru: 'RU',
  Rw: 'RW',
  Sa: 'SA',
  Sb: 'SB',
  Sc: 'SC',
  Se: 'SE',
  Sg: 'SG',
  Si: 'SI',
  Sk: 'SK',
  Sl: 'SL',
  Sn: 'SN',
  Sr: 'SR',
  St: 'ST',
  Sv: 'SV',
  Sz: 'SZ',
  Tc: 'TC',
  Td: 'TD',
  Th: 'TH',
  Tj: 'TJ',
  Tm: 'TM',
  Tn: 'TN',
  To: 'TO',
  Tr: 'TR',
  Tt: 'TT',
  Tw: 'TW',
  Tz: 'TZ',
  Ua: 'UA',
  Ug: 'UG',
  Uy: 'UY',
  Uz: 'UZ',
  Ve: 'VE',
  Vg: 'VG',
  Vn: 'VN',
  Vu: 'VU',
  Xk: 'XK',
  Ye: 'YE',
  Za: 'ZA',
  Zm: 'ZM',
  Zw: 'ZW'
} as const;

export type CountryCode = typeof CountryCode[keyof typeof CountryCode];

/**
 *
 * @export
 * @interface CreateAffiliateIdVerification
 */
export interface CreateAffiliateIdVerification {
  /**
   *
   * @type {string}
   * @memberof CreateAffiliateIdVerification
   */
  reference?: string | null;
}
/**
 *
 * @export
 * @interface CreateAffiliateLead
 */
export interface CreateAffiliateLead {
  /**
   *
   * @type {string}
   * @memberof CreateAffiliateLead
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof CreateAffiliateLead
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof CreateAffiliateLead
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof CreateAffiliateLead
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof CreateAffiliateLead
   */
  promoCode?: string;
  /**
   *
   * @type {string}
   * @memberof CreateAffiliateLead
   */
  phoneCountryCode: string;
  /**
   *
   * @type {CountryCode}
   * @memberof CreateAffiliateLead
   */
  countryCode: CountryCode;

  /**
   *
   * @type {string}
   * @memberof CreateAffiliateLead
   */
  externalParentAffiliateRef: string;
}
/**
 *
 * @export
 * @interface CreateIdVerification
 */
export interface CreateIdVerification {
  /**
   *
   * @type {string}
   * @memberof CreateIdVerification
   */
  reference?: string | null;
}
/**
 *
 * @export
 * @interface CreateLead
 */
export interface CreateLead {
  /**
   *
   * @type {string}
   * @memberof CreateLead
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof CreateLead
   */
  firstName: string;
}
/**
 *
 * @export
 * @interface Currency
 */
export interface Currency {
  /**
   *
   * @type {CurrencyCode}
   * @memberof Currency
   */
  currencyCode: CurrencyCode;
  /**
   *
   * @type {string}
   * @memberof Currency
   */
  currencyName: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const CurrencyCode = {
  Cad: 'CAD',
  Usd: 'USD',
  Gbp: 'GBP',
  Eur: 'EUR',
  Nzd: 'NZD',
  Chf: 'CHF',
  Jpy: 'JPY',
  Aud: 'AUD',
  Hkd: 'HKD',
  Sgd: 'SGD',
  Try: 'TRY',
  Dkk: 'DKK',
  Huf: 'HUF',
  Nok: 'NOK',
  Sek: 'SEK',
  Zar: 'ZAR',
  Pln: 'PLN',
  Mxn: 'MXN',
  Krw: 'KRW',
  Rub: 'RUB',
  Cny: 'CNY',
  Inr: 'INR',
  Czk: 'CZK',
  Brl: 'BRL',
  Php: 'PHP',
  Myr: 'MYR',
  Cnh: 'CNH',
  Idr: 'IDR',
  Thb: 'THB',
  Ngn: 'NGN',
  Ils: 'ILS',
  Clp: 'CLP'
} as const;

export type CurrencyCode = typeof CurrencyCode[keyof typeof CurrencyCode];

/**
 *
 * @export
 * @interface CustomerTermsAndConditions
 */
export interface CustomerTermsAndConditions {
  /**
   *
   * @type {string}
   * @memberof CustomerTermsAndConditions
   */
  privacyPolicy: string;
  /**
   *
   * @type {string}
   * @memberof CustomerTermsAndConditions
   */
  clientAgreement: string;
  /**
   *
   * @type {string}
   * @memberof CustomerTermsAndConditions
   */
  productDisclosureDocument: string;
  /**
   *
   * @type {string}
   * @memberof CustomerTermsAndConditions
   */
  productSchedule: string;
  /**
   *
   * @type {string}
   * @memberof CustomerTermsAndConditions
   */
  financialServicesGuide: string;
}
/**
 *
 * @export
 * @interface ErrorDetails
 */
export interface ErrorDetails {
  /**
   *
   * @type {number}
   * @memberof ErrorDetails
   */
  statusCode?: number;
  /**
   *
   * @type {string}
   * @memberof ErrorDetails
   */
  message?: string | null;
}
/**
 *
 * @export
 * @interface FreeCredit
 */
export interface FreeCredit {
  /**
   *
   * @type {boolean}
   * @memberof FreeCredit
   */
  enabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof FreeCredit
   */
  maxAppliedDaily?: number;
  /**
   *
   * @type {CurrencyCode}
   * @memberof FreeCredit
   */
  currency?: CurrencyCode;
  /**
   *
   * @type {number}
   * @memberof FreeCredit
   */
  amount?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof FreeCredit
   */
  conversions?: { [key: string]: number } | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const IdDocumentType = {
  Passport: 'Passport',
  DrivingLicense: 'DrivingLicense',
  NationalId: 'NationalId'
} as const;

export type IdDocumentType = typeof IdDocumentType[keyof typeof IdDocumentType];

/**
 *
 * @export
 * @interface IdVerification
 */
export interface IdVerification {
  /**
   *
   * @type {IdVerificationStatus}
   * @memberof IdVerification
   */
  status: IdVerificationStatus;
  /**
   *
   * @type {string}
   * @memberof IdVerification
   */
  transactionTime: string;
  /**
   *
   * @type {string}
   * @memberof IdVerification
   */
  remediationInfo?: string | null;
  /**
   *
   * @type {ConfidentialIdVerificationRedirectionInfo}
   * @memberof IdVerification
   */
  redirectionInfo?: ConfidentialIdVerificationRedirectionInfo | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const IdVerificationStatus = {
  Complete: 'Complete',
  RequiresRemediation: 'RequiresRemediation',
  Pending: 'Pending'
} as const;

export type IdVerificationStatus = typeof IdVerificationStatus[keyof typeof IdVerificationStatus];

/**
 *
 * @export
 * @enum {string}
 */

export const IncentiveType = {
  FreeCredit: 'FreeCredit'
} as const;

export type IncentiveType = typeof IncentiveType[keyof typeof IncentiveType];

/**
 *
 * @export
 * @interface Incentives
 */
export interface Incentives {
  /**
   *
   * @type {IncentivesFreeCredit}
   * @memberof Incentives
   */
  freeCredit?: IncentivesFreeCredit | null;
}
/**
 *
 * @export
 * @interface IncentivesFreeCredit
 */
export interface IncentivesFreeCredit {
  /**
   *
   * @type {boolean}
   * @memberof IncentivesFreeCredit
   */
  enabled?: boolean;
  /**
   *
   * @type {number}
   * @memberof IncentivesFreeCredit
   */
  maxAppliedDaily?: number;
  /**
   *
   * @type {CurrencyCode}
   * @memberof IncentivesFreeCredit
   */
  currency?: CurrencyCode;
  /**
   *
   * @type {number}
   * @memberof IncentivesFreeCredit
   */
  amount?: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof IncentivesFreeCredit
   */
  conversions?: { [key: string]: number } | null;
}
/**
 *
 * @export
 * @interface Lead
 */
export interface Lead {
  /**
   *
   * @type {string}
   * @memberof Lead
   */
  customerId: string;
  /**
   *
   * @type {string}
   * @memberof Lead
   */
  emailAddress: string;
  /**
   *
   * @type {string}
   * @memberof Lead
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof Lead
   */
  termsAndConditionsAcceptedTime: string;
  /**
   *
   * @type {Array<TradingAccount>}
   * @memberof Lead
   */
  demoTradingAccounts: Array<TradingAccount>;
  /**
   *
   * @type {ApplicationStatus}
   * @memberof Lead
   * @deprecated
   */
  applicationStatus?: ApplicationStatus | null;
  /**
   *
   * @type {string}
   * @memberof Lead
   */
  externalCustomerRef?: string | null;
  /**
   *
   * @type {CountryCode}
   * @memberof Lead
   */
  countryCode?: CountryCode | null;
  /**
   *
   * @type {CurrencyCode}
   * @memberof Lead
   */
  currencyCode?: CurrencyCode | null;
  /**
   *
   * @type {AffiliateLeadIdVerification}
   * @memberof Lead
   */
  idVerification?: AffiliateLeadIdVerification | null;
  /**
   *
   * @type {string}
   * @memberof Lead
   */
  customerTermsAndConditionsAcceptedTime?: string | null;
  /**
   *
   * @type {string}
   * @memberof Lead
   */
  customerTermsAndConditionsAcceptedIpAddress?: string | null;
  /**
   *
   * @type {LeadApplication}
   * @memberof Lead
   */
  application?: LeadApplication | null;
  /**
   *
   * @type {{ [key: string]: { [key: string]: any; }; }}
   * @memberof Lead
   */
  kycSections?: { [key: string]: { [key: string]: any } } | null;
}
/**
 *
 * @export
 * @interface LeadApplication
 */
export interface LeadApplication {
  /**
   *
   * @type {ApplicationStatus}
   * @memberof LeadApplication
   */
  status?: ApplicationStatus;
  /**
   *
   * @type {AffiliateLeadApplicationRemediation}
   * @memberof LeadApplication
   */
  remediation?: AffiliateLeadApplicationRemediation | null;
  /**
   *
   * @type {CurrencyCode}
   * @memberof LeadApplication
   */
  freeCreditCurrency?: CurrencyCode | null;
  /**
   *
   * @type {number}
   * @memberof LeadApplication
   */
  freeCreditAmount?: number | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const MatchType = {
  Any: 'Any',
  All: 'All',
  One: 'One'
} as const;

export type MatchType = typeof MatchType[keyof typeof MatchType];

/**
 *
 * @export
 * @interface OnboardingSettings
 */
export interface OnboardingSettings {
  /**
   *
   * @type {CountryCode}
   * @memberof OnboardingSettings
   */
  countryCode: CountryCode;
  /**
   *
   * @type {boolean}
   * @memberof OnboardingSettings
   */
  liveEnabled: boolean;
  /**
   *
   * @type {Array<IdDocumentType>}
   * @memberof OnboardingSettings
   */
  allowedIdDocumentTypes: Array<IdDocumentType>;
  /**
   *
   * @type {CurrencyCode}
   * @memberof OnboardingSettings
   */
  freeCreditCurrency?: CurrencyCode | null;
  /**
   *
   * @type {number}
   * @memberof OnboardingSettings
   */
  freeCreditAmount?: number | null;
  /**
   *
   * @type {ConfidentialLeadApplicationIncentives}
   * @memberof OnboardingSettings
   */
  incentives?: ConfidentialLeadApplicationIncentives | null;
}
/**
 *
 * @export
 * @enum {string}
 */

export const PlatformName = {
  Swan: 'Swan'
} as const;

export type PlatformName = typeof PlatformName[keyof typeof PlatformName];

/**
 *
 * @export
 * @interface Properties
 */
export interface Properties {
  /**
   *
   * @type {string}
   * @memberof Properties
   */
  legalEntity: string;
  /**
   *
   * @type {number}
   * @memberof Properties
   */
  legalEntityId: number;
  /**
   *
   * @type {string}
   * @memberof Properties
   */
  license: string;
  /**
   *
   * @type {string}
   * @memberof Properties
   */
  regulator: string;
  /**
   *
   * @type {number}
   * @memberof Properties
   */
  regulatorId: number;
}
/**
 *
 * @export
 * @interface RedirectionInfo
 */
export interface RedirectionInfo {
  /**
   *
   * @type {string}
   * @memberof RedirectionInfo
   */
  sectionId: string;
  /**
   *
   * @type {string}
   * @memberof RedirectionInfo
   */
  subFormId?: string | null;
}
/**
 *
 * @export
 * @interface ResetApplication
 */
export interface ResetApplication {
  /**
   *
   * @type {string}
   * @memberof ResetApplication
   */
  details: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const RuleType = {
  NotEquals: 'NotEquals'
} as const;

export type RuleType = typeof RuleType[keyof typeof RuleType];

/**
 *
 * @export
 * @interface SchemaInfo
 */
export interface SchemaInfo {
  /**
   *
   * @type {string}
   * @memberof SchemaInfo
   */
  schemaName?: string | null;
  /**
   *
   * @type {object}
   * @memberof SchemaInfo
   */
  schema?: object | null;
}
/**
 *
 * @export
 * @interface SchemaStructure
 */
export interface SchemaStructure {
  /**
   *
   * @type {Array<SectionDetails>}
   * @memberof SchemaStructure
   */
  sections?: Array<SectionDetails> | null;
}
/**
 *
 * @export
 * @interface Schemas
 */
export interface Schemas {
  /**
   *
   * @type {Array<SchemaInfo>}
   * @memberof Schemas
   */
  uiSchemas?: Array<SchemaInfo> | null;
  /**
   *
   * @type {Array<SchemaInfo>}
   * @memberof Schemas
   */
  dataSchemas?: Array<SchemaInfo> | null;
}
/**
 *
 * @export
 * @interface SectionDetails
 */
export interface SectionDetails {
  /**
   *
   * @type {string}
   * @memberof SectionDetails
   */
  id: string;
  /**
   *
   * @type {SectionType}
   * @memberof SectionDetails
   */
  type: SectionType;
}
/**
 *
 * @export
 * @enum {string}
 */

export const SectionType = {
  UiOnly: 'UiOnly',
  UiAndData: 'UiAndData',
  UiAndDataServerValidated: 'UiAndDataServerValidated'
} as const;

export type SectionType = typeof SectionType[keyof typeof SectionType];

/**
 *
 * @export
 * @interface TradingAccount
 */
export interface TradingAccount {
  /**
   *
   * @type {string}
   * @memberof TradingAccount
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof TradingAccount
   */
  platformInstance: string;
  /**
   *
   * @type {PlatformName}
   * @memberof TradingAccount
   */
  platformName: PlatformName;
}
/**
 *
 * @export
 * @interface UpdateAffiliateApplicationStatus
 */
export interface UpdateAffiliateApplicationStatus {
  /**
   *
   * @type {ConfidentialApplicationStatus}
   * @memberof UpdateAffiliateApplicationStatus
   */
  confidentialStatus?: ConfidentialApplicationStatus;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateAffiliateApplicationStatus
   */
  details?: Array<string> | null;
  /**
   *
   * @type {AffiliateLeadApplicationRemediation}
   * @memberof UpdateAffiliateApplicationStatus
   */
  remediation?: AffiliateLeadApplicationRemediation | null;
}
/**
 *
 * @export
 * @interface UpdateAffiliateIdVerification
 */
export interface UpdateAffiliateIdVerification {
  /**
   *
   * @type {string}
   * @memberof UpdateAffiliateIdVerification
   */
  transactionTime: string;
  /**
   *
   * @type {ConfidentialIdVerificationStatus}
   * @memberof UpdateAffiliateIdVerification
   */
  confidentialStatus: ConfidentialIdVerificationStatus;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof UpdateAffiliateIdVerification
   */
  confidentialDetails: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof UpdateAffiliateIdVerification
   */
  remediationInfo?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateAffiliateIdVerification
   */
  reference?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateAffiliateIdVerification
   */
  rejectionDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateAffiliateIdVerification
   */
  rejectionDescriptionExtra?: string | null;
  /**
   *
   * @type {IdDocumentType}
   * @memberof UpdateAffiliateIdVerification
   */
  documentType?: IdDocumentType | null;
  /**
   *
   * @type {ConfidentialIdVerificationRedirectionInfo}
   * @memberof UpdateAffiliateIdVerification
   */
  redirectionInfo?: ConfidentialIdVerificationRedirectionInfo | null;
}
/**
 *
 * @export
 * @interface UpdateApplicationStatus
 */
export interface UpdateApplicationStatus {
  /**
   *
   * @type {ConfidentialApplicationStatus}
   * @memberof UpdateApplicationStatus
   */
  confidentialStatus?: ConfidentialApplicationStatus;
  /**
   *
   * @type {Array<string>}
   * @memberof UpdateApplicationStatus
   */
  details?: Array<string> | null;
  /**
   *
   * @type {AffiliateLeadApplicationRemediation}
   * @memberof UpdateApplicationStatus
   */
  remediation?: AffiliateLeadApplicationRemediation | null;
  /**
   *
   * @type {Array<IncentiveType>}
   * @memberof UpdateApplicationStatus
   */
  incentivesApplied?: Array<IncentiveType> | null;
}
/**
 *
 * @export
 * @interface UpdateAssociatedEmailCustomers
 */
export interface UpdateAssociatedEmailCustomers {
  /**
   *
   * @type {Array<AssociatedCustomer>}
   * @memberof UpdateAssociatedEmailCustomers
   */
  associatedCustomers: Array<AssociatedCustomer>;
}
/**
 *
 * @export
 * @interface UpdateFreeCredit
 */
export interface UpdateFreeCredit {
  /**
   *
   * @type {boolean}
   * @memberof UpdateFreeCredit
   */
  enabled: boolean;
  /**
   *
   * @type {number}
   * @memberof UpdateFreeCredit
   */
  maxAppliedDaily: number;
  /**
   *
   * @type {CurrencyCode}
   * @memberof UpdateFreeCredit
   */
  currency: CurrencyCode;
  /**
   *
   * @type {number}
   * @memberof UpdateFreeCredit
   */
  amount: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof UpdateFreeCredit
   */
  conversions: { [key: string]: number };
}
/**
 *
 * @export
 * @interface UpdateIdVerification
 */
export interface UpdateIdVerification {
  /**
   *
   * @type {string}
   * @memberof UpdateIdVerification
   */
  transactionTime: string;
  /**
   *
   * @type {ConfidentialIdVerificationStatus}
   * @memberof UpdateIdVerification
   */
  confidentialStatus: ConfidentialIdVerificationStatus;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof UpdateIdVerification
   */
  confidentialDetails: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof UpdateIdVerification
   */
  remediationInfo?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateIdVerification
   */
  reference?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateIdVerification
   */
  rejectionDescription?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateIdVerification
   */
  rejectionDescriptionExtra?: string | null;
  /**
   *
   * @type {IdDocumentType}
   * @memberof UpdateIdVerification
   */
  documentType?: IdDocumentType | null;
  /**
   *
   * @type {ConfidentialIdVerificationRedirectionInfo}
   * @memberof UpdateIdVerification
   */
  redirectionInfo?: ConfidentialIdVerificationRedirectionInfo | null;
}
/**
 *
 * @export
 * @interface UpdateIncentives
 */
export interface UpdateIncentives {
  /**
   *
   * @type {UpdateIncentivesFreeCredit}
   * @memberof UpdateIncentives
   */
  freeCredit: UpdateIncentivesFreeCredit;
}
/**
 *
 * @export
 * @interface UpdateIncentivesFreeCredit
 */
export interface UpdateIncentivesFreeCredit {
  /**
   *
   * @type {boolean}
   * @memberof UpdateIncentivesFreeCredit
   */
  enabled: boolean;
  /**
   *
   * @type {number}
   * @memberof UpdateIncentivesFreeCredit
   */
  maxAppliedDaily: number;
  /**
   *
   * @type {CurrencyCode}
   * @memberof UpdateIncentivesFreeCredit
   */
  currency: CurrencyCode;
  /**
   *
   * @type {number}
   * @memberof UpdateIncentivesFreeCredit
   */
  amount: number;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof UpdateIncentivesFreeCredit
   */
  conversions: { [key: string]: number };
}
/**
 *
 * @export
 * @interface UpdateLead
 */
export interface UpdateLead {
  /**
   *
   * @type {string}
   * @memberof UpdateLead
   */
  emailAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateLead
   */
  firstName?: string | null;
  /**
   *
   * @type {CountryCode}
   * @memberof UpdateLead
   */
  countryCode?: CountryCode | null;
  /**
   *
   * @type {CurrencyCode}
   * @memberof UpdateLead
   */
  currencyCode?: CurrencyCode | null;
}
/**
 *
 * @export
 * @interface UpdateOnboardingSettings
 */
export interface UpdateOnboardingSettings {
  /**
   *
   * @type {boolean}
   * @memberof UpdateOnboardingSettings
   */
  liveEnabled: boolean;
  /**
   *
   * @type {Array<IdDocumentType>}
   * @memberof UpdateOnboardingSettings
   */
  allowedIdDocumentTypes: Array<IdDocumentType>;
  /**
   *
   * @type {UpdateOnboardingSettingsIncentives}
   * @memberof UpdateOnboardingSettings
   */
  incentives: UpdateOnboardingSettingsIncentives;
}
/**
 *
 * @export
 * @interface UpdateOnboardingSettingsIncentives
 */
export interface UpdateOnboardingSettingsIncentives {
  /**
   *
   * @type {UpdateIncentivesFreeCredit}
   * @memberof UpdateOnboardingSettingsIncentives
   */
  freeCredit: UpdateIncentivesFreeCredit;
}

/**
 * AffiliateLeadApi - axios parameter creator
 * @export
 */
export const AffiliateLeadApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadApplicationPost: async (
      authorization: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('affiliateLeadApplicationPost', 'authorization', authorization);
      const localVarPath = `/affiliate-lead/application`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['update:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [documentId]
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadDocumentDelete: async (
      authorization: string,
      documentId?: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('affiliateLeadDocumentDelete', 'authorization', authorization);
      const localVarPath = `/affiliate-lead/document`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['update:self'], configuration);

      if (documentId !== undefined) {
        localVarQueryParameter['documentId'] = documentId;
      }

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadDocumentPost: async (
      authorization: string,
      acceptLanguage?: string,
      file?: File,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('affiliateLeadDocumentPost', 'authorization', authorization);
      const localVarPath = `/affiliate-lead/document`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['update:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} documentId
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadDocumentsDocumentIdGet: async (
      documentId: string,
      authorization: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentId' is not null or undefined
      assertParamExists('affiliateLeadDocumentsDocumentIdGet', 'documentId', documentId);
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('affiliateLeadDocumentsDocumentIdGet', 'authorization', authorization);
      const localVarPath = `/affiliate-lead/documents/{documentId}`.replace(
        `{${'documentId'}}`,
        encodeURIComponent(String(documentId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['read:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadDocumentsGet: async (
      authorization: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('affiliateLeadDocumentsGet', 'authorization', authorization);
      const localVarPath = `/affiliate-lead/documents`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['read:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadGet: async (
      authorization: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('affiliateLeadGet', 'authorization', authorization);
      const localVarPath = `/affiliate-lead`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['read:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {CreateAffiliateIdVerification} [createAffiliateIdVerification]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadIdVerificationPost: async (
      authorization: string,
      acceptLanguage?: string,
      createAffiliateIdVerification?: CreateAffiliateIdVerification,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('affiliateLeadIdVerificationPost', 'authorization', authorization);
      const localVarPath = `/affiliate-lead/id-verification`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['update:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAffiliateIdVerification,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} kycSectionId
     * @param {string} [acceptLanguage]
     * @param {{ [key: string]: any; }} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadKycSectionsKycSectionIdPut: async (
      kycSectionId: string,
      acceptLanguage?: string,
      requestBody?: { [key: string]: any },
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'kycSectionId' is not null or undefined
      assertParamExists('affiliateLeadKycSectionsKycSectionIdPut', 'kycSectionId', kycSectionId);
      const localVarPath = `/affiliate-lead/kyc-sections/{kycSectionId}`.replace(
        `{${'kycSectionId'}}`,
        encodeURIComponent(String(kycSectionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['update:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        requestBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {CreateAffiliateLead} [createAffiliateLead]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadPost: async (
      authorization: string,
      acceptLanguage?: string,
      createAffiliateLead?: CreateAffiliateLead,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('affiliateLeadPost', 'authorization', authorization);
      const localVarPath = `/affiliate-lead`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['create:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createAffiliateLead,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadSchemasGet: async (
      authorization: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('affiliateLeadSchemasGet', 'authorization', authorization);
      const localVarPath = `/affiliate-lead/schemas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['read:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * AffiliateLeadApi - functional programming interface
 * @export
 */
export const AffiliateLeadApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = AffiliateLeadApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async affiliateLeadApplicationPost(
      authorization: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateLeadApplicationPost(
        authorization,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [documentId]
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async affiliateLeadDocumentDelete(
      authorization: string,
      documentId?: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateLeadDocumentDelete(
        authorization,
        documentId,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async affiliateLeadDocumentPost(
      authorization: string,
      acceptLanguage?: string,
      file?: File,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateLeadDocumentPost(
        authorization,
        acceptLanguage,
        file,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} documentId
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async affiliateLeadDocumentsDocumentIdGet(
      documentId: string,
      authorization: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateLeadDocumentsDocumentIdGet(
        documentId,
        authorization,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async affiliateLeadDocumentsGet(
      authorization: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateLeadDocumentsGet(
        authorization,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async affiliateLeadGet(
      authorization: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffiliateLead>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateLeadGet(
        authorization,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {CreateAffiliateIdVerification} [createAffiliateIdVerification]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async affiliateLeadIdVerificationPost(
      authorization: string,
      acceptLanguage?: string,
      createAffiliateIdVerification?: CreateAffiliateIdVerification,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateLeadIdVerificationPost(
        authorization,
        acceptLanguage,
        createAffiliateIdVerification,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} kycSectionId
     * @param {string} [acceptLanguage]
     * @param {{ [key: string]: any; }} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async affiliateLeadKycSectionsKycSectionIdPut(
      kycSectionId: string,
      acceptLanguage?: string,
      requestBody?: { [key: string]: any },
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateLeadKycSectionsKycSectionIdPut(
        kycSectionId,
        acceptLanguage,
        requestBody,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {CreateAffiliateLead} [createAffiliateLead]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async affiliateLeadPost(
      authorization: string,
      acceptLanguage?: string,
      createAffiliateLead?: CreateAffiliateLead,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateLeadPost(
        authorization,
        acceptLanguage,
        createAffiliateLead,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async affiliateLeadSchemasGet(
      authorization: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Schemas>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateLeadSchemasGet(
        authorization,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * AffiliateLeadApi - factory interface
 * @export
 */
export const AffiliateLeadApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AffiliateLeadApiFp(configuration);
  return {
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadApplicationPost(
      authorization: string,
      acceptLanguage?: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .affiliateLeadApplicationPost(authorization, acceptLanguage, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [documentId]
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadDocumentDelete(
      authorization: string,
      documentId?: string,
      acceptLanguage?: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .affiliateLeadDocumentDelete(authorization, documentId, acceptLanguage, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadDocumentPost(
      authorization: string,
      acceptLanguage?: string,
      file?: File,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .affiliateLeadDocumentPost(authorization, acceptLanguage, file, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} documentId
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadDocumentsDocumentIdGet(
      documentId: string,
      authorization: string,
      acceptLanguage?: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .affiliateLeadDocumentsDocumentIdGet(documentId, authorization, acceptLanguage, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadDocumentsGet(
      authorization: string,
      acceptLanguage?: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .affiliateLeadDocumentsGet(authorization, acceptLanguage, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadGet(
      authorization: string,
      acceptLanguage?: string,
      options?: any
    ): AxiosPromise<AffiliateLead> {
      return localVarFp
        .affiliateLeadGet(authorization, acceptLanguage, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {CreateAffiliateIdVerification} [createAffiliateIdVerification]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadIdVerificationPost(
      authorization: string,
      acceptLanguage?: string,
      createAffiliateIdVerification?: CreateAffiliateIdVerification,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .affiliateLeadIdVerificationPost(
          authorization,
          acceptLanguage,
          createAffiliateIdVerification,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} kycSectionId
     * @param {string} [acceptLanguage]
     * @param {{ [key: string]: any; }} [requestBody]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadKycSectionsKycSectionIdPut(
      kycSectionId: string,
      acceptLanguage?: string,
      requestBody?: { [key: string]: any },
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .affiliateLeadKycSectionsKycSectionIdPut(kycSectionId, acceptLanguage, requestBody, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {CreateAffiliateLead} [createAffiliateLead]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadPost(
      authorization: string,
      acceptLanguage?: string,
      createAffiliateLead?: CreateAffiliateLead,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .affiliateLeadPost(authorization, acceptLanguage, createAffiliateLead, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    affiliateLeadSchemasGet(
      authorization: string,
      acceptLanguage?: string,
      options?: any
    ): AxiosPromise<Schemas> {
      return localVarFp
        .affiliateLeadSchemasGet(authorization, acceptLanguage, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * AffiliateLeadApi - object-oriented interface
 * @export
 * @class AffiliateLeadApi
 * @extends {BaseAPI}
 */
export class AffiliateLeadApi extends BaseAPI {
  /**
   *
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AffiliateLeadApi
   */
  public affiliateLeadApplicationPost(
    authorization: string,
    acceptLanguage?: string,
    options?: AxiosRequestConfig
  ) {
    return AffiliateLeadApiFp(this.configuration)
      .affiliateLeadApplicationPost(authorization, acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [documentId]
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AffiliateLeadApi
   */
  public affiliateLeadDocumentDelete(
    authorization: string,
    documentId?: string,
    acceptLanguage?: string,
    options?: AxiosRequestConfig
  ) {
    return AffiliateLeadApiFp(this.configuration)
      .affiliateLeadDocumentDelete(authorization, documentId, acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AffiliateLeadApi
   */
  public affiliateLeadDocumentPost(
    authorization: string,
    acceptLanguage?: string,
    file?: File,
    options?: AxiosRequestConfig
  ) {
    return AffiliateLeadApiFp(this.configuration)
      .affiliateLeadDocumentPost(authorization, acceptLanguage, file, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} documentId
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AffiliateLeadApi
   */
  public affiliateLeadDocumentsDocumentIdGet(
    documentId: string,
    authorization: string,
    acceptLanguage?: string,
    options?: AxiosRequestConfig
  ) {
    return AffiliateLeadApiFp(this.configuration)
      .affiliateLeadDocumentsDocumentIdGet(documentId, authorization, acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AffiliateLeadApi
   */
  public affiliateLeadDocumentsGet(
    authorization: string,
    acceptLanguage?: string,
    options?: AxiosRequestConfig
  ) {
    return AffiliateLeadApiFp(this.configuration)
      .affiliateLeadDocumentsGet(authorization, acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AffiliateLeadApi
   */
  public affiliateLeadGet(
    authorization: string,
    acceptLanguage?: string,
    options?: AxiosRequestConfig
  ) {
    return AffiliateLeadApiFp(this.configuration)
      .affiliateLeadGet(authorization, acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {CreateAffiliateIdVerification} [createAffiliateIdVerification]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AffiliateLeadApi
   */
  public affiliateLeadIdVerificationPost(
    authorization: string,
    acceptLanguage?: string,
    createAffiliateIdVerification?: CreateAffiliateIdVerification,
    options?: AxiosRequestConfig
  ) {
    return AffiliateLeadApiFp(this.configuration)
      .affiliateLeadIdVerificationPost(
        authorization,
        acceptLanguage,
        createAffiliateIdVerification,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} kycSectionId
   * @param {string} [acceptLanguage]
   * @param {{ [key: string]: any; }} [requestBody]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AffiliateLeadApi
   */
  public affiliateLeadKycSectionsKycSectionIdPut(
    kycSectionId: string,
    acceptLanguage?: string,
    requestBody?: { [key: string]: any },
    options?: AxiosRequestConfig
  ) {
    return AffiliateLeadApiFp(this.configuration)
      .affiliateLeadKycSectionsKycSectionIdPut(kycSectionId, acceptLanguage, requestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {CreateAffiliateLead} [createAffiliateLead]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AffiliateLeadApi
   */
  public affiliateLeadPost(
    authorization: string,
    acceptLanguage?: string,
    createAffiliateLead?: CreateAffiliateLead,
    options?: AxiosRequestConfig
  ) {
    return AffiliateLeadApiFp(this.configuration)
      .affiliateLeadPost(authorization, acceptLanguage, createAffiliateLead, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AffiliateLeadApi
   */
  public affiliateLeadSchemasGet(
    authorization: string,
    acceptLanguage?: string,
    options?: AxiosRequestConfig
  ) {
    return AffiliateLeadApiFp(this.configuration)
      .affiliateLeadSchemasGet(authorization, acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * CountriesApi - axios parameter creator
 * @export
 */
export const CountriesApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCountryCodeAffiliateStructureGet: async (
      countryCode: CountryCode,
      authorization: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'countryCode' is not null or undefined
      assertParamExists('countriesCountryCodeAffiliateStructureGet', 'countryCode', countryCode);
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists(
        'countriesCountryCodeAffiliateStructureGet',
        'authorization',
        authorization
      );
      const localVarPath = `/countries/{countryCode}/affiliate-structure`.replace(
        `{${'countryCode'}}`,
        encodeURIComponent(String(countryCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'oauth2',
        ['read:client.country_schemas'],
        configuration
      );

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCountryCodeBusinessRulesGet: async (
      countryCode: CountryCode,
      authorization: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'countryCode' is not null or undefined
      assertParamExists('countriesCountryCodeBusinessRulesGet', 'countryCode', countryCode);
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('countriesCountryCodeBusinessRulesGet', 'authorization', authorization);
      const localVarPath = `/countries/{countryCode}/business-rules`.replace(
        `{${'countryCode'}}`,
        encodeURIComponent(String(countryCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'oauth2',
        ['read:client.country_business_rules'],
        configuration
      );

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCountryCodePropertiesGet: async (
      countryCode: CountryCode,
      authorization: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'countryCode' is not null or undefined
      assertParamExists('countriesCountryCodePropertiesGet', 'countryCode', countryCode);
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('countriesCountryCodePropertiesGet', 'authorization', authorization);
      const localVarPath = `/countries/{countryCode}/properties`.replace(
        `{${'countryCode'}}`,
        encodeURIComponent(String(countryCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'oauth2',
        ['read:client.country_properties'],
        configuration
      );

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} sectionId
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCountryCodeSchemasSectionIdGet: async (
      countryCode: CountryCode,
      sectionId: string,
      authorization: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'countryCode' is not null or undefined
      assertParamExists('countriesCountryCodeSchemasSectionIdGet', 'countryCode', countryCode);
      // verify required parameter 'sectionId' is not null or undefined
      assertParamExists('countriesCountryCodeSchemasSectionIdGet', 'sectionId', sectionId);
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('countriesCountryCodeSchemasSectionIdGet', 'authorization', authorization);
      const localVarPath = `/countries/{countryCode}/schemas/{sectionId}`
        .replace(`{${'countryCode'}}`, encodeURIComponent(String(countryCode)))
        .replace(`{${'sectionId'}}`, encodeURIComponent(String(sectionId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'oauth2',
        ['read:client.country_schemas'],
        configuration
      );

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCountryCodeStructureGet: async (
      countryCode: CountryCode,
      authorization: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'countryCode' is not null or undefined
      assertParamExists('countriesCountryCodeStructureGet', 'countryCode', countryCode);
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('countriesCountryCodeStructureGet', 'authorization', authorization);
      const localVarPath = `/countries/{countryCode}/structure`.replace(
        `{${'countryCode'}}`,
        encodeURIComponent(String(countryCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'oauth2',
        ['read:client.country_schemas'],
        configuration
      );

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesGet: async (
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/countries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(
        localVarHeaderParameter,
        'oauth2',
        [
          'create:client_demo_accounts',
          'create:client_leads',
          'create:self',
          'read:client.country_business_rules',
          'read:client.country_properties',
          'read:client.country_schemas',
          'read:client.language_tokens',
          'read:client_leads',
          'read:self',
          'read:settings',
          'update:client.applications',
          'update:client.countries',
          'update.client.languages',
          'update:client_leads',
          'update:self',
          'update:client_settings',
          'update:client.lead_associated_customers'
        ],
        configuration
      );

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * CountriesApi - functional programming interface
 * @export
 */
export const CountriesApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = CountriesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countriesCountryCodeAffiliateStructureGet(
      countryCode: CountryCode,
      authorization: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchemaStructure>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countriesCountryCodeAffiliateStructureGet(
        countryCode,
        authorization,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countriesCountryCodeBusinessRulesGet(
      countryCode: CountryCode,
      authorization: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusinessRules>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countriesCountryCodeBusinessRulesGet(
        countryCode,
        authorization,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countriesCountryCodePropertiesGet(
      countryCode: CountryCode,
      authorization: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Properties>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countriesCountryCodePropertiesGet(
        countryCode,
        authorization,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} sectionId
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countriesCountryCodeSchemasSectionIdGet(
      countryCode: CountryCode,
      sectionId: string,
      authorization: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countriesCountryCodeSchemasSectionIdGet(
        countryCode,
        sectionId,
        authorization,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countriesCountryCodeStructureGet(
      countryCode: CountryCode,
      authorization: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SchemaStructure>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countriesCountryCodeStructureGet(
        countryCode,
        authorization,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async countriesGet(
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.countriesGet(
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * CountriesApi - factory interface
 * @export
 */
export const CountriesApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CountriesApiFp(configuration);
  return {
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCountryCodeAffiliateStructureGet(
      countryCode: CountryCode,
      authorization: string,
      acceptLanguage?: string,
      options?: any
    ): AxiosPromise<SchemaStructure> {
      return localVarFp
        .countriesCountryCodeAffiliateStructureGet(
          countryCode,
          authorization,
          acceptLanguage,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCountryCodeBusinessRulesGet(
      countryCode: CountryCode,
      authorization: string,
      acceptLanguage?: string,
      options?: any
    ): AxiosPromise<BusinessRules> {
      return localVarFp
        .countriesCountryCodeBusinessRulesGet(countryCode, authorization, acceptLanguage, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCountryCodePropertiesGet(
      countryCode: CountryCode,
      authorization: string,
      acceptLanguage?: string,
      options?: any
    ): AxiosPromise<Properties> {
      return localVarFp
        .countriesCountryCodePropertiesGet(countryCode, authorization, acceptLanguage, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} sectionId
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCountryCodeSchemasSectionIdGet(
      countryCode: CountryCode,
      sectionId: string,
      authorization: string,
      acceptLanguage?: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .countriesCountryCodeSchemasSectionIdGet(
          countryCode,
          sectionId,
          authorization,
          acceptLanguage,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesCountryCodeStructureGet(
      countryCode: CountryCode,
      authorization: string,
      acceptLanguage?: string,
      options?: any
    ): AxiosPromise<SchemaStructure> {
      return localVarFp
        .countriesCountryCodeStructureGet(countryCode, authorization, acceptLanguage, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    countriesGet(acceptLanguage?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .countriesGet(acceptLanguage, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * CountriesApi - object-oriented interface
 * @export
 * @class CountriesApi
 * @extends {BaseAPI}
 */
export class CountriesApi extends BaseAPI {
  /**
   *
   * @param {CountryCode} countryCode
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CountriesApi
   */
  public countriesCountryCodeAffiliateStructureGet(
    countryCode: CountryCode,
    authorization: string,
    acceptLanguage?: string,
    options?: AxiosRequestConfig
  ) {
    return CountriesApiFp(this.configuration)
      .countriesCountryCodeAffiliateStructureGet(
        countryCode,
        authorization,
        acceptLanguage,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CountryCode} countryCode
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CountriesApi
   */
  public countriesCountryCodeBusinessRulesGet(
    countryCode: CountryCode,
    authorization: string,
    acceptLanguage?: string,
    options?: AxiosRequestConfig
  ) {
    return CountriesApiFp(this.configuration)
      .countriesCountryCodeBusinessRulesGet(countryCode, authorization, acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CountryCode} countryCode
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CountriesApi
   */
  public countriesCountryCodePropertiesGet(
    countryCode: CountryCode,
    authorization: string,
    acceptLanguage?: string,
    options?: AxiosRequestConfig
  ) {
    return CountriesApiFp(this.configuration)
      .countriesCountryCodePropertiesGet(countryCode, authorization, acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CountryCode} countryCode
   * @param {string} sectionId
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CountriesApi
   */
  public countriesCountryCodeSchemasSectionIdGet(
    countryCode: CountryCode,
    sectionId: string,
    authorization: string,
    acceptLanguage?: string,
    options?: AxiosRequestConfig
  ) {
    return CountriesApiFp(this.configuration)
      .countriesCountryCodeSchemasSectionIdGet(
        countryCode,
        sectionId,
        authorization,
        acceptLanguage,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CountryCode} countryCode
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CountriesApi
   */
  public countriesCountryCodeStructureGet(
    countryCode: CountryCode,
    authorization: string,
    acceptLanguage?: string,
    options?: AxiosRequestConfig
  ) {
    return CountriesApiFp(this.configuration)
      .countriesCountryCodeStructureGet(countryCode, authorization, acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CountriesApi
   */
  public countriesGet(acceptLanguage?: string, options?: AxiosRequestConfig) {
    return CountriesApiFp(this.configuration)
      .countriesGet(acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * LeadApi - axios parameter creator
 * @export
 */
export const LeadApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leadApplicationPost: async (
      authorization: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('leadApplicationPost', 'authorization', authorization);
      const localVarPath = `/lead/application`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['update:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leadCustomerTcAcceptancePost: async (
      authorization: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('leadCustomerTcAcceptancePost', 'authorization', authorization);
      const localVarPath = `/lead/customer-tc-acceptance`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['update:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leadCustomerTcRequirementsGet: async (
      authorization: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('leadCustomerTcRequirementsGet', 'authorization', authorization);
      const localVarPath = `/lead/customer-tc-requirements`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['read:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leadGet: async (
      authorization: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('leadGet', 'authorization', authorization);
      const localVarPath = `/lead`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['read:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {CreateIdVerification} [createIdVerification]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leadIdVerificationPost: async (
      authorization: string,
      acceptLanguage?: string,
      createIdVerification?: CreateIdVerification,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('leadIdVerificationPost', 'authorization', authorization);
      const localVarPath = `/lead/id-verification`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['update:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createIdVerification,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {CreateLead} [createLead]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leadPost: async (
      authorization: string,
      acceptLanguage?: string,
      createLead?: CreateLead,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('leadPost', 'authorization', authorization);
      const localVarPath = `/lead`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['create:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createLead,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {UpdateLead} [updateLead]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leadPut: async (
      authorization: string,
      acceptLanguage?: string,
      updateLead?: UpdateLead,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('leadPut', 'authorization', authorization);
      const localVarPath = `/lead`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['update:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateLead,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leadSchemasGet: async (
      authorization: string,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authorization' is not null or undefined
      assertParamExists('leadSchemasGet', 'authorization', authorization);
      const localVarPath = `/lead/schemas`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['read:self'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      if (authorization != null) {
        localVarHeaderParameter['Authorization'] = String(authorization);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * LeadApi - functional programming interface
 * @export
 */
export const LeadApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = LeadApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async leadApplicationPost(
      authorization: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.leadApplicationPost(
        authorization,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async leadCustomerTcAcceptancePost(
      authorization: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.leadCustomerTcAcceptancePost(
        authorization,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async leadCustomerTcRequirementsGet(
      authorization: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerTermsAndConditions>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.leadCustomerTcRequirementsGet(
        authorization,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async leadGet(
      authorization: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lead>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.leadGet(
        authorization,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {CreateIdVerification} [createIdVerification]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async leadIdVerificationPost(
      authorization: string,
      acceptLanguage?: string,
      createIdVerification?: CreateIdVerification,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.leadIdVerificationPost(
        authorization,
        acceptLanguage,
        createIdVerification,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {CreateLead} [createLead]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async leadPost(
      authorization: string,
      acceptLanguage?: string,
      createLead?: CreateLead,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.leadPost(
        authorization,
        acceptLanguage,
        createLead,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {UpdateLead} [updateLead]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async leadPut(
      authorization: string,
      acceptLanguage?: string,
      updateLead?: UpdateLead,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.leadPut(
        authorization,
        acceptLanguage,
        updateLead,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async leadSchemasGet(
      authorization: string,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Schemas>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.leadSchemasGet(
        authorization,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * LeadApi - factory interface
 * @export
 */
export const LeadApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = LeadApiFp(configuration);
  return {
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leadApplicationPost(
      authorization: string,
      acceptLanguage?: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .leadApplicationPost(authorization, acceptLanguage, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leadCustomerTcAcceptancePost(
      authorization: string,
      acceptLanguage?: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .leadCustomerTcAcceptancePost(authorization, acceptLanguage, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leadCustomerTcRequirementsGet(
      authorization: string,
      acceptLanguage?: string,
      options?: any
    ): AxiosPromise<CustomerTermsAndConditions> {
      return localVarFp
        .leadCustomerTcRequirementsGet(authorization, acceptLanguage, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leadGet(authorization: string, acceptLanguage?: string, options?: any): AxiosPromise<Lead> {
      return localVarFp
        .leadGet(authorization, acceptLanguage, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {CreateIdVerification} [createIdVerification]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leadIdVerificationPost(
      authorization: string,
      acceptLanguage?: string,
      createIdVerification?: CreateIdVerification,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .leadIdVerificationPost(authorization, acceptLanguage, createIdVerification, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {CreateLead} [createLead]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leadPost(
      authorization: string,
      acceptLanguage?: string,
      createLead?: CreateLead,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .leadPost(authorization, acceptLanguage, createLead, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {UpdateLead} [updateLead]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leadPut(
      authorization: string,
      acceptLanguage?: string,
      updateLead?: UpdateLead,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .leadPut(authorization, acceptLanguage, updateLead, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leadSchemasGet(
      authorization: string,
      acceptLanguage?: string,
      options?: any
    ): AxiosPromise<Schemas> {
      return localVarFp
        .leadSchemasGet(authorization, acceptLanguage, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * LeadApi - object-oriented interface
 * @export
 * @class LeadApi
 * @extends {BaseAPI}
 */
export class LeadApi extends BaseAPI {
  /**
   *
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LeadApi
   */
  public leadApplicationPost(
    authorization: string,
    acceptLanguage?: string,
    options?: AxiosRequestConfig
  ) {
    return LeadApiFp(this.configuration)
      .leadApplicationPost(authorization, acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LeadApi
   */
  public leadCustomerTcAcceptancePost(
    authorization: string,
    acceptLanguage?: string,
    options?: AxiosRequestConfig
  ) {
    return LeadApiFp(this.configuration)
      .leadCustomerTcAcceptancePost(authorization, acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LeadApi
   */
  public leadCustomerTcRequirementsGet(
    authorization: string,
    acceptLanguage?: string,
    options?: AxiosRequestConfig
  ) {
    return LeadApiFp(this.configuration)
      .leadCustomerTcRequirementsGet(authorization, acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LeadApi
   */
  public leadGet(authorization: string, acceptLanguage?: string, options?: AxiosRequestConfig) {
    return LeadApiFp(this.configuration)
      .leadGet(authorization, acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {CreateIdVerification} [createIdVerification]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LeadApi
   */
  public leadIdVerificationPost(
    authorization: string,
    acceptLanguage?: string,
    createIdVerification?: CreateIdVerification,
    options?: AxiosRequestConfig
  ) {
    return LeadApiFp(this.configuration)
      .leadIdVerificationPost(authorization, acceptLanguage, createIdVerification, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {CreateLead} [createLead]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LeadApi
   */
  public leadPost(
    authorization: string,
    acceptLanguage?: string,
    createLead?: CreateLead,
    options?: AxiosRequestConfig
  ) {
    return LeadApiFp(this.configuration)
      .leadPost(authorization, acceptLanguage, createLead, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {UpdateLead} [updateLead]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LeadApi
   */
  public leadPut(
    authorization: string,
    acceptLanguage?: string,
    updateLead?: UpdateLead,
    options?: AxiosRequestConfig
  ) {
    return LeadApiFp(this.configuration)
      .leadPut(authorization, acceptLanguage, updateLead, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof LeadApi
   */
  public leadSchemasGet(
    authorization: string,
    acceptLanguage?: string,
    options?: AxiosRequestConfig
  ) {
    return LeadApiFp(this.configuration)
      .leadSchemasGet(authorization, acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsCountriesGet: async (
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/settings/countries`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['read:settings'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsCountryCodeGet: async (
      countryCode: CountryCode,
      acceptLanguage?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'countryCode' is not null or undefined
      assertParamExists('settingsCountryCodeGet', 'countryCode', countryCode);
      const localVarPath = `/settings/{countryCode}`.replace(
        `{${'countryCode'}}`,
        encodeURIComponent(String(countryCode))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', [], configuration);

      // authentication oauth2 required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'oauth2', ['read:settings'], configuration);

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settingsCountriesGet(
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Country>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.settingsCountriesGet(
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async settingsCountryCodeGet(
      countryCode: CountryCode,
      acceptLanguage?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OnboardingSettings>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.settingsCountryCodeGet(
        countryCode,
        acceptLanguage,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    }
  };
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SettingsApiFp(configuration);
  return {
    /**
     *
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsCountriesGet(acceptLanguage?: string, options?: any): AxiosPromise<Array<Country>> {
      return localVarFp
        .settingsCountriesGet(acceptLanguage, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CountryCode} countryCode
     * @param {string} [acceptLanguage]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    settingsCountryCodeGet(
      countryCode: CountryCode,
      acceptLanguage?: string,
      options?: any
    ): AxiosPromise<OnboardingSettings> {
      return localVarFp
        .settingsCountryCodeGet(countryCode, acceptLanguage, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
  /**
   *
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public settingsCountriesGet(acceptLanguage?: string, options?: AxiosRequestConfig) {
    return SettingsApiFp(this.configuration)
      .settingsCountriesGet(acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CountryCode} countryCode
   * @param {string} [acceptLanguage]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SettingsApi
   */
  public settingsCountryCodeGet(
    countryCode: CountryCode,
    acceptLanguage?: string,
    options?: AxiosRequestConfig
  ) {
    return SettingsApiFp(this.configuration)
      .settingsCountryCodeGet(countryCode, acceptLanguage, options)
      .then(request => request(this.axios, this.basePath));
  }
}
