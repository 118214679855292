import axios from 'axios';
import { AuthResponse, loadSession } from 'src/auth';
import { jumioServiceConfig } from 'src/config';
import { isTokenExpired } from 'src/utils/token';
import { VerificationApi } from '../resources/api-client/jumio';

export const jumioSvcAxiosClient = axios.create({ baseURL: jumioServiceConfig.apiUrl });

const JumioSvc = new VerificationApi(null, jumioServiceConfig.apiUrl, jumioSvcAxiosClient);

export interface JumioSvc {
  client: VerificationApi;
}

const JumioSvcClient = {
  client: JumioSvc,
} as JumioSvc;

let token: string;
const getJumioSvcClient = async (): Promise<JumioSvc> => {
  if (token === null || isTokenExpired(token)) {
    loadSession((authResponse: AuthResponse) => {
      const { success, result } = authResponse;
      token = result.accessToken;
      if (success) {
        jumioSvcAxiosClient.interceptors.request.use((config: any) => {
          config.headers.Authorization = `Bearer ${token}`;
          return config;
        });
      }
    });
  } else {
    jumioSvcAxiosClient.interceptors.request.use((config: any) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  }
  return JumioSvcClient;
};

export const jumioSvcSetAccessToken = (_token: string) => {
  token = _token;
};

export default getJumioSvcClient;
