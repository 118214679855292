import React from 'react';
import clsx from 'clsx';

import styles from './RadioButton.module.scss';

interface RadioButtonProps {
  /**
   * Indicates whether the radio button is selected
   */
  checked?: boolean;
  /**
   * Scss class that apply to wrapper
   */
  className?: string;
  /**
   * Apply inactive visual appearance to the radio button
   */
  disabled?: boolean;
  /**
   * Unique identifier that apply to input directly
   */
  id?: string;
  /**
   * Name attribute of the input element. Required for grouping radio inputs
   */
  name?: string;
  /**
   * RadioButton main text
   */
  label?: React.ReactNode;
  /**
   * Scss class that apply to label
   */
  labelClass?: string;
  /**
   * Change handler that apply to input directly
   */
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  /**
   * Click handler that apply to span that represent radio button
   */
  onClick?: (event: React.MouseEvent<HTMLElement>, value: string) => void;
  /**
   * Keyboard handler that apply to span that represent radio button
   */
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>, value: string) => void;
  /**
   * Scss class that apply to span that represent radio button
   */
  radioClass?: string;
  /**
   * A unique value. Used during form submission and to identify which radio button in a group is selected
   */
  value: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  checked = false,
  className,
  disabled = false,
  id,
  label,
  labelClass,
  name = '',
  onChange = () => false,
  onClick = () => false,
  onKeyDown = () => false,
  radioClass,
  value = '',
  ...otherProps
}: RadioButtonProps) => {
  const handleClick = (event: React.MouseEvent<HTMLElement>): void =>
    !disabled && onClick(event, value);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>): void =>
    !disabled && onKeyDown(event, value);

  return (
    <div className={clsx(styles.Wrapper, className)}>
      <input
        checked={checked}
        className={styles.Input}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        type='radio'
        value={value}
        {...otherProps}
      />
      <span
        aria-checked={checked}
        className={clsx(
          styles.Radio,
          {
            [styles.RadioChecked]: checked,
            [styles.RadioDisabled]: disabled,
          },
          radioClass,
        )}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        role='radio'
        tabIndex={disabled ? -1 : 0}>
        <span
          className={clsx(styles.RadioFill, {
            [styles.CheckedDisabled]: checked && disabled,
          })}
        />
      </span>
      {label && (
        <label
          className={clsx(
            styles.Label,
            {
              [styles.LabelDisabled]: disabled,
            },
            labelClass,
          )}
          htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

export default RadioButton;
