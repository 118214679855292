import React from 'react';
import type { FC } from 'react';
import { Button } from 'src/components/kit';
import { useIntl } from 'react-intl';

interface BackButtonProps {
  onClick?: () => void;
}
const BackButton: FC<BackButtonProps> = ({ onClick }) => {
  const intl = useIntl();
  return (
    <div className='page-back-button'>
      <Button
        label={intl.formatMessage({
          id: 'forgotPassword.back',
          defaultMessage: 'back',
        })}
        data-testid='btnBack'
        variant='primary'
        onClick={onClick}
      />
    </div>
  );
};
export default BackButton;
