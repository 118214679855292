import { affiliateStructure } from 'src/schemas/affiliate-structure';

const getSectionIdOfRecentPage = (currentPage: string) => {
  const indexOfCurrentPage = affiliateStructure.sections.map((d) => d?.id).indexOf(currentPage);
  const indexOfPageToGoBack = indexOfCurrentPage - 1;

  const pageId = affiliateStructure.sections[indexOfPageToGoBack]?.id;

  return pageId;
};

export default getSectionIdOfRecentPage;
