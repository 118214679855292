/* eslint-disable react-hooks/rules-of-hooks */
import { ControlElement, JsonSchema, optionIs, rankWith, UISchemaElement } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import React, { useEffect, useState } from 'react';
import { RadioButton, Typography } from 'src/components/kit';

export type FormRadioControlElement = ControlElement & {
  displayValues: Record<string, string>;
};

interface RadioControlProps {
  uischema: UISchemaElement | any;
  handleChange: (path: string, value: string) => void;
  path: string;
  data: string;
  schema: JsonSchema | any;
}

// eslint-disable-next-line no-multi-assign
const RadioControlRenderer = (props: RadioControlProps): JSX.Element => {
  const { uischema, path, data, handleChange, schema } = props;
  const { displayValues } = uischema as FormRadioControlElement;
  const label = uischema.label as string;
  const [activeSelection, setActiveSelection] = useState(data);

  useEffect(() => {
    if (data) {
      setActiveSelection(data && data);
    }
  }, [data]);

  return (
    <div className='wide-control-container'>
      <Typography variant='body2'>{label}</Typography>
      <div className='radio-button-group'>
        {schema.enum?.map((value: any) => (
          <RadioButton
            key={`radio-btn-${value}`}
            checked={activeSelection === value}
            id={`radiobtn${value}`}
            label={displayValues[value] ?? value}
            name={`radio-btn-${value}`}
            onClick={() => {
              setActiveSelection(value);
              handleChange(path, value);
            }}
            value={value}
          />
        ))}
      </div>
    </div>
  );
};

export const RadioControlTester = rankWith(106, optionIs('format', 'radio'));
export default withJsonFormsControlProps(RadioControlRenderer);
