/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { and, GroupLayout, rankWith, RendererProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Typography } from 'src/components/kit';
import { formUITypeIs } from '../util';

const SummaryTextListRenderer = (props: RendererProps): JSX.Element => {
  const { uischema, data, visible } = props;
  const layout = uischema as GroupLayout;
  const { label } = layout;

  if (!visible) {
    return null;
  }

  return (
    <div className='summary-control-container'>
      <div className='row'>
        {label && (
          <div className='title-container'>
            <Typography variant='title1'>{label}</Typography>
          </div>
        )}
        {data &&
          data?.map((display) => (
            <div className='row'>
              <Typography>{display}</Typography>
            </div>
          ))}
      </div>
    </div>
  );
};

export const SummaryTextListTester = rankWith(10, and(formUITypeIs('SummaryTextList')));
export default withJsonFormsControlProps(SummaryTextListRenderer);
