import { UISchema } from 'src/slices/onboarding';
import augmentSummarySchema from './augmentSummarySchema';
import filterSummarySchema from './filterSummarySchema';
import transformToSummarySchema from './transformToSummarySchema';

type UIElement = {
  type: string;
};
type SubForm = {
  type: string;
  label: string;
  elements: Array<{
    type: string;
    label: string;
    id: string;
    elements: Array<UIElement>;
  }>;
};
export function createSummarySection(baseSummarySchema: any, uiSchemas: UISchema[]): UISchema {
  const summaryBlocks: any = uiSchemas.map(({ schema, schemaName }) => {
    const transformed = transformToSummarySchema(schema, schemaName);
    const filtered = filterSummarySchema(transformed);
    const augmented = augmentSummarySchema(filtered, schema);
    return augmented;
  });

  return {
    ...baseSummarySchema,
    schema: {
      ...baseSummarySchema.schema,
      elements: baseSummarySchema.schema?.elements.map((subForm: SubForm) => ({
        ...subForm,
        elements: subForm.elements.reduce((summaryElements, currentElement) => {
          if (currentElement.type === 'Summary') {
            return summaryElements.concat(summaryBlocks);
          }
          return summaryElements.concat(currentElement);
        }, [] as UIElement[]),
      })),
    },
  };
}
