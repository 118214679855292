/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback } from 'react';
import { and, ControlElement, GroupLayout, rankWith, RendererProps } from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { Typography } from 'src/components/kit';
import { formUITypeIs } from '../util';

type UiSchemaWithDisplayValues = ControlElement & {
  displayValues?: { [key: string]: string };
};

const SummaryControlField = (props: RendererProps): JSX.Element => {
  const { uischema, data, visible } = props;
  const layout = uischema as GroupLayout;
  const { label } = layout;

  const { displayValues } = uischema as UiSchemaWithDisplayValues;

  const getDisplayText = useCallback(() => {
    if (!data || (Array.isArray(data) && data.length === 0)) {
      return '-';
    }

    return Array.isArray(data)
      ? data.map(getDisplayValue).reduce(joinArrayValues)
      : getDisplayValue(data);

    function getDisplayValue(value: any) {
      return displayValues && displayValues[value] ? displayValues[value] : value;
    }

    function joinArrayValues(previous: any, current: any, index: number) {
      return index === 0 ? current : `${previous}, ${current}`;
    }
  }, [data, displayValues]);

  if (!visible) {
    return null;
  }

  return (
    <div className='summary-control-container'>
      <div className='row'>
        {label && (
          <div className='title-container'>
            <Typography variant='title1'>{label}</Typography>
          </div>
        )}
        <div className='child-container'>
          <Typography>{getDisplayText()}</Typography>
        </div>
      </div>
    </div>
  );
};

export const SummaryControlFieldTester = rankWith(100, and(formUITypeIs('SummaryControlField')));
export default withJsonFormsControlProps(SummaryControlField);
