export const parseJwt = (token: string): any => {
  if (!token) return null;
  const parsed = JSON.parse(window.atob(token.split('.')[1]));
  try {
    return parsed;
  } catch (e) {
    return null;
  }
};

export const isTokenExpired = (token: string): boolean => {
  if (token) {
    const decodedJwt = parseJwt(token);
    if (decodedJwt.exp * 1000 < Date.now()) {
      return true;
    }
    return false;
  }
  return true;
};
