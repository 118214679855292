import React from 'react';
import clsx from 'clsx';
import Icon from '../Icon/Icon';
import styles from './IconButton.module.scss';

interface IconButtonProps {
  /**
   * Scss class that apply to button
   */
  className?: string;
  /**
   * Is button disabled
   */
  disabled?: boolean;
  /**
   * Button contents
   */
  icon: IconName;
  /**
   * Optional click handler
   */
  onClick?: () => void;
  /**
   * Optional button variant
   */
  variant?: 'primary' | 'secondary';
}

/**
 * Primary UI component for user interaction
 */

const IconButton: React.FC<IconButtonProps> = ({
  className,
  disabled = false,
  icon,
  onClick,
  variant = 'primary',
  ...props
}: IconButtonProps) => (
  <button
    className={clsx(
      styles.button,
      {
        [styles.primary]: variant === 'primary',
        [styles.secondary]: variant === 'secondary',
      },
      className,
    )}
    disabled={disabled}
    onClick={onClick}
    type='button'
    {...props}>
    <Icon name={icon} />
  </button>
);

export default IconButton;
