import React, { useState } from 'react';
import clsx from 'clsx';
import ReactSelect, {
  SingleValue,
  components,
  DropdownIndicatorProps,
  createFilter,
} from 'react-select';
import colors from 'src/styles/variables.scss';
import Icon from '../Icon/Icon';
import hexToRgba from '../../../utils/hexToRgba';
import styles from './Select.module.scss';

interface Option {
  label: string;
  value: string | number;
}

interface SelectProps {
  clearable?: boolean;
  error?: string;
  disabled?: boolean;
  isMulti?: boolean;
  htmlFor?: string;
  label?: string;
  name: string;
  handleChange?: (value: any) => void;
  options?: Option[];
  placeholder?: string;
  searchable?: boolean;
  value?: any;
  width?: string;
  variant?: 'default' | 'boldLabel';
}

const Select: React.FC<SelectProps> = (props: SelectProps) => {
  const {
    clearable = false,
    error,
    disabled = false,
    isMulti = false,
    htmlFor,
    label,
    name,
    handleChange = () => false,
    placeholder = '',
    options = [],
    searchable = false,
    value,
    width = '100%',
    variant = 'default',
  } = props;

  const [ignoreCase] = useState(true);
  const [ignoreAccents] = useState(false);
  const [trim] = useState(true);
  const [matchFromStart] = useState(true);

  const filterConfig = {
    ignoreCase,
    ignoreAccents,
    trim,
    matchFrom: matchFromStart ? ('start' as const) : ('any' as const),
  };

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = (ddProps: DropdownIndicatorProps) =>
    components.DropdownIndicator && (
      <components.DropdownIndicator {...ddProps}>
        {ddProps?.selectProps?.menuIsOpen ? (
          <Icon name='arrowUp' size={16} />
        ) : (
          <Icon color={disabled ? colors.grey : colors.axiBlack} name='arrowDown' size={16} />
        )}
      </components.DropdownIndicator>
    );
  let labelStyle = styles.label;
  if (variant === 'boldLabel') {
    labelStyle = styles.boldLabel;
  }
  return (
    <>
      {label && (
        <label
          className={clsx(labelStyle, {
            [styles.disabled]: disabled,
          })}
          htmlFor={htmlFor}>
          {label}
        </label>
      )}
      <ReactSelect
        className='kit-select'
        id={`Select-${name}`}
        components={{ DropdownIndicator }}
        autoFocus={false}
        isDisabled={disabled}
        isClearable={clearable}
        isSearchable={searchable}
        isMulti={isMulti}
        value={value}
        name={name}
        onChange={(selectValue: string | number) => handleChange(selectValue)}
        menuPlacement='auto'
        options={options as SingleValue<any>[]}
        menuPortalTarget={document.body}
        placeholder={placeholder}
        filterOption={createFilter(filterConfig)}
        styles={{
          indicatorSeparator: () => ({
            display: 'none',
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            fill: state.isDisabled && colors.grey,
          }),
          placeholder: (provided) => ({
            ...provided,
            fontFamily: "'SF Pro Display', sans-serif",
            padding: '2px 8px',
            fontWeight: 400,
            fontSize: 16,
            lineHeight: '19px',
            color: colors.grey,
          }),
          control: (provided, state) => {
            let borderColor = colors.paleGrey;
            if (error) {
              borderColor = colors.red;
            }

            return {
              ...provided,
              fontFamily: "'SF Pro Display', sans-serif",
              background: state.isDisabled ? colors.white : colors.white,
              border: `1px solid ${borderColor} !important`,
              borderRadius: state.menuIsOpen ? '8px 8px 0 0' : 8,
              padding: '2px 8px',
              outline: 'none !important',
              boxShadow: 'none',
              minHeight: '40px',
              width,
            };
          },
          menu: (provided) => ({
            ...provided,
            background: colors.white,
            border: `1px solid ${colors.paleGrey}`,
            borderRadius: '0 0 8px 8px',
            margin: 0,
            boxShadow: 'none',
            borderTop: 0,
            overflow: 'hidden',
          }),
          menuList: (provided) => ({
            ...provided,
            padding: 0,
          }),
          option: (provided, state) => {
            let background = colors.white;
            if (state.isSelected) {
              background = hexToRgba(colors.yellow, 0.3);
            } else if (state.isFocused) {
              background = hexToRgba(colors.bone, 0.5);
            }
            return {
              ...provided,
              fontFamily: "'SF Pro Display', sans-serif",
              background: `${background} !important`,
              color: colors.axiBlack,
              fontWeight: 400,
              fontSize: 16,
              lineHeight: '19px',
            };
          },
          multiValue: (provided) => ({
            ...provided,
            borderRadius: 16,
            background: colors.axiBone,
          }),
        }}
      />
      {error && (
        <div className={styles.error} id={`SelectError-${name}`}>
          {error}
        </div>
      )}
    </>
  );
};

export default Select;
