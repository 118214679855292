import React from 'react';
import clsx from 'clsx';
import styles from './Spinner.module.scss';

const Spinner: React.FC<{ size: string }> = ({ size }) => (
  <div
    className={clsx(styles.Spinner, {
      [styles.small]: size === 'small',
      [styles.medium]: size === 'medium',
      [styles.large]: size === 'large',
    })}
  />
);
export default Spinner;
