import React, { useState, useEffect } from 'react';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { rankWith, uiTypeIs, JsonSchema, UISchemaElement } from '@jsonforms/core';
import { Input } from 'src/components/kit';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

export type PhoneControlElement = {
  dialingCode: string;
};

interface PhoneControlWrapperProps {
  handleChange: (path: string, value: string) => void;
  path: string;
  errors: any;
  data: string;
  schema: JsonSchema | any;
  uischema: UISchemaElement | any;
}

const PhoneControlRenderer = (props: PhoneControlWrapperProps): JSX.Element => {
  const { handleChange, path, errors, schema, data, uischema } = props;
  const [validationError, setValidationError] = useState<string>();
  const placeholder = uischema.placeholder as string;
  const label = uischema.label?.toString();
  const { lead } = useSelector((state: any) => state.onboarding);
  const intl = useIntl();

  useEffect(() => {
    setValidationErrorMessage();
  }, [data, lead?.phoneCountryCode]);

  const setValidationErrorMessage = () => {
    if (!data) {
      setValidationError(
        intl.formatMessage({
          id: 'kyc.validation.validPhoneNumber',
          defaultMessage: 'Please provide a valid phone number for your country of residence',
        }),
      );
    } else if (data !== '' && errors) {
      if (schema.message) {
        setValidationError(`${schema.message}`);
      } else {
        setValidationError(
          intl.formatMessage({
            id: 'kyc.validation.validPhoneNumber',
            defaultMessage: 'Please provide a valid phone number for your country of residence',
          }),
        );
      }
    } else if (!errors) {
      setValidationError('');
    }
  };

  return (
    lead?.phoneCountryCode && (
      <div className='phone-number-control-container control-container'>
        <Input
          error={validationError}
          label={label && label}
          name='phoneNumber'
          value={data && `${data.replace(/ +/g, '')}`}
          placeholder={placeholder && placeholder}
          handleChange={(e: any) => {
            handleChange(path, `${e.target?.value?.replace(/ +/g, '')}`);
          }}
        />
      </div>
    )
  );
};

export const PhoneControlTester = rankWith(5, uiTypeIs('Phone'));

export default withJsonFormsControlProps(PhoneControlRenderer);
