/* tslint:disable */
/* eslint-disable */
/**
 * JumioWrapper
 * API for wrapping Jumio API calls
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: jack.beezer@axi.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Enum for document type
 * @export
 * @enum {string}
 */

export const DocumentType = {
    Passport: 'PASSPORT',
    DrivingLicense: 'DRIVING_LICENSE',
    IdCard: 'ID_CARD'
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


/**
 * 
 * @export
 * @interface ErrorDetails
 */
export interface ErrorDetails {
    /**
     * 
     * @type {number}
     * @memberof ErrorDetails
     */
    'statusCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorDetails
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface InitiateRequest
 */
export interface InitiateRequest {
    /**
     * Transaction Id
     * @type {string}
     * @memberof InitiateRequest
     */
    'id': string;
    /**
     * User Reference
     * @type {string}
     * @memberof InitiateRequest
     */
    'userReference'?: string;
    /**
     * Workflow ID [100,101,102,200,201,202]
     * @type {number}
     * @memberof InitiateRequest
     */
    'workflowId': number;
    /**
     * Country code in ISO3 for
     * @type {string}
     * @memberof InitiateRequest
     */
    'isoCountry': string;
    /**
     * 
     * @type {DocumentType}
     * @memberof InitiateRequest
     */
    'documentType': DocumentType;
    /**
     * 
     * @type {string}
     * @memberof InitiateRequest
     */
    'locale'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InitiateRequest
     */
    'tokenLifetimeInMins'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InitiateRequest
     */
    'redirectSuccessUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InitiateRequest
     */
    'redirectFailureUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InitiateRequest
     */
    'callbackUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface InitiateResponse
 */
export interface InitiateResponse {
    /**
     * Timestamp in UTC format
     * @type {string}
     * @memberof InitiateResponse
     */
    'utcTimestamp'?: string;
    /**
     * Jumio redirect URL
     * @type {string}
     * @memberof InitiateResponse
     */
    'redirectUrl'?: string;
    /**
     * Jumio transaction reference
     * @type {string}
     * @memberof InitiateResponse
     */
    'transactionReference'?: string;
}

/**
 * VerificationApi - axios parameter creator
 * @export
 */
export const VerificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Wrapper call for Jumio initiate endpoint
         * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
         * @param {InitiateRequest} [initiateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInitiate: async (authorization: string, initiateRequest?: InitiateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authorization' is not null or undefined
            assertParamExists('postInitiate', 'authorization', authorization)
            const localVarPath = `/initiate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", ["read:self"], configuration)

            if (authorization != null) {
                localVarHeaderParameter['Authorization'] = String(authorization);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initiateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VerificationApi - functional programming interface
 * @export
 */
export const VerificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VerificationApiAxiosParamCreator(configuration)
    return {
        /**
         * Wrapper call for Jumio initiate endpoint
         * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
         * @param {InitiateRequest} [initiateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postInitiate(authorization: string, initiateRequest?: InitiateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InitiateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postInitiate(authorization, initiateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VerificationApi - factory interface
 * @export
 */
export const VerificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VerificationApiFp(configuration)
    return {
        /**
         * Wrapper call for Jumio initiate endpoint
         * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
         * @param {InitiateRequest} [initiateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postInitiate(authorization: string, initiateRequest?: InitiateRequest, options?: any): AxiosPromise<InitiateResponse> {
            return localVarFp.postInitiate(authorization, initiateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VerificationApi - object-oriented interface
 * @export
 * @class VerificationApi
 * @extends {BaseAPI}
 */
export class VerificationApi extends BaseAPI {
    /**
     * Wrapper call for Jumio initiate endpoint
     * @param {string} authorization The authorisation header used to pass the client access token to be used for authorisation within the service
     * @param {InitiateRequest} [initiateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerificationApi
     */
    public postInitiate(authorization: string, initiateRequest?: InitiateRequest, options?: AxiosRequestConfig) {
        return VerificationApiFp(this.configuration).postInitiate(authorization, initiateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


