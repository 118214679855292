import { UISchema } from 'src/slices/onboarding';

export default function augmentSummarySchema(summarySchema: any, schema: any): UISchema['schema'] {
  switch (summarySchema.blockProperty) {
    case 'idVerification':
      return augmentIdVerificationSummarySchema(summarySchema);
    case 'documentUpload':
      return augmentDocUploadSummarySchema(summarySchema, schema);
    default:
      return summarySchema;
  }
}

function augmentIdVerificationSummarySchema(summarySchema: any): UISchema['schema'] {
  const idVerificationSubSection = summarySchema.elements.find(
    (element: any) => element.id === 'id_verification',
  );

  if (typeof idVerificationSubSection === 'undefined') {
    return summarySchema;
  }

  return {
    ...summarySchema,
    elements: [
      {
        ...idVerificationSubSection,
        elements: [
          {
            type: 'SummaryControlField',
            scope: `#/properties/${summarySchema.blockProperty}/properties/statusText`,
            label: `${idVerificationSubSection.label}`,
          },
        ],
      },
      ...summarySchema.elements.filter(
        (element: UISchema['schema']) => element !== idVerificationSubSection,
      ),
    ],
  };
}

function augmentDocUploadSummarySchema(summarySchema: any, schema: any): UISchema['schema'] {
  const docUploadSubSection = summarySchema.elements.find(
    (element: any) => element.id === 'document_upload',
  );

  if (typeof docUploadSubSection === 'undefined') {
    return summarySchema;
  }

  const docUploadElements = schema?.elements?.map((subForm: any) => {
    const updatedSubFormElements = subForm?.elements?.filter(
      (subFormElement: any) => subFormElement.type !== 'DocumentUpload',
    );

    const documentUploadElement = subForm?.elements?.filter(
      (subFormElement: any) => subFormElement.type === 'DocumentUpload',
    );
    const headerText = documentUploadElement[0] ? documentUploadElement[0].label : '';
    return {
      ...subForm,
      elements: [
        {
          type: 'SummaryHorizontalLayout',
          elements: [
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'Header',
                  value: `${headerText}`,
                },
                ...updatedSubFormElements,
              ],
            },
            {
              type: 'VerticalLayout',
              elements: [
                {
                  type: 'SummaryFileList',
                  scope: `#/properties/${summarySchema.blockProperty}/properties/documentList`,
                  label: '',
                },
              ],
            },
          ],
        },
      ],
    };
  });

  const docUploadSchema = {
    ...summarySchema,
    elements: [
      {
        ...docUploadSubSection,
        elements: docUploadElements,
      },
    ],
  };

  return docUploadSchema;
}
