/* eslint-disable array-callback-return */
import React from 'react';
import { and, ArrayLayoutProps, rankWith } from '@jsonforms/core';
import { Icon, Typography } from 'src/components/kit';
import { withJsonFormsArrayControlProps } from '@jsonforms/react';
import { useIntl } from 'react-intl';
import { formUITypeIs } from '../util';

interface Document {
  id: string;
  fileName: string;
  error: any;
}

const SummaryFileListRenderer = (props: ArrayLayoutProps): JSX.Element => {
  const intl = useIntl();

  // eslint-disable-next-line react/destructuring-assignment
  const files = props.data as unknown as Document[];
  if (files) {
    return (
      <>
        <Typography variant='title1'>
          {intl.formatMessage({
            id: 'kyc.summary.filesText',
            defaultMessage: 'Files: ',
          })}
        </Typography>
        {files &&
          files
            ?.filter((file) => !file.error)
            .map((file) => (
              <div key={file.id} className='row-container'>
                <Icon name='file' key={file.id} />
                <Typography variant='body1' key={file.id}>
                  {file.fileName}
                </Typography>
              </div>
            ))}
      </>
    );
  }
  return <></>;
};

export const SummaryFileListTester = rankWith(5, and(formUITypeIs('SummaryFileList')));
export default withJsonFormsArrayControlProps(SummaryFileListRenderer);
