export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
};

export const DEVICE_SIZES = {
  MOBILE: 375,
  TABLET: 768,
  DESKTOP: 1024,
  LARGE_DESKTOP: 1920,
};

export const AXI_COLORS = {
  AXI_RED: '#FC404D',
  AXI_DARK_RED: '#D11C36',
  AXI_LIGHT_RED: '#FF5954',
  AXI_BONE: '#F0EBE3',
  AXI_CHARCOAL: '#282424',
  AXI_AMETHYST: '#55165c',
  AXI_LEMON: '#FCD758',
  AXI_TEAL: '#45C2C7',
  AXI_GREEN: '#6FCF97',
  AXI_LIGHT_GREEN: '#EDF7ED',
  AXI_VERY_LIGHT_RED: '#FDECEA',
  AXI_LIGHT_YELLOW: '#FFF4E5',
  AXI_BLUE_GREY: '#546E7A',
  AXI_WHITE: '#FFFFFF',
};
