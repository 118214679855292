import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import styles from './Typography.module.scss';

interface TypographyProps {
  /**
   * Sets the horizontal alignment of the content
   */
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
  /**
   * Scss class that apply to wrapper
   */
  className?: string;
  /**
   * Typography content
   */
  children?: any;
  /**
   * Sets content color
   */
  color?: string;
  /**
   * Used for the root node.
   * Either a string to use a DOM element or a component.
   * E.g. `span`, `p`, `h1`.
   */
  component?: any;
  /**
   *  If true, bottomMargin is zero
   */
  gutterBottom?: boolean;
  /**
   * Sets style
   */
  style?: object;
  /**
   * Font style
   */
  variant?: TypographyVariant;
  visible?: boolean;
}

const Typography: React.FC<TypographyProps> = ({
  align,
  className,
  children,
  color,
  component: Component = 'span',
  gutterBottom = false,
  style,
  variant = 'body1',
  visible = true,
  ...otherProps
}: TypographyProps) => {
  const [componentStyle, setComponentStyle] = useState({});

  useEffect(() => {
    let newStyle = { ...style };
    if (color) {
      newStyle = {
        ...newStyle,
        color,
      };
    }
    if (gutterBottom) {
      newStyle = {
        ...newStyle,
        marginBottom: 0,
      };
    }
    if (align) {
      newStyle = {
        ...newStyle,
        textAlign: align,
      };
    }
    setComponentStyle(newStyle);
  }, [style, color, gutterBottom, align]);
  if (!visible) {
    return <></>;
  }

  return (
    <Component
      className={clsx(styles.typography, styles[variant], className)}
      style={componentStyle}
      {...otherProps}>
      {children}
    </Component>
  );
};

export default Typography;
