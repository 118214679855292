import { createAjv } from '@jsonforms/core';
import {
  AffiliateLead,
  IdVerification,
  IdVerificationStatus,
} from 'src/resources/api-client/onboarding';

export type BlockStatus = 'NotStarted' | 'Ongoing' | 'Completed' | 'RequiresRemediation';
export type Ajv = ReturnType<ReturnType<typeof createAjv>['compile']>;

// Required fields of type string in json schema's means the key exists, not that the field is not empty. So in order to fix the validation, empty fields need to be deleted.
export function stripEmptyValues<T extends Record<string, any>>(data: T): T {
  const result: any = {};
  Object.keys(data).forEach((key) => {
    if ((data[key] as any)?.toString() !== '') {
      result[key] = data[key];
    }
  });
  return result;
}

function validatePersonalDetails(
  personalDetailsAjv: Ajv,
  personalDetail?: Record<string, any>,
): BlockStatus {
  if (!personalDetail) {
    return 'NotStarted';
  }
  return personalDetailsAjv(stripEmptyValues(personalDetail)) ? 'Completed' : 'Ongoing';
}

function validateFinances(financesAjv: Ajv, finances?: Record<string, any>): BlockStatus {
  if (!finances) {
    return 'NotStarted';
  }
  return financesAjv(stripEmptyValues(finances)) ? 'Completed' : 'Ongoing';
}

function validateVerification(verification: IdVerification | null | undefined): BlockStatus {
  if (typeof verification?.status !== 'undefined') {
    const { status } = verification;
    if (status === IdVerificationStatus.Complete) {
      return 'Completed';
    }
    if (
      status === IdVerificationStatus.Pending ||
      status === IdVerificationStatus.RequiresRemediation
    ) {
      return 'Ongoing';
    }
  }
  return 'NotStarted';
}

function validateExperience(experienceAjv: Ajv, experience?: Record<string, any>): BlockStatus {
  if (!experience) {
    return 'NotStarted';
  }
  return experienceAjv(stripEmptyValues(experience)) ? 'Completed' : 'Ongoing';
}

function validateDeclaration(declarationAjv: Ajv, declaration?: Record<string, any>): BlockStatus {
  if (!declaration) {
    return 'NotStarted';
  }
  return declarationAjv(stripEmptyValues(declaration)) ? 'Completed' : 'Ongoing';
}

// function validateTermsAndConditions(
//   datetime: string | undefined | null,
// ): BlockStatus {
//   return datetime ? 'Completed' : 'NotStarted';
// }

export type Validate = (lead: AffiliateLead | null) => {
  personalDetailsProgress: BlockStatus;
  idVerificationProgress: BlockStatus;
  financesProgress: BlockStatus;
  experienceProgress: BlockStatus;
  declarationProgress: BlockStatus;
};
export const validate =
  (ajvValidators: {
    personalDetailsAjv: Ajv;
    financesAjv: Ajv;
    experienceAjv: Ajv;
    declarationAjv: Ajv;
  }): Validate =>
  (lead: AffiliateLead | null) => {
    const { kycSections, idVerification } = lead || {};
    const personalDetails = kycSections?.personalDetails as Record<string, any>;
    const finances = kycSections?.finances as unknown as Record<string, any>;
    const experience = kycSections?.experience as unknown as Record<string, any>;
    const declaration = kycSections?.declaration as unknown as Record<string, any>;
    const { personalDetailsAjv, financesAjv, experienceAjv, declarationAjv } = ajvValidators;

    return {
      personalDetailsProgress: validatePersonalDetails(personalDetailsAjv, personalDetails),
      idVerificationProgress: validateVerification(idVerification),
      financesProgress: validateFinances(financesAjv, finances),
      experienceProgress: validateExperience(experienceAjv, experience),
      declarationProgress: validateDeclaration(declarationAjv, declaration),
    };
  };
