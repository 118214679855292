import React from 'react';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { rankWith, uiTypeIs, RendererProps, GroupLayout } from '@jsonforms/core';

const SubFormRenderer = (props: RendererProps): JSX.Element => {
  const { uischema, schema, path, renderers, cells, enabled, visible } = props;
  const uiSchemaForm = uischema as GroupLayout;
  return (
    visible && (
      <>
        {uiSchemaForm.elements.map((child: any, key: any): JSX.Element => {
          const elementKey = `${child.type}-${key}`;
          return (
            <JsonFormsDispatch
              schema={schema}
              uischema={child}
              path={path}
              enabled={enabled}
              renderers={renderers}
              cells={cells}
              key={elementKey}
            />
          );
        })}
      </>
    )
  );
};

export const SubFormTester = rankWith(5, uiTypeIs('SubForm'));
export default withJsonFormsLayoutProps(SubFormRenderer);
