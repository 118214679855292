import React, { createContext, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { merge, debounce } from 'lodash';
import { THEMES, DEVICE_SIZES } from 'src/constants';

interface Settings {
  direction?: 'ltr' | 'rtl';
  responsiveFontSizes?: boolean;
  theme?: string;
  device?: Device;
}

export interface SettingsContextValue {
  settings: Settings;
  saveSettings: (update: Settings) => void;
}

interface SettingsProviderProps {
  settings?: Settings;
  children?: ReactNode;
}

const defaultSettings: Settings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.LIGHT,
  device: 'desktop',
};

export const restoreSettings = (): Settings | null => {
  let settings = null;

  try {
    const storedData: string | null = window.localStorage.getItem('settings');

    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (err) {
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

export const storeSettings = (settings: Settings): void => {
  window.localStorage.setItem('settings', JSON.stringify(settings));
};

const SettingsContext = createContext<SettingsContextValue>({
  settings: defaultSettings,
  saveSettings: () => {},
});

export const SettingsProvider: FC<SettingsProviderProps> = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState<Settings>(settings || defaultSettings);

  const handleSaveSettings = (update: Settings = {}): void => {
    const mergedSettings = merge({}, currentSettings, update);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(() => {
    const restoredSettings = restoreSettings();

    if (restoredSettings) {
      setCurrentSettings(restoredSettings);
    }
  }, []);

  useEffect(() => {
    document.dir = currentSettings.direction || 'ltr';
  }, [currentSettings]);

  useEffect(() => {
    const updateWindowDimensions = debounce(() => {
      const width = window.innerWidth;
      let device: Device;
      if (width < DEVICE_SIZES.TABLET) {
        device = 'mobile';
      } else if (width >= DEVICE_SIZES.TABLET && width < DEVICE_SIZES.DESKTOP) {
        device = 'tablet';
      } else if (width >= DEVICE_SIZES.DESKTOP && width < DEVICE_SIZES.LARGE_DESKTOP) {
        device = 'desktop';
      } else if (width >= DEVICE_SIZES.LARGE_DESKTOP) {
        device = 'largeDesktop';
      }

      handleSaveSettings({ device });
    }, 50);

    window.addEventListener('resize', updateWindowDimensions);
    updateWindowDimensions();

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
      }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
