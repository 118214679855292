import React, { useState, useEffect } from 'react';
import { Box, Stepper, Step, StepLabel } from '@material-ui/core';
import useWindowSize from 'src/hooks/useWindowSize';
import { setCurrentStep } from 'src/slices/auth';
import { useDispatch } from 'react-redux';
import { Icon, Typography } from 'src/components/kit';
import {
  StepNotStartedIcon,
  StepInProgressIcon,
  StepSkippedIcon,
  StepPendingIcon,
  StepFailedIcon,
  StepCompletedIcon,
} from 'src/components/ProgressIcons';
import './SideMenu.styles.scss';
import colors from 'src/styles/variables.scss';
import { SideMenuItem } from 'src/layouts/Sidebar/Sidebar.component';

const identifyIconToRender = (status: string): JSX.Element => {
  let icon = <Icon name='stepNotStarted' title='Not Started' color={colors.white} />;

  switch (status) {
    case 'NotStarted':
      icon = <StepNotStartedIcon />;
      break;
    case 'Ongoing':
      icon = <StepInProgressIcon />;
      break;
    case 'Skipped':
      icon = <StepSkippedIcon />;
      break;
    case 'Pending':
      icon = <StepPendingIcon />;
      break;
    case 'RequiresRemediation':
      icon = <StepFailedIcon />;
      break;
    case 'Completed':
      icon = <StepCompletedIcon />;
      break;
    default:
      icon = <StepNotStartedIcon />;
      break;
  }
  return icon;
};

const SideMenu: React.FC<{ items: SideMenuItem[] }> = ({ items }): JSX.Element => {
  const [activeStep, setActiveStep] = React.useState(1);
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const [disableNavigation, setDisableNavigation] = useState<boolean>(false);

  useEffect(() => {
    if (windowSize <= 1024) {
      setDisableNavigation(true);
    } else {
      setDisableNavigation(false);
    }
  }, [windowSize]);

  return (
    <Box data-testid='SideMenu'>
      <div className='side-menu-container'>
        <Stepper activeStep={activeStep} orientation='vertical' className='sidebar-menu'>
          {items.map((item: any) => {
            const icon = () => identifyIconToRender(item.status);
            return (
              <Step
                className={`${item.status === 'NotStarted' && 'not-allowed'} clickable`}
                key={item.label}
                onClick={() => {
                  if (item.status === 'NotStarted') return;
                  setActiveStep(item.step);
                  if (!disableNavigation) {
                    if (item.status === 'Pending') return;
                    if (item.status === 'Completed') {
                      if (item.key === 'idVerification') return;
                      dispatch(setCurrentStep(`affiliate-${item.key}`));
                    } else if (item.status === 'Skipped' || item.status === 'RequiresRemediation') {
                      dispatch(setCurrentStep(`affiliate-${item.key}`));
                    }
                  }
                }}>
                <StepLabel StepIconComponent={icon}>
                  <Typography variant='h3' color={colors.white}>
                    {item.label}
                  </Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
    </Box>
  );
};

export default React.memo(SideMenu);
