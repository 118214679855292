/* eslint no-underscore-dangle: ["error", { "allow": ["_env_"] }] */
/* eslint-disable no-nested-ternary */

const localHostBaseUri = 'http://localhost:3000';
const previewBaseUri = 'https://affiliateonboarding.preview.axitp.com';
const stagingBaseUri = 'https://affiliateonboarding.stg.axitp.com';
const prodBaseUri = 'https://affiliateonboarding.app.axitp.com';

export const debuggingConfig = {
  mode: window.location.origin.includes('localhost:6006') ? 'storybook' : 'dev',
};
export const jumioServiceConfig = {
  // eslint-disable-next-line no-nested-ternary
  apiUrl: window.location.origin.startsWith('http://localhost')
    ? 'http://localhost:3003/jumio-wrapper'
    : window.location.origin.includes('preview')
    ? 'https://jumioservice.stg.axi-test.com'
    : `${window.location.origin.replace('affiliateonboarding', 'jumioservice')}`,
  audience: 'customer',
  scope: 'read:self',
};

export const jumioRedirectUri = window.location.origin.startsWith('http://localhost')
  ? `${localHostBaseUri}/jumio`
  : window.location.origin.includes('preview')
  ? `${previewBaseUri}/jumio`
  : window.location.origin.includes('app')
  ? `${prodBaseUri}/jumio`
  : `${stagingBaseUri}/jumio`;

export const onboardingServiceConfig = {
  // eslint-disable-next-line no-nested-ternary
  apiUrl: window.location.origin.startsWith('http://localhost')
    ? 'http://localhost:3002'
    : window.location.origin.includes('preview')
    ? 'https://onboardingapi.stg.axi-test.com'
    : `${window.location.origin.replace('affiliateonboarding', 'onboardingapi')}`,
  audience: 'customer',
  scope: 'read:self',
};

export const onboardingConfig = {
  for: window.location.origin.startsWith('http://localhost')
    ? 'affiliate-lead'
    : window.location.origin.includes('affiliate')
    ? 'affiliate-lead'
    : 'lead',
};

export const auth0Config = {
  clientID: window.location.origin.startsWith('http://localhost')
    ? 'hEX2dKTlSdnUF3HoGq1JTOd6BbXS07H5'
    : window.location.origin.includes('preview')
    ? 'NKoDtLRUt4gGtgVlHl09fIiNkztCFzI5'
    : window.location.origin.includes('app')
    ? 'SMpiGhapTZq0CVjzpYmyqgZt1eFLNhlg'
    : 'NKoDtLRUt4gGtgVlHl09fIiNkztCFzI5',
  domain: window.location.origin.startsWith('http://localhost')
    ? 'auth.preview.axitp.com'
    : window.location.origin.includes('preview')
    ? 'auth.stg.axitp.com'
    : window.location.origin.includes('app')
    ? 'auth.app.axitp.com'
    : 'auth.stg.axitp.com',
  useRefreshTokens: true,
  responseType: 'id_token token',
  connection: 'Username-Password-Authentication',
  audience: 'customer',
  scope: 'openid profile email offline_access read:self update:self create:self read:settings',
};

export const auth0RedirectUri = window.location.origin.startsWith('http://localhost')
  ? `${localHostBaseUri}/kyc`
  : window.location.origin.includes('preview')
  ? `${previewBaseUri}/kyc`
  : window.location.origin.includes('app')
  ? `${prodBaseUri}/kyc`
  : `${stagingBaseUri}/kyc`;

export const auth0LogoutURL = window.location.origin.startsWith('http://localhost')
  ? `${localHostBaseUri}/login`
  : window.location.origin.includes('preview')
  ? `${previewBaseUri}/login`
  : window.location.origin.includes('app')
  ? `${prodBaseUri}/login`
  : `${stagingBaseUri}/login`;

export const reCatchaConfig = {
  siteKey: window.location.origin.startsWith('http://localhost')
    ? '6Lc78cgiAAAAAOUDuTfzSSNV3mXDTLxPg1a4YN1X'
    : window.location.origin.includes('app')
    ? '6Lc78cgiAAAAAOUDuTfzSSNV3mXDTLxPg1a4YN1X'
    : '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
};
