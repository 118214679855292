import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import { loadSession, AuthResponse } from 'src/auth';
import { tryGetCustomerIdFromToken } from 'src/utils/accessToken';
import useAnalytics from 'src/analytics/useAnalytics';
import { getData, getSchema, getSummarySchema } from './onboarding';

export interface AuthenticationState {
  isAuthenticated: boolean;
  status: 'loading' | 'ready' | 'error' | '404';
  formTouched?: boolean;
  currentStep?: string;
}

export const initialState: AuthenticationState = {
  isAuthenticated: false,
  status: 'loading',
  formTouched: false,
  currentStep: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetState(state: AuthenticationState) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
    setAuthentication(state: AuthenticationState, action: PayloadAction<AuthenticationState>) {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.status = action.payload.status;
    },
    setFormTouched(state: AuthenticationState, action: PayloadAction<{ formTouched: boolean }>) {
      state.formTouched = action.payload.formTouched;
    },
    setCurrentStep(state: AuthenticationState, action: PayloadAction<{ currentStep: string }>) {
      state.currentStep = action.payload.currentStep;
    },
  },
});

export const setAuthentication =
  (acceptLanguage: string): AppThunk =>
  async (dispatch) => {
    loadSession((authResponse: AuthResponse) => {
      const { success, error, result } = authResponse;
      const trackLogin = localStorage.getItem('TrackLogin');
      const trackEvent = useAnalytics();
      if (success === true) {
        if (trackLogin === 'true') {
          const customerId = tryGetCustomerIdFromToken(result.accessToken);
          window.analytics.identify(customerId, {});
          trackEvent('Affiliate Auth0 Login Completed', undefined);
        }
        dispatch(slice.actions.setAuthentication({ isAuthenticated: true, status: 'ready' }));
        dispatch(getSchema(acceptLanguage));
        dispatch(getData(acceptLanguage));
        dispatch(getSummarySchema(acceptLanguage));
      } else if (success === false && error) {
        dispatch(slice.actions.setAuthentication({ isAuthenticated: false, status: 'ready' }));
      }
    });
  };

export const setAffiliate =
  (auth: AuthenticationState): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setAuthentication(auth));
  };

export const setFormTouched =
  (touched: boolean): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setFormTouched({ formTouched: touched }));
  };

export const setCurrentStep =
  (step: string): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.setCurrentStep({ currentStep: step }));
  };

export const { reducer } = slice;
export default slice;
