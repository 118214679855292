import en from '../localization/en.json';
import es from '../localization/es.json';

const languages = [
  {
    key: 'en',
    file: en,
    label: 'English (US)',
    locale: 'en-US',
  },
  {
    key: 'es',
    file: es,
    label: 'Español (España)',
    locale: 'es-ES',
  },
];

export default languages;
