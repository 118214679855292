import React from 'react';
import { JsonFormsDispatch, withJsonFormsLayoutProps } from '@jsonforms/react';
import { rankWith, uiTypeIs, RendererProps, GroupLayout } from '@jsonforms/core';
import './PhoneHorizontalLayout.styles.scss';

const PhoneHorizontalLayoutRenderer = (props: RendererProps): JSX.Element => {
  const { uischema, schema, path, renderers, cells, enabled } = props;
  const uiSchemaForm = uischema as GroupLayout;
  return (
    <div className='flex justify-content-between phone-horizontal-layout'>
      {uiSchemaForm.elements.map(
        (child: any): JSX.Element => (
          <JsonFormsDispatch
            schema={schema}
            uischema={child}
            path={path}
            enabled={enabled}
            renderers={renderers}
            cells={cells}
            key={child.scope || child.value}
          />
        ),
      )}
    </div>
  );
};

export const PhoneHorizontalLayoutTester = rankWith(10, uiTypeIs('PhoneHorizontalLayout'));
export default withJsonFormsLayoutProps(PhoneHorizontalLayoutRenderer);
