/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { UISchema } from 'src/slices/onboarding';

export default function transformToSummarySchema(
  schema: UISchema['schema'],
  section: string,
): UISchema['schema'] {
  const result = transform(schema, section);
  return result;
}

function transform(schema: any, section: string): UISchema['schema'] {
  switch (schema.type) {
    case 'Form':
      // eslint-disable-next-line no-case-declarations
      const result = transformForm(schema, section);
      return result;
    case 'SubForm':
      return transformSubForm(schema, section);
    case 'Control':
    case 'Date':
    case 'Phone':
    case 'CheckboxDateTime':
    case 'PhoneCountryCode':
      if (schema.options?.format === 'checkbox_datetime') {
        return transformControlCheckbox(schema, section);
      }
      return transformControl(schema, section);
    case 'PhoneHorizontalLayout':
      return transformPhoneHorizontalLayout(schema, section);
    default:
      return null;
  }
}

function transformMany(schemas: UISchema['schema'][], section: string) {
  return schemas
    .flatMap((element: UISchema['schema']) => transform(element, section))
    .filter((schema) => schema !== null);
}

function transformForm(schema: any, section: string): UISchema['schema'] {
  const parsedSection = section.replace('affiliate-', '');
  const result = transformMany(schema.elements, parsedSection);
  return {
    type: 'SummaryBlock',
    label: schema.label,
    blockProperty: parsedSection,
    elements: result,
  };
}

function transformSubForm(schema: any, section: string): UISchema['schema'] {
  const parsedSection = section.replace('affiliate-', '');
  return {
    type: 'SummarySubSection',
    label: schema.label,
    id: schema.id,
    section: parsedSection,
    editable: {
      parent: section,
    },
    elements: transformMany(schema.elements, parsedSection),
  };
}

function transformControl(schema: any, section: string): UISchema['schema'] {
  const controlSchema: any = {
    type: 'SummaryControlField',
    label: schema.label,
    scope: schema.scope.replace('#/', `#/properties/${section}/`),
  };

  if (typeof schema.displayValues !== 'undefined') {
    controlSchema.displayValues = schema.displayValues;
  }

  if (typeof schema.rule !== 'undefined') {
    controlSchema.rule = {
      ...schema.rule,
      condition: {
        ...schema.rule.condition,
        scope: schema.rule.condition.scope.replace('#/', `#/properties/${section}/`),
      },
    };
  }
  return controlSchema;
}

function transformControlCheckbox(schema: any, section: string): UISchema['schema'] {
  const controlSchema: any = {
    type: 'SummaryControlCheckbox',
    label: schema.label,
    scope: schema.scope.replace('#/', `#/properties/${section}/`),
  };

  if (typeof schema.rule !== 'undefined') {
    controlSchema.rule = {
      ...schema.rule,
      condition: {
        ...schema.rule.condition,
        scope: schema.rule.condition.scope.replace('#/', `#/properties/${section}/`),
      },
    };
  }
  return controlSchema;
}

function transformPhoneHorizontalLayout(schema: any, section: string): UISchema['schema'] {
  const result = [];
  for (let i = 0; i < schema.elements.length; i++) {
    result.push(transform(schema.elements[i], section));
  }
  // console.log(result);
  return {
    type: 'SummaryPhoneLayout',
    label: schema.label,
    id: schema.id,
    elements: result,
  };
}
